import React from 'react';
import Entity from "../../_model/Entity";
import ISort from "../../Framework/Sort";
import {MainContext} from "../../_base/MainContext";
import {ListItem} from "./List";
import {AdminObjectType} from "../../Framework/Enums";
import {TooltipText} from "../Tooltips";
import {ListSortOptions} from "./ListSortOptions";
import {AdminDataListSortSettings} from "../../Admin/Maintenance/AdminDataListSortSettings";
import ListPaginationOptions from "./ListPaginationOptions";
import {RouteComponentProps} from "react-router-dom";
import translations from "../../Framework/translations.json";

export interface ListTypeProps extends RouteComponentProps {
    items?: ListItem[]
    loading?: boolean
    objectType: AdminObjectType

    sortOptions: ListSortOptions
    paginationOptions: ListPaginationOptions

    newItemUrl?: string
    newItemTooltip?: TooltipText
    redirectUrl?: string

    selectedItems: number[]
    allowMultiSelect: boolean
    selectedEntityTextSingle?: string
    selectedEntityTextMultiple?: string

    onSelectionChange?: (id: number[]) => void
}
export interface ListTypeState {
    page: number
    sortValue: ISort<Entity>
}

export class ListType <T extends ListTypeProps = ListTypeProps,
    U extends ListTypeState = ListTypeState> extends React.Component<T, U> {

    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    getCurrentSortValue = () => {
        let sortValue: AdminDataListSortSettings | undefined = this.context.getListSortValue(this.props.objectType)
        if (sortValue === undefined) {
            sortValue = new AdminDataListSortSettings(
                this.props.objectType,
                this.state ? this.state.sortValue.property : this.props.sortOptions.sortField,
                this.state ? this.state.sortValue.isDescending : this.props.sortOptions.sortDescending
            )
        }

        return sortValue
    }
    setSortValue = (property: string, descending: boolean) => {
        let propRule = property as keyof Entity
        this.setState({sortValue: {property: propRule, isDescending: descending}})
    }

    onItemSelect = (itemId: number, multiSelect?: boolean) => {
        let selectedItems: number[] = this.props.selectedItems
        if ((multiSelect !== undefined && !multiSelect) || !this.props.allowMultiSelect) {
            selectedItems = []
        }
        if (selectedItems.find(item => item === itemId) === undefined) {
            selectedItems.push(itemId)
        }
        this.props.onSelectionChange?.(selectedItems)
    }
    onItemUnselect = (itemId: number) => {
        let selectedItems = this.props.selectedItems
        const index = this.props.selectedItems.indexOf(itemId)
        if (index >= 0) {
            selectedItems.splice(index, 1)
            this.props.onSelectionChange?.(selectedItems)
        }
    }

    onPageChange = (page: number) => {
        if (this.props.paginationOptions.externalPagination === true) {
            this.props.paginationOptions.onPageChange?.(page)
        } else {
            if (page >= 0 && page < this.getPageCount()) {
                this.props.paginationOptions.onPageChange?.(page)
                this.setState({page: page})
            }
        }
    }
    onClickLoadContent = () => {
        // only call page change if number of elements loaded the last time is not less than page size
        if (this.props.items && !this.props.paginationOptions.lastElementReached) {
            let currentPage = this.getCurrentPage()
            this.onPageChange(currentPage + 1)
        }
    }
    onScrollContent = (e: React.UIEvent<HTMLDivElement>) => {
        let element = e.target as HTMLDivElement
        let currentPage = this.getCurrentPage()

        if (element.scrollTop + 10 >= (element.scrollHeight - element.offsetHeight) && !this.props.loading) {
            this.context.log.info("Loading page: " + (currentPage + 1))
            this.context.log.flush()

            // only call page change if number of elements loaded the last time is not less than page size
            if (this.props.items && (this.props.items.length % this.props.paginationOptions.pageSize) === 0) {
                this.onPageChange(currentPage + 1)
            }
        }
    }
    scrollDown = () => {
        let scrollContainer = document.getElementById("scrollContainer")
        if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight
        }
    }

    getPageCount = () => {
        if (this.props.paginationOptions.externalPagination === true && this.props.paginationOptions.itemCount !== undefined) {
            return Math.ceil(this.props.paginationOptions.itemCount / this.props.paginationOptions.pageSize)
        } else {
            if (this.props.items === undefined || this.props.items.length === 0) {
                return 1
            }
            return Math.ceil(this.props.items.length / this.props.paginationOptions.pageSize)
        }
    }
    getCurrentPage = () => {
        if (this.props.paginationOptions.externalPagination === true && this.props.paginationOptions.currentPage) {
            return this.props.paginationOptions.currentPage
        }

        if (this.state) {
            return this.state.page
        }

        let currentPage = 0
        if (this.props.items && this.props.paginationOptions.pageSize) {
            currentPage = Math.floor(this.props.items.length / this.props.paginationOptions.pageSize)
            currentPage = currentPage > 0 ? currentPage - 1 : 0
        }
        return currentPage
    }

    getFooterText = () : string => {
        let footer = this.props.selectedItems.length + " "
        if (this.props.selectedItems.length === 1) {
            footer += (this.props.selectedEntityTextSingle || this.context.translate(translations.text.row_selected))
        } else {
            footer += (this.props.selectedEntityTextMultiple || this.context.translate(translations.text.rows_selected))
        }

        return footer
    }
}

