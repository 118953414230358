import React from 'react';
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import {AdminObjectType, ColumnRenderOption, Status} from "../../Framework/Enums";
import Country from "../../_model/Country";
import {GetAllCountriesAdmin, UpdateCountry} from "../../_endpoint/CountryEndpoint";
import AdminDataList from "../Maintenance/AdminDataList";
import CountryListItem from "./CountryListItem";
import {MainContext} from "../../_base/MainContext";
import User from "../../_model/User";
import Const from "../../Framework/Const";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: Country[]
    listItems?: CountryListItem[]
    pageSize: number
}

export default class CountryList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.name), "name", 17, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.country.isoCode), "isoCode", 13, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.language), "languages", 26, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 13, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 11, false, true, ColumnRenderOption.DateTime)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            pageSize: 100
        }
    }

    componentDidMount() {
        this.fetchData()

        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })
    }

    fetchData = () => {
        this.setState({ items: [] })

        GetAllCountriesAdmin().then(
            (itemData) => {
                const listItems = itemData.map(item => {
                    // Enums (translated)
                    let status = translations.enum.status[item.status]

                    let languages = ""
                    if (item.languages) {
                        languages = item.languages
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(r => r.name).join(", ")
                    }

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    return new CountryListItem(
                        item.id!,
                        item.name,
                        owner,
                        item.modifiedOn || item.createdOn!,
                        item.isoCode || "",
                        languages,
                        this.context.translate(status),
                        createdBy
                    )
                })

                this.setState({ items: itemData, listItems: listItems })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    onSetState = async(item: number, status: Status, user?: User) => {
        try {
            const country = this.state.items.find(d => d.id === item)
            if (country && country.status !== Status.deactivated) {
                Country.setStatus(country, status, user)
                await UpdateCountry(country)

                return true
            }
        }
        catch (e) { }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.country) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.country) + "{0}"}
                              objectType={AdminObjectType.country}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              paginationOptions={{
                                  allowPagination: true,
                                  showPagination: true,
                                  pageSize: this.state.pageSize,
                                  manipulatePageNumberRepresentation: true
                              }}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "name",
                                  sortDescending: false
                              }}
                              filterOptions={{ externalFilter: false }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}
