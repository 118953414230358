import React, {FormEvent, RefObject} from "react";
import translations from "../../../Framework/translations.json";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {
    ElementBorder,
    ElementFillStyle,
    ElementLayout,
    ElementTransformation,
    ResizeInfo,
    WDElementContainer
} from "../WDElementContainer";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {WDTextbox, WDTextboxData} from "../Textbox/WDTextbox";
import {WDMathLineature} from "../Lineature/MathLineature/WDMathLineature";
import {Coords} from "../../../Framework/Coords";
import {Util} from "../../../Framework/Util";
import {WorksheetItem} from "../../../_model/WorksheetItem";
import {WDImageData} from "../Image/WDImage";
import {
    Difficulty,
    ExerciseCalculationType,
    ExerciseType,
    NotificationStatus, RenderingMedia,
    Status,
    VerticalAlignment
} from "../../../Framework/Enums";
import Converter from "../../../Framework/Converter";
import {ElementDefinition, ElementProps} from "../../Toolbar/Toolbar/WDToolbarElement";
import {
    WDWritingLineature,
    WDWritingLineatureData,
    WDWritingLineatureSize
} from "../Lineature/WritingLineature/WDWritingLineature";
import {getDefaultFontName} from "../Lineature/WritingLineature/WDWritingLineatureFontValues";
import {getDefaultCorrectionMargin} from "../Lineature/WritingLineature/WDWritingLineatureCorrectionMargin";
import {
    WDTextExerciseData,
    WDTextExerciseSection,
    WDTextExerciseSectionData,
    WDTextExerciseSectionVariables
} from "./WDTextExerciseData";
import {GetExerciseSibling, GetExerciseWorksheetItems, GetRandomExercise} from "../../../_endpoint/ExerciseEndpoint";
import {ExerciseFilter} from "../../../_model/ExerciseFilter";
import {MenuItem} from "../../../_model/MenuItem";
import {WSContextType} from "../WSContext";
import {LogLevel} from "../../../Framework/Log";
import {FloatingHint} from "../../../Components/Notification/FloatingHint";
import Entity from "../../../_model/Entity";
import WDTextExerciseDropDownValues from "./WDTextExerciseDropDownValues";
import {WDUtils} from "../../Utils/WDUtils";
import {GetNameByNameConfig} from "../../../_endpoint/NameEndpoint";
import {WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import {WDTable} from "../Table/WDTable";
import {WDTableData} from "../Table/WDTableData";
import {NotificationData} from "../../../Components/Notification/Hint";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {WDActionLogCategory} from "../../ActionLog/WDActionLogEntry";

interface IProps extends WDElementBaseProps {
    data: WDTextExerciseData
    images: WorksheetItem[]

    renderingMedia: RenderingMedia
    renderElement: (element: WorksheetItem) => JSX.Element
    addElementToDesigner: (element: WorksheetItem) => void
    addElementToGroup: (groupKey: string, element: WorksheetItem) => void
    deleteChildren: (itemKey: string) => void
    onSelectImage: () => void
}

interface IState extends WDElementBaseState {
    focusElement: WDTextExerciseSection | undefined
    isDragging: boolean
    error?: string

    introductionRef: RefObject<WDTextbox>
    calculationRef: RefObject<WDMathLineature>
    tableRef: RefObject<WDTable>
    answerRef: RefObject<WDTextbox>
    answerLineatureRef: RefObject<WDWritingLineature>
}

export class WDTextExercise extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // Padding of the whole element (table border to container)
    PADDING = 10

    // Coords of the click on the resizer
    resizerClickCoords: Coords = new Coords(0, 0)
    // Value of the resized row or column when drag starts
    resizerElementValue: number = 0
    // Section being resized
    resizerElementSection?: WDTextExerciseSection

    loading: boolean = false

    constructor(props: IProps) {
        super(props)

        // New instance of text exercise created by user
        if (this.props.data.exerciseId === undefined && this.props.context === WSContextType.standard) {
            this.loadRandomExercise(this.props.data, this.props.data.toolboxItemId, this.props.data.calculationType,
                this.props.data.difficulty, this.props.data.topicId)
        }

        this.state = {
            isEdited: false,
            isDragging: false,
            focusElement: undefined,
            showNonPrintableObjects: this.props.showNonPrintableObjects,

            elementRef: React.createRef(),
            introductionRef: React.createRef(),
            calculationRef: React.createRef(),
            tableRef: React.createRef(),
            answerRef: React.createRef(),
            answerLineatureRef: React.createRef()
        }
    }

    componentDidMount() {
        if (this.props.context !== WSContextType.standard) {
            let newData = {...this.props.data}
            this.setVariables(newData, WDTextExerciseSection.INTRODUCTION)
            this.setVariables(newData, WDTextExerciseSection.ANSWER)

            this.props.onUpdateElement(new WorksheetItemUpdate(
                    this.props.id, {content: this.serializeElementData(newData)}),
                {actionCategory: WDActionLogCategory.content}
            )
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    loadRandomExercise = (data: WDTextExerciseData, toolboxItemId?: number, calculationType?: ExerciseCalculationType, difficulty?: Difficulty, topicId?: number, parentId?: number) => {
        let filter = new ExerciseFilter(ExerciseType.text_exercise)
        if (calculationType !== ExerciseCalculationType.none) {
            filter.calculationType = calculationType
            data.calculationType = calculationType || ExerciseCalculationType.none
        }
        if (toolboxItemId !== undefined && toolboxItemId > 0) {
            filter.menuEntry = new MenuItem(true, toolboxItemId, "")
            data.toolboxItemId = toolboxItemId
        }
        if (filter.difficulty) {
            filter.difficulty = difficulty
            data.difficulty = difficulty || Difficulty.none
        }
        if (topicId !== undefined && topicId > 0) {
            filter.menuEntry = new MenuItem(true, topicId, "")
            data.topicId = topicId
        }
        if (parentId !== undefined) {
            filter.parentExercise = new Entity("", parentId)
        }

        this.context?.log.info("Calculation Type = " + filter.calculationType + ", " +
            "Difficulty = " + filter.difficulty + ", " +
            "Menu Entry = " + filter.menuEntry + ", " +
            "Topic = " + filter.topicMenuEntry?.id + ", " +
            "Parent Exercise = " + filter.parentExercise
        )

        GetRandomExercise(filter)
            .then((exercise) => {
                    const exerciseId = exercise.id!

                    this.context?.log.info("Random exercise id = " + exerciseId)
                    this.context?.log.flush()

                    this.instantiateExercise(data, exerciseId)
                },
                (error) => {
                    this.context?.handleError(error, this.context.translate(translations.error.no_text_exercise_found))
                })
    }
    instantiateExercise = (data: WDTextExerciseData, exerciseId: number) => {
        GetExerciseWorksheetItems(exerciseId)
            .then(async (worksheetItems) => {
                    this.context.log.info("Worksheet item found!")
                    this.context.log.flush()

                    this.loading = true

                    let textExercise = worksheetItems.find(i => i.worksheetItemTypeId === WorksheetItemTypeEnum.TEXT_EXERCISE)
                    if (textExercise) {
                        // Delete existing child elements (images)
                        this.props.deleteChildren(this.props.id)

                        // Parse text exercise data and set data
                        let sourceData = JSON.parse(textExercise.content)

                        data.exerciseId = exerciseId
                        data.worksheetItemId = textExercise.id

                        data.introduction.data = await this.replaceDynamicElements(sourceData.introduction.data)
                        data.introduction.toggleEnabled = sourceData.introduction.visible
                        data.introduction.visible = sourceData.introduction.visible
                        data.introduction.height = sourceData.introduction.height

                        data.images.height = sourceData.images.height
                        data.images.toggleEnabled = sourceData.images.visible
                        data.images.visible = sourceData.images.visible

                        let calculationData = JSON.parse(sourceData.calculation.data)
                        calculationData.showSolution = this.props.data.showSolution
                        data.calculation.data = JSON.stringify(calculationData)
                        data.calculation.toggleEnabled = sourceData.calculation.visible
                        data.calculation.visible = sourceData.calculation.visible
                        data.calculation.height = sourceData.calculation.height

                        if (sourceData.table === undefined) {
                            let tableData = JSON.stringify(WDTableData.createEmptyTableData(2, 4, 36, 100));
                            data.table = new WDTextExerciseSectionData(120, tableData, WorksheetItemTypeEnum.TABLE)
                            data.table.toggleEnabled = false
                            data.table.visible = false
                        } else {
                            let tableData = JSON.parse(sourceData.table.data)
                            data.table.data = JSON.stringify(tableData)
                            data.table.toggleEnabled = sourceData.table.visible
                            data.table.visible = sourceData.table.visible
                            data.table.height = sourceData.table.height
                        }

                        let answerData = JSON.parse(await this.replaceDynamicElements(sourceData.answer.data))
                        answerData.showSolution = this.props.data.showSolution
                        data.answer.worksheetElementType = sourceData.answer.worksheetElementType
                        data.answer.data = JSON.stringify(answerData)
                        data.answer.toggleEnabled = sourceData.answer.visible
                        data.answer.visible = sourceData.answer.visible
                        data.answer.height = sourceData.answer.height

                        // Resize container to fit the retrieved worksheet item
                        let container: ElementLayout = ElementLayout.createFromWorksheetItem(textExercise)
                        container.left = this.props.element.posX
                        container.top = this.props.element.posY
                        container.height = this.getContainerHeight(data)
                        container = WDUtils.convertRectToAnchorObjectCoordinates(this.props.id, container)

                        // Load images of text exercise
                        let section = document.getElementById(this.props.id + "-section-images")
                        if (section) {
                            worksheetItems
                                .filter(i => i.worksheetItemTypeId === WorksheetItemTypeEnum.IMAGE)
                                .forEach(i => {
                                    let content = JSON.parse(i.content)
                                    content.showSolution = this.props.data.showSolution
                                    i.content = JSON.stringify(content)

                                    i = WorksheetItem.duplicate(i)

                                    this.props.addElementToGroup(this.props.id, i)
                                })
                        }

                        let update = new WorksheetItemUpdate(this.props.id, {
                            content: this.serializeElementData(data),
                            width: container.width,
                            height: container.height
                        })
                        this.props.onUpdateElement(update,
                            {actionCategory: WDActionLogCategory.create})

                        this.loading = false
                    }
                },
                (error) => {
                    this.context.log.error(error.message)
                    this.context.log.flush()

                    this.setState({error: error.message})
                })
    }

    replaceDynamicElements = async (sourceData: string) => {
        let textData = JSON.parse(sourceData)
        let div = document.createElement("div");
        div.innerHTML = textData.text;

        // Replace dynamic text placeholder
        let spans = div.querySelectorAll("span.variable-text");
        for (let i = 0; i < spans.length; i++) {
            let span = (spans[i] as HTMLElement)
            Util.unwrapNode(span)
        }

        // Replace names
        await this.replaceNameElements(div)

        textData.text = div.innerHTML

        return JSON.stringify(textData)
    }
    replaceNameElements = async (div) => {

        // get name config id by placeholder
        let names = div.querySelectorAll(".name-config-tag");
        let nameConfigIdList: number[] = []
        for (let i = 0; i < names.length; i++) {
            let name = (names[i] as HTMLElement)
            const id = name.getAttribute("nameconfigid")
            const genetive = name.getAttribute("genetive")

            if (id) {
                nameConfigIdList.push(+id)
            }

            this.context.log.info("Name Config = " + id + ", genetive = " + genetive)
        }

        const uniqueNameConfigs = nameConfigIdList.filter((v, i, a) => a.indexOf(v) === i)
        for (let configId of uniqueNameConfigs) {
            // Get name by name config
            let result = await GetNameByNameConfig(configId)

            for (let i = 0; i < names.length; i++) {
                let name = (names[i] as HTMLElement)
                const id = name.getAttribute("nameconfigid")

                // check name config id of
                if (id && +id === configId) {
                    const genetive = name.getAttribute("genetive")

                    // check genetive flag
                    let nameValue = result.name
                    if (genetive === "true" && result.genitive) {
                        nameValue = result.genitive
                    }

                    // create span tag which replaces name placeholder
                    let spanTag = document.createElement("span")
                    spanTag.className = "name-config-tag"
                    spanTag.innerText = nameValue

                    // custom tags
                    spanTag.setAttribute("nameConfigId", id)
                    if (genetive) {
                        spanTag.setAttribute("genetive", genetive)
                    }

                    if (name.parentNode) {
                        this.context.log.info("Name Config = " + id + ", genetive = " + genetive + " with " + nameValue)
                        name.parentNode.replaceChild(spanTag, name);
                    }
                }
            }
        }

        this.context.log.flush()
    }

    static getDefaultWidth = () => {
        return 470;
    }
    static getDefaultHeight = () => {
        return 420;
    }

    getSectionName = (section: WDTextExerciseSection) => {
        switch (section) {
            case WDTextExerciseSection.IMAGES:
                return "images"
            case WDTextExerciseSection.CALCULATION:
                return "calculation"
            case WDTextExerciseSection.TABLE:
                return "table"
            case WDTextExerciseSection.ANSWER:
                return "answer"
        }

        return "introduction"
    }
    getSectionStyle = (section: WDTextExerciseSection) => {
        const sectionName = this.getSectionName(section)

        let style: React.CSSProperties = {
            height: this.props.data[sectionName].visible ? this.props.data[sectionName].height : this.getHiddenSectionHeight()
        }
        if (!this.props.data[sectionName].visible && this.props.context === WSContextType.standard) {
            style.border = "none"
            style.borderWidth = "0"
            style.visibility = "hidden"
            style.paddingTop = "0"
            style.display = "none"
        }

        return style
    }

    getTextboxControlName = (section: WDTextExerciseSection): string => {
        let controlName = "txt-" + this.props.id
        if (section === WDTextExerciseSection.INTRODUCTION) {
            controlName += "-introduction"
        } else if (section === WDTextExerciseSection.ANSWER) {
            controlName += "-answer"
            if (this.props.data.answer.worksheetElementType === WorksheetItemTypeEnum.WRITING_LINEATURE) {
                controlName = "txt-line-" + controlName + "-lineature"
            }
        }

        return controlName
    }
    getVariableTextElements = (section: WDTextExerciseSection) => {
        const controlName = this.getTextboxControlName(section)

        let textarea = document.getElementById(controlName)
        if (textarea) {
            return textarea.querySelectorAll(".variable-text");
        }
    }
    setVariables = (data: WDTextExerciseData, section: WDTextExerciseSection) => {
        let sectionName = this.getSectionName(section)

        let spans = this.getVariableTextElements(section)
        if (spans) {
            data[sectionName].variable = []
            for (let i = 0; i < spans.length; i++) {
                let span = (spans[i] as HTMLElement)
                data[sectionName].variable.push(new WDTextExerciseSectionVariables(span.id, span.innerText))
            }
        }
    }

    getMinWidth = () => {
        return 300
    }
    getMinHeight = () => {
        return 120
    }
    getHiddenSectionHeight = () => {
        if (this.props.context === WSContextType.standard) {
            return 0
        }

        return 26
    }

    doAction = (action: WDToolbarAction, data?: any) => {
        let newData = {...this.props.data}
        let propagateUpdate = true

        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.CONVERT_TO_LINE:
                if (this.state.focusElement === WDTextExerciseSection.ANSWER) {
                    let answerText = ""

                    let answerControl = this.getTextboxControlName(WDTextExerciseSection.ANSWER)

                    let answerTextbox = document.getElementById(answerControl)
                    if (answerTextbox) {
                        answerText = answerTextbox.innerText
                    }

                    this.context.log.info("Current text = " + answerText)
                    this.loading = true

                    if (this.props.data.answer.worksheetElementType === WorksheetItemTypeEnum.TEXTBOX) {
                        this.context.log.info("Convert to lineature")
                        const lineatureData = new WDWritingLineatureData(answerText,
                            new WDTextboxData(answerText, this.props.data.showSolution, false, VerticalAlignment.top),
                            "D-AT-6-6-6-STANDARD", getDefaultFontName(),
                            WDWritingLineatureSize["6-6-6 mm"], 1, getDefaultCorrectionMargin(), 0)

                        newData.answer.worksheetElementType = WorksheetItemTypeEnum.WRITING_LINEATURE
                        newData.answer.data = JSON.stringify(lineatureData)
                    } else {
                        this.context.log.info("Convert to textbox")
                        const textboxData = new WDTextboxData(answerText, this.props.data.showSolution, false, VerticalAlignment.top)
                        newData.answer.worksheetElementType = WorksheetItemTypeEnum.TEXTBOX
                        newData.answer.data = JSON.stringify(textboxData)
                    }
                    this.context.log.flush()

                    this.loading = false
                }
                break

            case WDToolbarAction.TOGGLE_SECTION:
                if (data) {
                    this.onToggleSection(data.section)
                }
                break

            case WDToolbarAction.REGENERATE:
                if (this.props.data.exerciseId) {
                    this.context.log.info("Current exercise id = " + this.props.data.exerciseId)

                    let filter = new ExerciseFilter(ExerciseType.text_exercise)
                    if (this.props.data.calculationType !== ExerciseCalculationType.none) {
                        filter.calculationType = this.props.data.calculationType
                    }
                    if (this.props.data.toolboxItemId !== undefined && this.props.data.toolboxItemId > 0) {
                        filter.menuEntry = new MenuItem(true, this.props.data.toolboxItemId, "")
                    }
                    if (this.props.data.difficulty !== undefined) {
                        filter.difficulty = this.props.data.difficulty
                    }
                    if (this.props.data.topicId !== undefined && this.props.data.topicId > 0) {
                        filter.topicMenuEntry = new MenuItem(true, this.props.data.topicId, "")
                    }

                    this.context.log.info("Calculation Type = " + filter.calculationType + ", " +
                        "Difficulty = " + filter.difficulty + ", " +
                        "Menu Entry = " + filter.menuEntry + ", " +
                        "Topic = " + filter.topicMenuEntry?.id + ", "
                    )

                    propagateUpdate = false

                    GetExerciseSibling(this.props.data.exerciseId, filter).then((exercise) => {
                            const exerciseId = exercise.id!

                            this.context.log.info("Random exercise id = " + exerciseId)
                            this.context.log.flush()

                            this.instantiateExercise(newData, exerciseId)
                        },
                        (error) => {
                            this.context.log.error(error.message)
                            this.context.log.flush()

                            this.setState({error: this.context.translate(translations.error.no_text_exercise_found)})
                        })

                    this.context.log.flush()
                }
                break

            case WDToolbarAction.REMOVE_CALCULATION_PROCESS:
                this.state.calculationRef.current?.clearData(JSON.parse(newData.calculation.data))
                break

            case WDToolbarAction.CHANGE_DIFFICULTY_LEVEL:
                propagateUpdate = false
                newData.difficulty = data.difficultyLevel
                this.loadRandomExercise(newData, this.props.data.toolboxItemId,
                    this.props.data.calculationType, data.difficultyLevel, this.props.data.topicId)
                break

            case WDToolbarAction.CHANGE_VALUE_RANGE:
                propagateUpdate = false
                let toolboxItemId: number = WDTextExerciseDropDownValues.mapNumberRange(data.zr)
                newData.numberRange = data.zr
                newData.toolboxItemId = toolboxItemId

                this.loadRandomExercise(newData, toolboxItemId,
                    this.props.data.calculationType, this.props.data.difficulty, this.props.data.topicId)
                break

            case WDToolbarAction.CHANGE_CALCULATION_TYPE:
                propagateUpdate = false
                newData.calculationType = data.calculationType

                this.loadRandomExercise(newData, this.props.data.toolboxItemId,
                    data.calculationType, this.props.data.difficulty, this.props.data.topicId)
                break

            case WDToolbarAction.CHANGE_TOPIC:
                propagateUpdate = false
                newData.topicId = data.topicId

                this.loadRandomExercise(newData, this.props.data.toolboxItemId,
                    this.props.data.calculationType, this.props.data.difficulty, data.topicId)
                break

            case WDToolbarAction.CHANGE_SOLUTION_SHOW:
                newData.showSolution = !newData.showSolution

                this.state.calculationRef.current?.doAction(action, data)
                if (this.props.data.answer.worksheetElementType === WorksheetItemTypeEnum.TEXTBOX) {
                    this.state.answerRef.current?.doAction(action, data)
                } else {
                    this.state.answerLineatureRef.current?.doAction(action, data)
                }
                break

            case WDToolbarAction.SELECT_ITEM:
                propagateUpdate = false
                this.loadRandomExercise(newData, this.props.data.toolboxItemId,
                    this.props.data.calculationType, this.props.data.difficulty, this.props.data.topicId, data.id)
                break

            case WDToolbarAction.VARIABLE_TEXT:
                let id: string | undefined = undefined
                switch (this.state.focusElement) {
                    case WDTextExerciseSection.INTRODUCTION:
                        id = this.state.introductionRef.current?.onAddVariableText(this.onVariableTextInput)
                        break
                    case WDTextExerciseSection.ANSWER:
                        if (this.props.data.answer.worksheetElementType === WorksheetItemTypeEnum.TEXTBOX) {
                            id = this.state.answerRef.current?.onAddVariableText(this.onVariableTextInput)
                        } else {
                            id = this.state.answerLineatureRef.current?.onAddVariableText(this.onVariableTextInput)
                        }
                        break
                }

                if (id) {
                    let element = document.getElementById(id)
                    if (element) {
                        element.style.width = '0'
                        element.style.width = (element.scrollWidth + 10) + 'px'
                    }
                }

                break

            default:
                let u: WorksheetItemUpdate | undefined
                switch (this.state.focusElement) {
                    case WDTextExerciseSection.INTRODUCTION:
                        u = this.state.introductionRef.current?.doAction(action, data)
                        if (u && u.value.content) {
                            newData.introduction.data = u.value.content
                        }
                        break
                    case WDTextExerciseSection.CALCULATION:
                        u = this.state.calculationRef.current?.doAction(action, data)
                        if (u && u.value.content) {
                            newData.calculation.data = u.value.content
                        }
                        break
                    case WDTextExerciseSection.TABLE:
                        u = this.state.tableRef.current?.doAction(action, data)
                        if (u && u.value.content) {
                            newData.table.data = u.value.content
                        }
                        break
                    case WDTextExerciseSection.ANSWER:
                        if (this.props.data.answer.worksheetElementType === WorksheetItemTypeEnum.TEXTBOX) {
                            u = this.state.answerRef.current?.doAction(action, data)
                        } else {
                            u = this.state.answerLineatureRef.current?.doAction(action, data)
                        }

                        if (u && u.value.content) {
                            newData.answer.data = u.value.content
                        }
                        break
                }
        }

        if (!propagateUpdate) {
            update.value.content = this.serializeElementData(newData)
        }
        return update
    }

    /**
     * Overridden methods
     */
    onEditElement = async (editMode: boolean) => {
        if (editMode !== this.state.isEdited) {

            await this.state.introductionRef.current?.onEditElement(editMode)
            await this.state.tableRef.current?.onEditElement(editMode)

            // Unselect cells in math lineature
            if (!editMode) {
                this.state.calculationRef.current?.unselectCells()
            }

            this.setState({isEdited: editMode}, () => {
                if (editMode) {
                    this.state.introductionRef.current?.setFocus()
                }

                // Tell designer this is in edit mode
                // Cancels all event handlers to avoid element being dragged while in edit mode
                this.props.onElementEdit?.(this.props.id, editMode)
            })
        }
    }

    getSourceRecordId = () => {
        return this.props.data.exerciseId
    }

    hasNameConfigInstancesEnabled = (): boolean => {
        return true
    }
    getNameConfigInstances = (): number[] => {
        let result: number[] = this.state.introductionRef.current?.getNameConfigInstances() || []

        if (this.props.data.answer.worksheetElementType === WorksheetItemTypeEnum.TEXTBOX) {
            return result.concat(this.state.answerRef.current?.getNameConfigInstances() || [])
        }

        return result.concat(this.state.answerLineatureRef.current?.getNameConfigInstances() || [])
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        const layout = this.state.elementRef.current?.resizeElement(false, x, y)
        if (layout) {
            this.props.onElementResize?.(false, x, y)
        }
    }

    onFocus = async (section: WDTextExerciseSection) => {
        if (this.props.context === WSContextType.standard && section !== WDTextExerciseSection.IMAGES) {
            return
        }

        if (this.state.focusElement === section) {
            return
        }

        this.context.log.info("Focus Section = " + section + " vs. current = " + this.state.focusElement)

        switch (section) {
            case WDTextExerciseSection.INTRODUCTION:
                await this.state.introductionRef.current?.onEditElement(true)
                await this.state.calculationRef.current?.onEditElement(false)
                await this.state.answerRef.current?.onEditElement(true)
                await this.state.answerLineatureRef.current?.onEditElement(true)
                break
            case WDTextExerciseSection.CALCULATION:
                this.state.introductionRef.current?.onEditElement(false)
                this.state.calculationRef.current?.onEditElement(true)
                this.state.answerRef.current?.onEditElement(false)
                this.state.answerLineatureRef.current?.onEditElement(false)
                break
            case WDTextExerciseSection.TABLE:
                await this.state.introductionRef.current?.onEditElement(false)
                await this.state.calculationRef.current?.onEditElement(false)
                await this.state.answerRef.current?.onEditElement(false)
                await this.state.answerLineatureRef.current?.onEditElement(false)
                break
            case WDTextExerciseSection.IMAGES:
                await this.state.introductionRef.current?.onEditElement(false)
                await this.state.calculationRef.current?.onEditElement(false)
                await this.state.answerRef.current?.onEditElement(false)
                await this.state.answerLineatureRef.current?.onEditElement(false)

                // Clicking on an element in images section ends the edit mode of the element
                // so the images can be moved and resized. Clicking outside enters the edit mode again
                document.addEventListener("mousedown", this.onBlur)
                break
            case WDTextExerciseSection.ANSWER:
                await this.state.introductionRef.current?.onEditElement(false)
                await this.state.calculationRef.current?.onEditElement(false)
                await this.state.answerRef.current?.onEditElement(true)
                await this.state.answerLineatureRef.current?.onEditElement(true)
                break
        }

        this.context.log.flush()

        this.setState({focusElement: section}, () => {

            if (this.props.context === WSContextType.text_exercise_main) {
                let inputs = this.getVariableTextElements(section)
                if (inputs) {
                    for (let i = 0; i < inputs.length; i++) {
                        const input = inputs[i] as HTMLInputElement
                        input.addEventListener("input", this.onVariableTextInput)
                    }
                }
            }

            // If a section other than "images" gets the focus enter the edit mode to show the toolbar
            this.props.onElementEdit?.(this.props.id, section !== WDTextExerciseSection.IMAGES)
        })
    }
    onBlur = (e: MouseEvent) => {
        this.context.log.info("onBlur")
        let element = (e.target as HTMLElement)
        if (Util.getParentByClass(element, "ws-designer-text-exercise-section-images") === null &&
            Util.getParentByClass(element, "ws-designer-toolbar-container") === null &&
            Util.getParentByClass(element, "ws-designer-sidebar-container") === null) {

            document.removeEventListener("mousedown", this.onBlur)

            // Select the text exercise element again (unselected by mouse down event in designer)
            this.props.onElementSelect?.(this.props.id, true, false)

            // Put element in edit mode
            this.props.onElementEdit?.(this.props.id, true)
        }
        this.context.log.flush()
    }
    onToggleSection = (section: WDTextExerciseSection) => {
        let newData = {...this.props.data}
        switch (section) {
            case WDTextExerciseSection.INTRODUCTION:
                newData.introduction.visible = !newData.introduction.visible
                break
            case WDTextExerciseSection.CALCULATION:
                newData.calculation.visible = !newData.calculation.visible
                break
            case WDTextExerciseSection.TABLE:
                newData.table.visible = !newData.table.visible
                break
            case WDTextExerciseSection.IMAGES:
                newData.images.visible = !newData.images.visible
                break
            case WDTextExerciseSection.ANSWER:
                newData.answer.visible = !newData.answer.visible
                break
        }

        this.recalculateContainerSize(newData)
    }
    onVariableTextInput = (e: Event) => {
        let control = (e.target as HTMLInputElement)

        // append span element to get the width of the content
        let span = document.createElement("span")
        span.innerText = control.value
        span.className = "variable-text"
        control.parentNode!.insertBefore(span, control.nextSibling);

        control.defaultValue = control.value
        control.style.width = (span.offsetWidth + 10) + 'px'

        // Remove span element after retrieving its width and setting it to the input
        span.remove()
    }

    onChangeSection = async (sectionName: string, update: WorksheetItemUpdate) => {
        let newData = {...this.props.data}

        if (update.value.content) {
            newData[sectionName].data = update.value.content
        }

        this.props.onUpdateElement(new WorksheetItemUpdate(
                this.props.id, {content: this.serializeElementData(newData)}),
            {actionCategory: WDActionLogCategory.content}
        )
    }

    onFocusVariableText = () => {
        this.state.introductionRef.current?.onEditElement(false)
        this.state.calculationRef.current?.onEditElement(false)
        this.state.answerRef.current?.onEditElement(false)
        this.state.answerLineatureRef.current?.onEditElement(false)

        this.props.onElementEdit?.(this.props.id, false)
    }
    onChangeVariableText = (e: FormEvent, section: WDTextExerciseSection) => {
        const control = e.target as HTMLInputElement
        const controlId = control.id.replace("input-", "")

        const sectionTextbox = this.getTextboxControlName(section)

        let textarea = document.getElementById(sectionTextbox)
        if (textarea) {
            const variable = textarea.querySelector("span#" + controlId)
            if (variable) {
                variable.innerHTML = control.value
            }
        }
    }
    onClickSectionImages = (e: React.MouseEvent) => {
        this.onFocus(WDTextExerciseSection.IMAGES)
    }

    getContainerHeight = (data: WDTextExerciseData) => {
        return (data.introduction.visible ? data.introduction.height : this.getHiddenSectionHeight()) +
            (data.images.visible ? data.images.height : this.getHiddenSectionHeight()) +
            (data.calculation.visible ? data.calculation.height : this.getHiddenSectionHeight()) +
            (data.table.visible ? data.table.height : this.getHiddenSectionHeight()) +
            (data.answer.visible ? data.answer.height : this.getHiddenSectionHeight()) +
            (this.PADDING * 2)
    }

    recalculateContainerSize = (data: WDTextExerciseData) => {
        this.props.onUpdateElement(new WorksheetItemUpdate(this.props.id, {
                content: this.serializeElementData(data),
                height: this.getContainerHeight(data)
            }),
            {actionCategory: WDActionLogCategory.resize})
    }

    onMouseDownResizer = (e: React.MouseEvent<HTMLDivElement>, section: WDTextExerciseSection) => {
        this.context.log.debug("onMouseDownResizer")

        this.resizerClickCoords = new Coords(e.clientX, e.clientY)
        this.resizerElementSection = section
        this.context.log.debug("X: " + this.resizerClickCoords.x + " , Y: " + this.resizerClickCoords.y)

        switch (section) {
            case WDTextExerciseSection.INTRODUCTION:
                this.resizerElementValue = this.props.data.introduction.height
                break
            case WDTextExerciseSection.IMAGES:
                this.resizerElementValue = this.props.data.images.height
                break
            case WDTextExerciseSection.CALCULATION:
                this.resizerElementValue = this.props.data.calculation.height
                break
            case WDTextExerciseSection.TABLE:
                this.resizerElementValue = this.props.data.table.height
                break
            case WDTextExerciseSection.ANSWER:
                this.resizerElementValue = this.props.data.answer.height
                break
        }

        this.context.log.debug("Cached: " + this.resizerElementValue)

        document.addEventListener("mousemove", this.onMouseMoveResizer)
        document.addEventListener("mouseup", this.onMouseUpResizer)

        this.context.log.flush()
    }
    onMouseMoveResizer = async (e: MouseEvent) => {
        const newHeight = Math.max(this.resizerElementValue - (this.resizerClickCoords.y - e.clientY), 100)

        this.context.log.debug("Resize to " + newHeight)

        let newData = {...this.props.data}
        switch (this.resizerElementSection) {
            case WDTextExerciseSection.INTRODUCTION:
                newData.introduction.height = newHeight
                break
            case WDTextExerciseSection.IMAGES:
                newData.images.height = newHeight
                break
            case WDTextExerciseSection.CALCULATION:
                newData.calculation.height = newHeight
                break
            case WDTextExerciseSection.TABLE:
                newData.table.height = newHeight
                break
            case WDTextExerciseSection.ANSWER:
                newData.answer.height = newHeight
                break
        }

        this.context.log.debug("Height: " + newHeight)

        this.recalculateContainerSize(newData)

        this.context.log.flush(LogLevel.INFO)
    }
    onMouseUpResizer = () => {
        this.context.log.info("onMouseUp")

        document.removeEventListener("mousemove", this.onMouseMoveResizer)
        document.removeEventListener("mouseup", this.onMouseUpResizer)

        this.resizerElementSection = undefined

        this.recalculateContainerSize(this.props.data)
        this.context.log.flush()
    }

    // onChangeElementSize = async (oldLayout: ElementLayout, newLayout: ElementLayout): Promise<ElementLayout> => {
    //     const oldHeight = oldLayout.height - (2 * this.PADDING)
    //     const newHeight = newLayout.height - (2 * this.PADDING)
    //
    //     let newData = {...this.props.data}
    //     newData.introduction.height = newHeight * (newData.introduction.height / oldHeight)
    //     newData.images.height = newHeight * (newData.images.height / oldHeight)
    //     newData.calculation.height = newHeight * (newData.calculation.height / oldHeight)
    //     newData.table.height = newHeight * (newData.table.height / oldHeight)
    //     newData.answer.height = newHeight * (newData.answer.height / oldHeight)
    //
    //     // Recalculate height
    //     newLayout.height = this.getContainerHeight(newData)
    //
    //     this.props.onUpdateElement(new WorksheetItemUpdate(this.props.id, {
    //         content: this.serializeElementData(newData)
    //     }))
    //
    //     return newLayout
    // }
    // resizeElement = (proportional: boolean, x: number, y:number) => {
    //     // Ignore nodeType so the math lineature is not resized when a group containing the
    //     // lineature is resized
    //     return this.state.elementRef.current?.resizeElement(proportional, x, y)
    // }

    serializeElementData = (data: WDElementBaseData) => {
        return WDTextExerciseData.serialize(data as WDTextExerciseData)
    }
    getFocusElement = (): ElementDefinition | undefined => {
        // await this.updateElementData()
        if (this.state.focusElement === WDTextExerciseSection.INTRODUCTION) {
            return new ElementDefinition(
                this.props.id + "-introduction",
                WorksheetItemTypeEnum.TEXTBOX,
                new ElementProps(
                    new ElementLayout(0, 0, this.props.element.width, this.props.data.introduction.height),
                    new ElementTransformation(0, false, false, 0),
                    ElementBorder.defaultBorder(),
                    ElementFillStyle.defaultColor(),
                    this.state.introductionRef?.current?.getMinWidth(),
                    this.state.introductionRef?.current?.getMinHeight(),
                    Const.WorksheetDefaultPageWidth,
                    Const.WorksheetDefaultPageHeight,
                    false),
                this.props.data.introduction.data)
        } else if (this.state.focusElement === WDTextExerciseSection.CALCULATION) {
            return new ElementDefinition(
                this.props.id + "-calculation",
                WorksheetItemTypeEnum.MATH_LINEATURE,
                new ElementProps(
                    new ElementLayout(0, 0, this.props.element.width, this.props.data.calculation.height),
                    new ElementTransformation(0, false, false, 0),
                    ElementBorder.defaultBorder(),
                    ElementFillStyle.defaultColor(),
                    this.state.calculationRef?.current?.getMinWidth(),
                    this.state.calculationRef?.current?.getMinHeight(),
                    Const.WorksheetDefaultPageWidth,
                    Const.WorksheetDefaultPageHeight,
                    false),
                this.props.data.calculation.data)
        } else if (this.state.focusElement === WDTextExerciseSection.TABLE) {
            return new ElementDefinition(
                this.props.id + "-table",
                WorksheetItemTypeEnum.TABLE,
                new ElementProps(
                    new ElementLayout(0, 0, this.props.element.width, this.props.data.table.height),
                    new ElementTransformation(0, false, false, 0),
                    ElementBorder.defaultBorder(),
                    ElementFillStyle.defaultColor(),
                    this.state.calculationRef?.current?.getMinWidth(),
                    this.state.calculationRef?.current?.getMinHeight(),
                    Const.WorksheetDefaultPageWidth,
                    Const.WorksheetDefaultPageHeight,
                    false),
                this.props.data.table.data)
        } else if (this.state.focusElement === WDTextExerciseSection.ANSWER) {
            if (this.props.data.answer.worksheetElementType === WorksheetItemTypeEnum.TEXTBOX) {
                return new ElementDefinition(
                    this.props.id + "-answer",
                    WorksheetItemTypeEnum.TEXTBOX,
                    new ElementProps(
                        new ElementLayout(0, 0, this.props.element.width, this.props.data.answer.height),
                        new ElementTransformation(0, false, false, 0),
                        ElementBorder.defaultBorder(),
                        ElementFillStyle.defaultColor(),
                        this.state.answerRef?.current?.getMinWidth(),
                        this.state.answerRef?.current?.getMinHeight(),
                        Const.WorksheetDefaultPageWidth,
                        Const.WorksheetDefaultPageHeight,
                        false),
                    this.props.data.answer.data)
            }

            return new ElementDefinition(
                this.props.id + "-answer-lineature",
                WorksheetItemTypeEnum.WRITING_LINEATURE,
                new ElementProps(
                    new ElementLayout(0, 0, this.props.element.width, this.props.data.answer.height),
                    new ElementTransformation(0, false, false, 0),
                    ElementBorder.defaultBorder(),
                    ElementFillStyle.defaultColor(),
                    this.state.answerLineatureRef?.current?.getMinWidth(),
                    this.state.answerLineatureRef?.current?.getMinHeight(),
                    Const.WorksheetDefaultPageWidth,
                    Const.WorksheetDefaultPageHeight,
                    false),
                this.props.data.answer.data)
        }

        return undefined
    }

    onDrag = (e: React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (!this.state.isDragging) {
            this.setState({isDragging: true})
        }
    }
    onDragLeave = (e: React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (this.state.isDragging) {
            this.setState({isDragging: false})
        }
    }
    onDrop = async (e: React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer) {

            // Bind element to innerDesigner
            const elementId = WorksheetItem.getNewItemKey()
            const worksheetItemTypeId = e.dataTransfer.getData(Const.DATA_TRANSFER_WS_ITEM_ID)
            const configData = e.dataTransfer.getData(Const.DATA_TRANSFER_CONFIG_DATA)

            if (+worksheetItemTypeId === WorksheetItemTypeEnum.IMAGE) {

                let section = document.getElementById(this.props.id + "-section-images")
                if (section) {
                    const targetWidth = Converter.mmToPx(15)

                    let data = JSON.parse(configData) as WDImageData

                    const factor = data.width / targetWidth

                    let width = data.width / factor
                    let height = data.height / factor
                    let save = (data.status === Status.published)

                    // PageKey and PosZ are evaluated when added to group
                    let element = new WorksheetItem(elementId, "", +worksheetItemTypeId, Converter.toMmGrid(10),
                        Converter.toMmGrid(10), width, height, configData, true, false, false, false)
                    element.changed = true
                    element.save = save

                    this.props.addElementToGroup(this.props.id, element)
                }
            }

            this.setState({isDragging: false})
        }
    }

    onHideError = () => {
        this.setState({error: undefined})
    }

    isImageDragAllowed = () => {
        return (this.props.context === WSContextType.text_exercise_main || this.props.context === WSContextType.exercise)
    }

    renderResizer = () => {
        let resizer: JSX.Element[] = []

        // Row height resizer
        let posY = 0

        if (this.props.data.introduction.visible) {
            posY += this.props.data.introduction.height
            resizer.push(<div className={"ws-designer-table-resizer-y"}
                              key={this.props.id + "-resizer-y-1"}
                              style={{
                                  width: this.props.element.width,
                                  height: "10px",
                                  top: posY + this.PADDING - 5,
                                  left: 0
                              }}
                              onMouseDown={(e) => this.onMouseDownResizer(e, WDTextExerciseSection.INTRODUCTION)}
            />)
        } else {
            posY += this.getHiddenSectionHeight()
        }

        if (this.props.data.images.visible) {
            posY += this.props.data.images.height
            resizer.push(<div className={"ws-designer-table-resizer-y"}
                              key={this.props.id + "-resizer-y-2"}
                              style={{
                                  width: this.props.element.width,
                                  height: "10px",
                                  top: posY + this.PADDING - 5,
                                  left: 0
                              }}
                              onMouseDown={(e) => this.onMouseDownResizer(e, WDTextExerciseSection.IMAGES)}
            />)
        } else {
            posY += this.getHiddenSectionHeight()
        }

        if (this.props.data.calculation.visible) {
            posY += this.props.data.calculation.height
            resizer.push(<div className={"ws-designer-table-resizer-y"}
                              key={this.props.id + "-resizer-y-3"}
                              style={{
                                  width: this.props.element.width,
                                  height: "10px",
                                  top: posY + this.PADDING - 5,
                                  left: 0
                              }}
                              onMouseDown={(e) => this.onMouseDownResizer(e, WDTextExerciseSection.CALCULATION)}
            />)
        } else {
            posY += this.getHiddenSectionHeight()
        }

        if (this.props.data.table.visible) {
            posY += this.props.data.table.height
            resizer.push(<div className={"ws-designer-table-resizer-y"}
                              key={this.props.id + "-resizer-y-4"}
                              style={{
                                  width: this.props.element.width,
                                  height: "10px",
                                  top: posY + this.PADDING - 5,
                                  left: 0
                              }}
                              onMouseDown={(e) => this.onMouseDownResizer(e, WDTextExerciseSection.TABLE)}
            />)
        } else {
            posY += this.getHiddenSectionHeight()
        }

        if (this.props.data.answer.visible) {
            posY += this.props.data.answer.height
            resizer.push(<div className={"ws-designer-table-resizer-y"}
                              key={this.props.id + "-resizer-y-5"}
                              style={{
                                  width: this.props.element.width,
                                  height: "10px",
                                  top: posY + this.PADDING - 5,
                                  left: 0
                              }}
                              onMouseDown={(e) => this.onMouseDownResizer(e, WDTextExerciseSection.ANSWER)}
            />)
        }

        return resizer
    }
    renderVariableTextInput = (variables: WDTextExerciseSectionVariables[], section: WDTextExerciseSection) => {
        let width = this.props.element.width - (2 * this.PADDING)

        return <div className={"ws-designer-text-exercise-variables"} style={{left: width + 10}}>
            {variables.map((v, i) => {
                return <div className={"ws-designer-text-exercise-variables-entry"} key={"entry" + v.id}>
                    <label className={"bold-label"} htmlFor={"input-" + v.id}>{i + 1}</label>
                    <input id={"input-" + v.id}
                           type={"text"}
                           defaultValue={v.value}
                           onFocus={this.onFocusVariableText}
                           onKeyDown={(e) => e.stopPropagation()}
                           onInput={(e) => this.onChangeVariableText(e, section)}/>
                </div>
            })}
        </div>
    }

    render() {
        let resizeInfo: ResizeInfo =
            new ResizeInfo(!this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly,
                !this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly,
                this.getMinWidth(), Const.MaxElementSize, this.getMinHeight(), Const.MaxElementSize)

        let sectionImagesClass = "ws-designer-text-exercise-section ws-designer-text-exercise-section-images" +
            (this.state.isDragging ? " ws-designer-text-exercise-drag" : "") +
            (this.props.data.images.visible ? "" : " ws-designer-text-exercise-section-hidden")

        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }

        let width = this.props.element.width - (2 * this.PADDING)

        // Dynamic inline styles
        const introductionStyle = this.getSectionStyle(WDTextExerciseSection.INTRODUCTION)
        const imagesStyle = this.getSectionStyle(WDTextExerciseSection.IMAGES)
        const calculationStyle = this.getSectionStyle(WDTextExerciseSection.CALCULATION)
        const tableStyle = this.getSectionStyle(WDTextExerciseSection.TABLE)
        const answerStyle = this.getSectionStyle(WDTextExerciseSection.ANSWER)

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            hasResizeOnCreate={false}
            renderWrapper={true}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.props.onElementResize}
            onEdit={this.onEditElement}
            isEditModeAllowed={() => true}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
            resizeInfo={resizeInfo}
            onDragEnter={this.isImageDragAllowed() ? this.onDrag : undefined}
            onDragOver={this.isImageDragAllowed() ? this.onDrag : undefined}
            onDragLeave={this.isImageDragAllowed() ? this.onDragLeave : undefined}
            onDrop={this.isImageDragAllowed() ? this.onDrop : undefined}
        >

            {this.state.error &&
                <FloatingHint id={"error-text-exercise"}
                              notificationData={new NotificationData(NotificationStatus.info, this.state.error)}
                              style={{width: "500px", left: (width / 2) - 250, top: "15px"}}
                              animationTimeout={4000}
                              onHide={this.onHideError}/>
            }

            <div id={this.props.id + "-exercise"} className={"ws-designer-text-exercise"}>
                <table className={"ws-designer-text-exercise-table"}>
                    <tbody>
                    {/* Introduction (text field) */}
                    <tr>
                        <td className={"ws-designer-text-exercise-table-cell"} style={introductionStyle}>
                            <div
                                className={"ws-designer-text-exercise-section ws-designer-text-exercise-section-introduction"}
                                style={introductionStyle}>

                                <WDTextbox
                                    className={"ws-designer-textbox ws-designer-text-exercise-text"}
                                    isIndependentElement={false}
                                    id={this.props.id + "-introduction"}
                                    element={new WorksheetItem(
                                        this.props.id + "-introduction",
                                        this.props.element.worksheetPageKey,
                                        WorksheetItemTypeEnum.TEXTBOX,
                                        this.props.element.posX, this.props.element.posY,
                                        this.props.element.width, this.props.element.height, "{}", false, false,
                                        false, this.props.isReadOnly || this.props.context === WSContextType.standard
                                    )}
                                    fireUpdateOnBlur={false}
                                    hasSpellCheck={true}
                                    hasResizeOnCreate={false}
                                    onFocus={() => this.onFocus(WDTextExerciseSection.INTRODUCTION)}
                                    resizeOptions={{showResizer: false, showError: false}}
                                    style={!this.props.data.introduction.visible ? {visibility: "hidden"} : {}}
                                    isReadOnly={this.props.isReadOnly}
                                    onUpdateElement={update => this.onChangeSection("introduction", update)}
                                    onShowAttentionInForeground={this.props.onShowAttentionInForeground}
                                    solutionForceMode={this.props.solutionForceMode}
                                    showNonPrintableObjects={this.state.showNonPrintableObjects}
                                    inPresentationMode={this.props.inPresentationMode}
                                    data={JSON.parse(this.props.data.introduction.data)}
                                    updateHistory={this.props.updateHistory}
                                    pushHistory={this.props.pushHistory}
                                    context={this.props.context}
                                    ref={this.state.introductionRef}
                                />

                                {/* Section Label */}
                                {this.props.context !== WSContextType.standard &&
                                    <div className={"ws-designer-text-exercise-section-label"}
                                         style={{backgroundColor: "#d73548"}}>
                                        <div>{translations.text.exercise_sections.introduction.de}</div>
                                        {!this.props.isReadOnly &&
                                            <input id={"btnCloseIntroduction"} key={"btnCloseIntroduction"}
                                                   className={"cancel-cross"} type={"button"}
                                                   value={this.props.data.introduction.visible ? "-" : "+"}
                                                   onClick={() => this.onToggleSection(WDTextExerciseSection.INTRODUCTION)}/>
                                        }
                                    </div>
                                }
                            </div>

                            {/* Variable text block*/}
                            {this.props.context === WSContextType.text_exercise_child && this.props.data.introduction.visible && this.props.data.introduction.variable &&
                                this.renderVariableTextInput(this.props.data.introduction.variable, WDTextExerciseSection.INTRODUCTION)
                            }
                        </td>
                    </tr>

                    {/* Images (grouped and images) */}
                    <tr>
                        <td className={"ws-designer-text-exercise-table-cell"} style={imagesStyle}>
                            <div
                                id={this.props.id + "-section-images"}
                                className={sectionImagesClass}
                                style={imagesStyle}
                                onClick={this.onClickSectionImages}
                            >

                                {/* Section Label */}
                                {this.props.context !== WSContextType.standard &&
                                    <div className={"ws-designer-text-exercise-section-label"}
                                         style={{backgroundColor: "#6d80bf"}}>
                                        <div className="cursor-pointer" onClick={this.props.onSelectImage}>
                                            {translations.text.exercise_sections.images.de}
                                        </div>
                                        <input id={"btnCloseImages"} key={"btnCloseImages"}
                                               className={"cancel-cross"} type={"button"}
                                               value={this.props.data.images.visible ? "-" : "+"}
                                               onClick={() => this.onToggleSection(WDTextExerciseSection.IMAGES)}/>
                                    </div>
                                }

                                {this.props.images.map((element, index) => {
                                    return <div id={element.itemKey + "-container"}
                                                className={"ws-designer-element-container"}
                                                key={index}
                                                style={{
                                                    left: element.posX,
                                                    top: element.posY,
                                                    width: element.width,
                                                    height: element.height,
                                                    zIndex: (element.posZ === undefined ? WDUtils.getMaxPosZOfElements(this.props.images) : element.posZ)
                                                }}
                                                onDragOver={e => e.preventDefault()}
                                                onDragEnter={e => e.preventDefault()}
                                                onDragLeave={e => e.preventDefault()}
                                    >
                                        {this.props.renderElement(element)}
                                    </div>
                                })}
                            </div>
                        </td>
                    </tr>

                    {/* Calculation (math lineature) */}
                    <tr>
                        <td className={"ws-designer-text-exercise-table-cell"} style={calculationStyle}>
                            <div className={"ws-designer-text-exercise-section"} style={calculationStyle}>
                                <WDMathLineature id={this.props.id + "-calculation"}
                                                 element={new WorksheetItem(
                                                     this.props.id + "-calculation",
                                                     this.props.element.worksheetPageKey,
                                                     WorksheetItemTypeEnum.MATH_LINEATURE,
                                                     this.props.element.posX,
                                                     this.props.element.posY,
                                                     width,
                                                     this.props.data.calculation.height, "{}", false, false,
                                                     !this.props.data.calculation.visible, this.props.context === WSContextType.standard
                                                 )}
                                                 renderingMedia={this.props.renderingMedia}
                                                 isIndependentElement={false}
                                                 isReadOnly={this.props.isReadOnly}
                                                 onFocus={() => this.onFocus(WDTextExerciseSection.CALCULATION)}
                                                 solutionForceMode={this.props.solutionForceMode}
                                                 onShowAttentionInForeground={this.props.onShowAttentionInForeground}
                                                 onUpdateElement={update => this.onChangeSection("calculation", update)}
                                                 showNonPrintableObjects={this.state.showNonPrintableObjects}
                                                 inPresentationMode={this.props.inPresentationMode}
                                                 data={JSON.parse(this.props.data.calculation.data)}
                                                 updateHistory={this.props.updateHistory}
                                                 pushHistory={this.props.pushHistory}
                                                 context={this.props.context}
                                                 ref={this.state.calculationRef}
                                />

                                {/* Section Label */}
                                {this.props.context !== WSContextType.standard &&
                                    <div className={"ws-designer-text-exercise-section-label"}
                                         style={{backgroundColor: "#f2b748"}}>
                                        <div>{translations.text.exercise_sections.calculation.de}</div>

                                        <input id={"btnCloseCalculation"} key={"btnCloseCalculation"}
                                               className={"cancel-cross"} type={"button"}
                                               value={this.props.data.calculation.visible ? "-" : "+"}
                                               onClick={() => this.onToggleSection(WDTextExerciseSection.CALCULATION)}/>
                                    </div>
                                }
                            </div>
                        </td>
                    </tr>

                    {/* Table (table) */}
                    <tr>
                        <td className={"ws-designer-text-exercise-table-cell"} style={tableStyle}>
                            <div className={"ws-designer-text-exercise-section"} style={tableStyle}>
                                <WDTable id={this.props.id + "-table"}
                                         element={new WorksheetItem(
                                             this.props.id + "-table",
                                             this.props.element.worksheetPageKey,
                                             WorksheetItemTypeEnum.TABLE,
                                             this.props.element.posX, this.props.element.posY,
                                             this.props.element.width, this.props.element.height,
                                             "{}", false, false,
                                             !this.props.data.table.visible, this.props.context === WSContextType.standard
                                         )}
                                         renderingMedia={this.props.renderingMedia}
                                         isIndependentElement={false}
                                         hasResizeOnCreate={false}
                                         isReadOnly={this.props.isReadOnly}
                                         onFocus={() => this.onFocus(WDTextExerciseSection.TABLE)}
                                         data={JSON.parse(this.props.data.table.data)}
                                         context={this.props.context}
                                         solutionForceMode={this.props.solutionForceMode}
                                         showNonPrintableObjects={this.state.showNonPrintableObjects}
                                         inPresentationMode={this.props.inPresentationMode}
                                         addElementToDesigner={this.props.addElementToDesigner}
                                         addElementToGroup={this.props.addElementToGroup}
                                         onUpdateElement={update => this.onChangeSection("table", update)}
                                         onShowAttentionInForeground={this.props.onShowAttentionInForeground}
                                         updateHistory={this.props.updateHistory}
                                         pushHistory={this.props.pushHistory}
                                         ref={this.state.tableRef}
                                />

                                {/* Section Label */}
                                {this.props.context !== WSContextType.standard &&
                                    <div className={"ws-designer-text-exercise-section-label"}
                                         style={{backgroundColor: "#73a62d"}}>
                                        <div>{translations.text.exercise_sections.table.de}</div>

                                        <input id={"btnCloseTable"} key={"btnCloseTable"}
                                               className={"cancel-cross"} type={"button"}
                                               value={this.props.data.table.visible ? "-" : "+"}
                                               onClick={() => this.onToggleSection(WDTextExerciseSection.TABLE)}/>
                                    </div>
                                }
                            </div>
                        </td>
                    </tr>

                    {/* Answer (text field) */}
                    <tr>
                        <td className={"ws-designer-text-exercise-table-cell"} style={answerStyle}>
                            <div className={"ws-designer-text-exercise-section"} style={answerStyle}>
                                {this.props.data.answer.worksheetElementType === WorksheetItemTypeEnum.TEXTBOX ?

                                    <WDTextbox
                                        className={"ws-designer-textbox ws-designer-text-exercise-text"}
                                        isIndependentElement={false}
                                        id={this.props.id + "-answer"}
                                        element={new WorksheetItem(
                                            this.props.id + "-answer",
                                            this.props.element.worksheetPageKey,
                                            WorksheetItemTypeEnum.TEXTBOX,
                                            this.props.element.posX, this.props.element.posY,
                                            this.props.element.width, this.props.element.height,
                                            "{}", false, false,
                                            false, this.props.isReadOnly || this.props.context === WSContextType.standard
                                        )}
                                        hasResizeOnCreate={false}
                                        hasSpellCheck={true}
                                        fireUpdateOnBlur={false}
                                        onFocus={() => this.onFocus(WDTextExerciseSection.ANSWER)}
                                        resizeOptions={{showResizer: false, showError: false}}
                                        style={!this.props.data.answer.visible ? {visibility: "hidden"} : {}}
                                        isReadOnly={this.props.isReadOnly}
                                        solutionForceMode={this.props.solutionForceMode}
                                        showNonPrintableObjects={this.state.showNonPrintableObjects}
                                        inPresentationMode={this.props.inPresentationMode}
                                        onShowAttentionInForeground={this.props.onShowAttentionInForeground}
                                        onUpdateElement={update => this.onChangeSection("answer", update)}
                                        data={JSON.parse(this.props.data.answer.data)}
                                        updateHistory={this.props.updateHistory}
                                        pushHistory={this.props.pushHistory}
                                        context={this.props.context}
                                        ref={this.state.answerRef}
                                    />
                                    :
                                    <WDWritingLineature
                                        id={this.props.id + "-answer-lineature"}
                                        element={new WorksheetItem(
                                            this.props.id + "-answer-lineature",
                                            this.props.element.worksheetPageKey,
                                            WorksheetItemTypeEnum.WRITING_LINEATURE,
                                            this.props.element.posX, this.props.element.posY,
                                            this.props.element.width, this.props.element.height,
                                            "{}", false, false,
                                            false, this.props.isReadOnly || this.props.context === WSContextType.standard
                                        )}
                                        isIndependentElement={false}
                                        onFocus={() => this.onFocus(WDTextExerciseSection.ANSWER)}
                                        isReadOnly={this.props.isReadOnly}
                                        onShowAttentionInForeground={this.props.onShowAttentionInForeground}
                                        onUpdateElement={update => this.onChangeSection("answer", update)}
                                        data={JSON.parse(this.props.data.answer.data)}
                                        context={this.props.context}
                                        solutionForceMode={this.props.solutionForceMode}
                                        showNonPrintableObjects={this.state.showNonPrintableObjects}
                                        inPresentationMode={this.props.inPresentationMode}
                                        fixedPaddingSides={8}
                                        updateHistory={this.props.updateHistory}
                                        pushHistory={this.props.pushHistory}
                                        ref={this.state.answerLineatureRef}
                                    />
                                }

                                {/* Section Label */}
                                {this.props.context !== WSContextType.standard &&
                                    <div className={"ws-designer-text-exercise-section-label"}
                                         style={{backgroundColor: "#ee7c38"}}>
                                        <div>{translations.text.exercise_sections.answer.de}</div>

                                        <input id={"btnCloseAnswer"} key={"btnCloseAnswer"}
                                               className={"cancel-cross"} type={"button"}
                                               value={this.props.data.answer.visible ? "-" : "+"}
                                               onClick={() => this.onToggleSection(WDTextExerciseSection.ANSWER)}/>
                                    </div>
                                }
                            </div>

                            {/* Variable text block*/}
                            {this.props.context === WSContextType.text_exercise_child && this.props.data.answer.visible && this.props.data.answer.variable &&
                                this.renderVariableTextInput(this.props.data.answer.variable, WDTextExerciseSection.ANSWER)
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>

                {/* Resizer rows */}
                {!this.props.isReadOnly &&
                    this.renderResizer()
                }
            </div>
        </WDElementContainer>
    }
}
