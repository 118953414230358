import React from 'react';
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";
import ListFooter from "./ListFooter";

interface IProps {
    page: number
    pageCount: number
    manipulatePageNumberRepresentation: boolean
    text?: string

    onPageChange: (page: number) => void
}

export default class ListPagination extends React.Component<IProps> {
    
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <div className="list-pagination">
            {this.props.text &&
                <ListFooter text={this.props.text}/>
            }
            <div className={"list-pagination-data"}>
                <div>
                    <input type="image" src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_first.svg"}
                           onClick={() => this.props.onPageChange(this.props.manipulatePageNumberRepresentation ? 0 : 1)}
                           alt={this.context.translate(translations.command.first)}/>
                    <input type="image" src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_previous.svg"}
                           onClick={() => this.props.onPageChange(this.props.page - 1)}
                           alt={this.context.translate(translations.command.prev)}/>
                </div>

                <span style={{marginRight: "10px"}}>
                    {this.context.translate(translations.text.page)}&nbsp;
                    {this.props.page + (this.props.manipulatePageNumberRepresentation ? 1 : 0)} / {this.props.pageCount}
                </span>

                <div>
                    <input type="image" src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_next.svg"}
                           onClick={() => this.props.onPageChange(this.props.page + 1)}
                           alt={this.context.translate(translations.command.next)}/>
                    <input type="image" src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_last.svg"}
                           onClick={() => this.props.onPageChange(this.props.manipulatePageNumberRepresentation ? this.props.pageCount - 1 : this.props.pageCount)}
                           alt={this.context.translate(translations.command.last)}/>
                </div>
            </div>
        </div>
    }
}

