import Converter from "./Converter";

export class Coords {
    x: number
    y: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }

    toMmGrid = () => {
        this.x = Converter.toMmGrid(this.x)
        this.y = Converter.toMmGrid(this.y)
    }

    toString = () => {
        return "(" + this.x + " / " + this.y + ")"
    }

    equals = (coords: Coords) => {
        return (this.x === coords.x && this.y === coords.y)
    }

    static equals = (coords1: Coords[], coords2: Coords[]) => {
        if (coords1.length !== coords2.length) {
            return false
        }

        let i = 0

        while (i < coords1.length) {
            if (!coords1[i].equals(coords2[i])) {
                return false
            }

            i++
        }
        return true
    }
}
