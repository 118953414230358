import React from "react";
import {
    WDToolbarElement,
    WDToolbarElementProps,
    WDToolbarElementState
} from "../../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarButtonDeleteRow} from "../../../Toolbar/Button/Lineature/WDToolbarButtonDeleteRow";
import {WDRowPosition, WDToolbarButtonInsertRow} from "../../../Toolbar/Button/Lineature/WDToolbarButtonInsertRow";
import {WDToolbarButtonCorrectionMargin} from "../../../Toolbar/Button/Lineature/WDToolbarButtonCorrectionMargin";
import {WDToolbarButtonLineatureSize} from "../../../Toolbar/Button/Lineature/WDToolbarButtonLineatureSize";
import {WDToolbarButtonGraphics} from "../../../Toolbar/Button/General/WDToolbarButtonGraphics";
import {ImageCategory, ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {WDToolbarAction} from "../../../Toolbar/WDToolbarAction";
import {getDefaultCorrectionMargin} from "./WDWritingLineatureCorrectionMargin";
import {WDToolbarButtonRowSpacing} from "../../../Toolbar/Button/Lineature/WDToolbarButtonRowSpacing";
import Converter from "../../../../Framework/Converter";
import {MainContext} from "../../../../_base/MainContext";
import {WDWritingLineature, WDWritingLineatureData} from "./WDWritingLineature";
import {WDToolbarSectionObjectGeneral} from "../../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarSectionTransformation} from "../../../Toolbar/Section/WDToolbarSectionTransformation";
import {ElementLayout} from "../../WDElementContainer";
import {WDToolbarSectionText, WDToolbarSectionTextSettings} from "../../../Toolbar/Section/WDToolbarSectionText";
import {WDTextboxToolbarProps} from "../../Textbox/WDTextboxToolbar";
import {WDToolbarButtonLineColor} from "../../../Toolbar/Button/General/WDToolbarButtonLineColor";
import {TooltipText} from "../../../../Components/Tooltips";
import {WDToolbarButtonSolution} from "../../../Toolbar/Button/Admin/WDToolbarButtonSolution";
import {WDToolbarButtonSeparator} from "../../../Toolbar/Button/General/WDToolbarButtonSeparator";
import {WSContext, WSContextType} from "../../WSContext";
import {WDToolbarButtonConvertToLineText} from "../../../Toolbar/Button/Text/WDToolbarConvertToLineText";
import {WDToolbarButtonVariableText} from "../../../Toolbar/Button/Admin/WDToolbarButtonVariableText";
import {getFontSize, getFontValue} from "./WDWritingLineatureFontValues";
import {WDToolbarButtonConvertToSyllableText} from "../../../Toolbar/Button/Text/WDToolbarButtonConvertToSyllableText";
import {SyllableMethod} from "../../../../_model/Dictionary/Word";
import _ from "lodash";
import {WDMathLineatureProps, WDMathLineatureState} from "../MathLineature/WDMathLineatureToolbar";
import {Util} from "../../../../Framework/Util";

export interface WDToolbarWritingLineatureProps extends WDToolbarElementProps {
    syllableMethod: SyllableMethod
}
export interface WDToolbarWritingLineatureState extends WDToolbarElementState {}

export class WDWritingLineatureToolbar extends WDToolbarElement<WDToolbarWritingLineatureProps, WDToolbarWritingLineatureState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    textToolbarSettings: WDToolbarSectionTextSettings = new WDToolbarSectionTextSettings(true, false,
        true, true, true, true, true, true
        , false, false, true, true, true, false,
        undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        undefined, undefined, {key: "value", value: "Vicido"})

    constructor(props: WDToolbarWritingLineatureProps, state: WDToolbarWritingLineatureState) {
        super(props, state);

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef()
        }

        if(this.isUsedInTextExerciseChild()) {
            this.textToolbarSettings = new WDToolbarSectionTextSettings(false, false,
                false, false, false, false, false,
                false, false, false, false,
                false, false, false, undefined, undefined, undefined,
                undefined, undefined, undefined, undefined,
                undefined, undefined, {key: "value", value: "Vicido"})
        }
    }

    componentDidMount() {
        this.resetActiveId()
    }
    componentDidUpdate(prevProps: WDTextboxToolbarProps) {
        this.evaluateToolbarType(prevProps.editMode, this.props.editMode)

        // this.resetActiveId()
    }
    shouldComponentUpdate(nextProps: Readonly<WDMathLineatureProps>, nextState: Readonly<WDMathLineatureState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getElementGraphic = (obj: any) => {
        // #4266 Graphic of changeGraphic button should change matching to the element
        let iconString = ImagePath.getLineatureUrl() + "d-at-4-4-4-standard-shade-thumb.svg"
        if(obj) {
            iconString = ImagePath.getLineatureUrl() + obj.newValue.toString().toLowerCase() + "-thumb.svg"
            iconString = iconString.replace("6-6-6","4-4-4")
            iconString = iconString.replace("8-8-8","4-4-4")
            iconString = iconString.replace("12-6","8-4")
            iconString = iconString.replace("16-8","8-4")
        }

        return iconString
    }

    render () {
        // Get values to display data - important if more than one element is selected
        let fonts = this.multiselectSearchForValue("fontName")
        let individualFontSizes = this.multiselectSearchForValue("individualFontSize")
        let lineatureSizes = this.multiselectSearchForValue("lineature")
        let correctionMargins = this.multiselectSearchForValue("correctionMargin")
        let rowSpacings = this.multiselectSearchForValue("additionalRowSpacing")
        let graphics = this.multiselectSearchForValue("image")
        let lineColors = this.multiselectSearchForValue("lineColor")
        let activateSyllableValueObj = this.multiselectSearchForValue("syllableActivated")

        const font = (fonts?.valueEqual && fonts.newValue !== undefined) ? fonts.newValue : undefined
        const individualFontSize = (individualFontSizes?.valueEqual && individualFontSizes.newValue !== undefined) ? individualFontSizes.newValue : undefined
        const lineatureSize = (lineatureSizes?.valueEqual && lineatureSizes.newValue !== undefined) ? lineatureSizes.newValue : "10"
        const correctionMargin = (correctionMargins?.valueEqual && correctionMargins.newValue !== undefined) ? correctionMargins.newValue : getDefaultCorrectionMargin()
        const rowSpacing = (rowSpacings?.valueEqual && rowSpacings.newValue !== undefined) ? rowSpacings.newValue : 0
        const standardGraphic = (graphics?.valueEqual && graphics.newValue !== undefined && graphics.newValue.toString().toLowerCase().includes("standard"))
        const lineColor = (lineColors?.valueEqual && lineColors.newValue !== undefined) ? lineColors.newValue : undefined
        const activateSyllable = (activateSyllableValueObj?.valueEqual && activateSyllableValueObj.newValue !== undefined) ? activateSyllableValueObj.newValue : false

        // set initial fontSize
        let fontSize: string | null = null

        if (individualFontSize !== undefined) {
            fontSize = individualFontSize
        }
        else if (font !== undefined && lineatureSize !== undefined) {
            fontSize = getFontSize(font, lineatureSize)
        }
        this.textToolbarSettings.fontSize = fontSize !== null ? fontSize : undefined
        this.textToolbarSettings.fontType = font !== null ? font : undefined

        // allow fontSize changes when lineature is 10 and set maxValue only when not in child exercise
        if(font !== undefined && !this.isUsedInTextExerciseChild()) {
            this.textToolbarSettings.fontSizeAvailable = lineatureSize === "10"

            if(lineatureSize === "10") {
                let fontValue = getFontValue(font, lineatureSize)
                this.textToolbarSettings.maxValueFontSize = fontValue?.fontSize !== undefined ? +(fontValue.fontSize.replace("pt", "")) : undefined
            }
        }

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, true, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(WDWritingLineature.getDefaultHeight(lineatureSize))),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={this.isUsedInTextExerciseChild()}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={false}
                    canChangeLinkState={false}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.TEXT &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionText
                    onToolbarAction={this.props.onToolbarAction}
                    toolbarSettings={this.textToolbarSettings}
                />

                <WDToolbarButtonSeparator/>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonConvertToSyllableText
                            id={604}
                            pressed={activateSyllable}
                            clickable={this.props.syllableMethod !== SyllableMethod.noSyllable}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                            onShowConfirmation={this.props.onShowConfirmation}
                        />
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                    </div>
                </div>

                {/* Only in admin mode! */}
                {WSContext.showToolbarButtonMarkSolution(this.props.context) &&
                    <>
                        <WDToolbarButtonSeparator/>

                        <WDToolbarButtonSolution
                            id={602}
                            clickable={true}
                            icon={"solution_mark.svg"}
                            onActionButton={this.onToolbarAction}
                            action={WDToolbarAction.SOLUTION}
                        />
                    </>
                }

                {WSContext.showToolbarButtonVariableText(this.props.context) &&
                    <>
                        <WDToolbarButtonSeparator/>

                        <div className={"ws-designer-toolbar-section"}>
                            <div className={"ws-designer-toolbar-row"}>
                                <WDToolbarButtonConvertToLineText
                                    id={601}
                                    pressed={false}
                                    clickable={true}
                                    onActionButton={this.onToolbarAction}
                                    onToggleButton={this.onToggleButton}
                                />
                            </div>
                            <div className={"ws-designer-toolbar-row"}>
                                <WDToolbarButtonVariableText
                                    id={603}
                                    clickable={true}
                                    onActionButton={this.onToolbarAction}
                                    action={WDToolbarAction.VARIABLE_TEXT}
                                />
                            </div>
                        </div>
                    </>
                }
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonInsertRow
                            id={2}
                            pressed={false}
                            clickable={true}
                            position={WDRowPosition.NONE}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonDeleteRow
                            id={1}
                            pressed={false}
                            clickable={true}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarButtonGraphics
                        id={5}
                        pressed={this.state.activeId === 5}
                        clickable={this.props.context !== WSContextType.text_exercise_child}
                        onToggleButton={this.onToggleButton}
                        onActionButton={this.props.onToolbarAction}

                        icon={this.getElementGraphic(graphics)}
                        iconButtonWidth={90}
                        iconButtonHeight={69}
                        iconImageWidth={70}
                        iconImageHeight={72}

                        imageCategory={[WDWritingLineatureData.getImageCategory(lineatureSize)]}
                        imageWidth={155}
                        imageHeight={70}
                        imagePath={ImagePath.getLineatureUrl()}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic),
                            this.context.translate(translations.tooltip.change_graphic))}/>
                </div>

                <div className={"ws-designer-toolbar-section"} style={{marginRight: "5px"}}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonLineatureSize
                            id={6}
                            readonly={this.isUsedInChildExercise()}
                            size={lineatureSize}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonRowSpacing
                            id={7}
                            clickable={lineatureSize !== "10" && !this.isUsedInChildExercise()}
                            rowSpacing={Math.round(Converter.pxToMm(rowSpacing))}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonLineColor
                            id={8}
                            clickable={standardGraphic && !this.isUsedInTextExerciseChild()}
                            pressed={lineColor !== undefined}
                            lineColor={lineColor}
                            open={this.state.activeId === 8}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonCorrectionMargin
                            id={4}
                            correctionMarginData={correctionMargin}
                            options={{
                                action: WDToolbarAction.CORRECTION_MARGIN,
                                open: this.state.activeId === 4,
                                imageCategory: ImageCategory.WRITING_LINEATURE_CORRECTION_MARGIN,
                                imagePath: ImagePath.getButtonUrl(),
                                width: 110,
                                height: 50,
                                itemWidth: 32,
                                itemHeight: 32
                            }}
                            clickable={!this.isUsedInChildExercise() }
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={true}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    elementProps={this.props.elementProps}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onChangeBorder={this.props.onChangeBorder}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onElementDeleted={this.props.onElementDeleted}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                />
            </ul>
            }
        </WDToolbar>
    }
}
