import {Util} from "../Framework/Util";
import {
    ElementBorder,
    ElementLayout,
} from "../Designer/Elements/WDElementContainer";
import Const from "../Framework/Const";
import Entity from "./Entity";
import {WDElementBase} from "../Designer/Elements/WDElementBase";
import {RefObject} from "react";
import {WorksheetItemType} from "./WorksheetItemType";
import {WDUtils} from "../Designer/Utils/WDUtils";
import ProblemReport from "./base/ProblemReport";

export class WorksheetItem {
    id?: number
    itemKey: string
    worksheetPageKey: string
    worksheetItemTypeId: number
    posX: number
    posY: number
    posZ?: number
    width: number
    height: number
    rotation: number
    flipHorizontal: boolean
    flipVertical: boolean
    fillColor: string
    transparency: number
    skew: number

    children: WorksheetItem[]
    groupKey?: string
    groupId?: Entity
    linkedWorksheetItemId?: WorksheetItem
    sourceRecordId?: number

    borderVisible: boolean
    borderStyle: string
    borderColor: string
    borderWeight: number
    paddingTop: number
    paddingRight: number
    paddingBottom: number
    paddingLeft: number
    linkPadding: boolean

    content: string

    minWidth: number
    maxWidth: number
    minHeight: number
    maxHeight: number

    selectX: number = 0
    selectY: number = 0
    selected: boolean = false
    edited: boolean = false
    resized: boolean = false
    deleted: boolean = false
    changed: boolean = false
    locked: boolean = false

    attentionInForeground: boolean = false

    save: boolean = true
    renderSolutionInPresentationMode: boolean = false
    problemReport?: ProblemReport

    ref?: RefObject<WDElementBase>

    constructor(
        itemKey: string,
        worksheetPageKey: string,
        worksheetItemTypeId: number,
        posX: number,
        posY: number,
        width: number,
        height: number,
        content: string,
        selected: boolean,
        resized: boolean,
        deleted: boolean,
        locked: boolean,

        posZ?: number,
        rotation?: number,
        flipHorizontal?: boolean,
        flipVertical?: boolean,
        fillColor?: string,
        transparency?: number,
        skew?: number,

        borderVisible?: boolean, // used for border button toggle (not saved in database) to know old values but don't show border
        borderStyle?: string,
        borderColor?: string,
        borderWeight?: number,
        paddingTop?: number,
        paddingRight?: number,
        paddingBottom?: number,
        paddingLeft?: number,
        linkPadding?: boolean,

        minWidth?: number,
        maxWidth?: number,
        minHeight?: number,
        maxHeight?: number,

        groupId?: Entity,
        linkedWorksheetItemId?: WorksheetItem,
        sourceRecordId?: number
    ) {

        this.itemKey = itemKey
        this.worksheetPageKey = worksheetPageKey
        this.worksheetItemTypeId = worksheetItemTypeId
        this.posX = posX
        this.posY = posY
        this.posZ = posZ
        this.width = width
        this.height = height
        this.content = content
        this.selected = selected
        this.resized = resized
        this.deleted = deleted
        this.locked = locked
        this.save = true
        this.changed = true
        this.renderSolutionInPresentationMode = false

        this.rotation = rotation || 0
        this.flipHorizontal = flipHorizontal || false
        this.flipVertical = flipVertical || false
        this.fillColor = fillColor || "transparent"
        this.skew = skew || 0
        this.transparency = transparency || 100

        this.borderVisible = borderVisible || false
        this.borderStyle = borderStyle || "none"
        this.borderColor = borderColor || "transparent"
        this.borderWeight = borderWeight || 0
        this.paddingTop = paddingTop || 0
        this.paddingRight = paddingRight || 0
        this.paddingBottom = paddingBottom || 0
        this.paddingLeft = paddingLeft || 0
        this.linkPadding = linkPadding || true

        this.children = []
        this.groupId = groupId

        this.minWidth = minWidth ? minWidth : Const.MinElementSize
        this.maxWidth = maxWidth ? maxWidth : Const.WorksheetDefaultPageHeight
        this.minHeight = minHeight ? minHeight : Const.MinElementSize
        this.maxHeight = maxHeight ? maxHeight : Const.WorksheetDefaultPageHeight

        this.linkedWorksheetItemId = linkedWorksheetItemId
        this.sourceRecordId = sourceRecordId
        this.ref = WorksheetItemType.getReactRefByWorksheetItemType(worksheetItemTypeId)
    }

    static getElementSize(element: WorksheetItem) {
        if (element.rotation !== 0) {
            return WDUtils.getBoundingRectOfElement(element)
        }

        return new ElementLayout(element.posX, element.posY, element.width, element.height)
    }
    static getElementBorder(element: WorksheetItem) {
        return ElementBorder.createBorderOptions(
            WorksheetItem.getBorderVisible(element),
            element.borderStyle,
            element.borderColor,
            element.borderWeight,
            element.paddingLeft,
            element.paddingTop,
            element.paddingRight,
            element.paddingBottom,
            element.linkPadding)
    }
    static getProps(element: WorksheetItem) {
        return {
            id: element.itemKey,
            element: element,
            data: Util.isValidJson(element.content) ? JSON.parse(element.content) : undefined
        }
    }
    static getBorderVisible (element: WorksheetItem) {
        return element.borderVisible && element.borderStyle !== "none"
    }

    static getNewItemKey () {
        return "element-" + Date.now() + Math.floor(100000 + Math.random() * 900000)
    }

    static clone(item: WorksheetItem): WorksheetItem {
        let newItem = {...item}
        newItem.changed = true
        // newItem.children = newItem.children.map(child => WorksheetItem.clone(child))
        newItem.ref = WorksheetItemType.getReactRefByWorksheetItemType(newItem.worksheetItemTypeId)
        return newItem
    }

    static duplicate(item: WorksheetItem, offsetX?: number, offsetY?: number, groupKey?: string, pageKey?: string): WorksheetItem {
        let newItem = {...item}
        newItem.itemKey = WorksheetItem.getNewItemKey()
        newItem.id = undefined
        newItem.worksheetPageKey = pageKey || item.worksheetPageKey
        newItem.posX += offsetX || 0
        newItem.posY += offsetY || 0
        newItem.groupId = undefined
        newItem.groupKey = groupKey
        newItem.changed = true
        if (newItem.children === undefined) {
            newItem.children = []
        }
        newItem.children = newItem.children.map(child => WorksheetItem.duplicate(child, 0, 0, newItem.itemKey, pageKey))
        newItem.ref = WorksheetItemType.getReactRefByWorksheetItemType(newItem.worksheetItemTypeId)
        return newItem
    }

    static copyData(source: WorksheetItem, target: WorksheetItem) {
        let excludeAttributes = [
            "id", "linkedWorksheetItemId", "itemKey", "worksheetPageId", "worksheetItemTypeId", "content",
            "createdBy", "createdOn", "modifiedBy", "modifiedOn"
        ]

        for(let k in source) {
            if (!excludeAttributes.includes(k)) {
                target[k] = source[k];
            }
        }
    }

    static getElementContainer(item: WorksheetItem) {
        return document.getElementById(item.itemKey + "-container")
    }

    static getParentWorkspace(item: WorksheetItem) {
        const sheet = Util.getRequiredElementById(item.worksheetPageKey)
        return Util.getParentByClass(sheet, "ws-designer-sheet-workspace")
    }
}
