import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {DropDown, DropDownType} from "../../../../Components/Controls/DropDown";
import {Util} from "../../../../Framework/Util";
import {Tooltips, TooltipText} from "../../../../Components/Tooltips";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";

/**
 * Number input and drop down in Text Toolbar
 * Effects selected text of an element
 * If a value is entered or selected from the list the font size is changed
 */

interface IProps {
    id: number
    action: WDToolbarAction
    readonly: boolean

    initialValue?: string
    maxValue?: number

    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    value: string
}

export class WDToolbarFontSize extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props)

        let initialValue = this.props.initialValue ? this.props.initialValue.replace(/[^0-9.]/g, "") : undefined

        this.state = {
            value: initialValue || "18"
        }
    }

    componentDidMount() {
        document.addEventListener('selectionchange', this.onSelectionChange, false);
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({value: this.props.initialValue || "18"})
        }
    }
    componentWillUnmount() {
        document.removeEventListener('selectionchange', this.onSelectionChange, false);
    }

    initDropDownListItems = () => {
        let defaultListItems = [
            { value: "8", name: "8 pt" },
            { value: "9", name: "9 pt" },
            { value: "10", name: "10 pt" },
            { value: "11", name: "11 pt" },
            { value: "12", name: "12 pt" },
            { value: "14", name: "14 pt" },
            { value: "16", name: "16 pt" },
            { value: "18", name: "18 pt" },
            { value: "20", name: "20 pt" },
            { value: "24", name: "24 pt" },
            { value: "36", name: "36 pt" },
            { value: "48", name: "48 pt" },
            { value: "60", name: "60 pt" },
            { value: "72", name: "72 pt" }
        ]

        if(this.props.maxValue) {
            defaultListItems = defaultListItems.filter(item => {return +item.value < this.props.maxValue!})
        }

        return defaultListItems
    }

    onSelectionChange = () => {
        // Default font size
        let fontValue = this.state.value

        const selection = window.getSelection()
        if (selection && selection.focusNode) {
            const selectedElement = selection.focusNode as HTMLElement

            // Get the textbox element and check if selected item is in text
            const parent = Util.getParentByClass(selectedElement, "ws-designer-textbox")
            if (parent) {
                // Get the font tag "above" the current node, stop at textbox which is the container
                // const font = Util.getParentByTag(selectedElement, "font", parent.id)
                const font = Util.getParentByStyle(selectedElement, "font-size", "", parent.id, [])

                // If found, get the font size and set it as current value
                if (font) {
                    const fontSize = font.style.fontSize
                    fontValue = fontSize.replace("pt", "").trim()

                    this.setState({value: fontValue})
                }
                else {
                    this.setState({value: "18"})
                }
            }
        }
    }

    onChangeValue = (value: string) => {
        this.setState({value: value})
        this.props.onActionButton(this.props.action, {command: "fontSize", size: value})
    }

    render() {
        let listItems = this.initDropDownListItems()

        return <li
            className={this.props.readonly ? "tooltip-above ws-designer-toolbar-button-disabled" : "tooltip-above"}
            key={"ws-designer-toolbar-font-size"}
            id={"font-size"}>

            <DropDown id={"font-size"}
                      className={"tooltip-control"}
                      width={80}
                      readonly={this.props.readonly}
                      required={false}
                      autocomplete={false}
                      minValue={1} maxValue={this.props.maxValue || 500}
                      type={DropDownType.NUMBER}
                      unit={"pt"} unitWidth={12} unitPaddingRight={3}
                      value={this.state.value}
                      items={listItems}
                      onChangeValue={this.onChangeValue} />

            <Tooltips
                text={new TooltipText(
                    this.context.translate(translations.toolbar.font_size),
                    this.context.translate(translations.tooltip.font_size)
                )}
                translateX={-40} translateY={-34} />
        </li>
    }
}
