import React from 'react';
import {ColumnDefinition} from "../../Components/List/List";
import {Resource} from "../../Framework/Util";
import translations from "../../Framework/translations.json";
import {AdminContentType, AdminObjectType, ColumnRenderOption, EnumToEntityArray, Status} from "../../Framework/Enums";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import User from "../../_model/User";
import {AdminUtils} from "../Maintenance/AdminUtils";
import SearchResultListItem from "./SearchResultListItem";
import {GetAllSearchResults, UpdateSearchResult} from "../../_endpoint/SearchResultEndpoint";
import ApprovalItem from "../../_model/ApprovalItem";
import SearchResult from "../../_model/SearchResult";
import {ListFilterDefinition, ListFilterDefinitionItem} from "../../Components/List/ListFilter";
import Entity from "../../_model/Entity";
import {GetAllAdmins} from "../../_endpoint/UserEndpoint";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}

interface IState {
    items: SearchResult[]
    filter: ListFilterDefinition[]
    listItems?: SearchResultListItem[]
    pageSize: number
    admins?: User[]
}

export default class SearchResultList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition("", "url", 7, true, true, ColumnRenderOption.Icon),
        new ColumnDefinition(this.context.translate(translations.fields.search_text), "name", 20, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.type), "type", 12, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.result_amount), "resultAmount", 15, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 10, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_on), "createdOn", 18, false, true, ColumnRenderOption.DateTime),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 18, false, true)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            filter: [],
            pageSize: 100
        }
    }

    componentDidMount() {
        this.fetchData()

        GetAllAdmins().then((result) => {
            this.setState({admins: result}, this.setFilter)
        }, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })

        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })
    }

    fetchData = () => {
        this.setState({items: []})

        GetAllSearchResults().then(
            (itemData) => {
                const listItems = itemData.map(item => {
                    let type: Resource | null = null
                    let config

                    let status = translations.enum.search_result_status[item.status]

                    if (item.type) {
                        type = translations.enum.admin_object_type[item.type]
                        config = ApprovalItem.getItemConfig(item)
                    }

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    return new SearchResultListItem(
                        item.id!,
                        item.name,
                        this.context.translate(status),
                        config ? config.url : "",
                        type ? this.context.translate(type) : "",
                        item.createdOn!,
                        owner,
                        item.resultAmount
                    )
                })

                this.setState({items: itemData, listItems: listItems})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    setFilter = () => {
        let filterDefinitions = [...this.state.filter]

        let types = [new ListFilterDefinitionItem(-1, this.context.translate(translations.fields.filter.all_types))]
        for (const item of Object.values(AdminContentType)) {
            const i = Object.values(AdminContentType).indexOf(item);
            types.push(new ListFilterDefinitionItem(i, this.context.translate(translations.enum.admin_content_type[item])))
        }
        filterDefinitions.push(new ListFilterDefinition("type", types, this.onFilterType, -1))

        let status = [new ListFilterDefinitionItem(-1, this.context.translate(translations.fields.filter.all_status))]
        for (const item of Object.values(Status)) {
            const i = Object.values(Status).indexOf(item);
            status.push(new ListFilterDefinitionItem(i, this.context.translate(translations.enum.search_result_status[item])))
        }
        filterDefinitions.push(new ListFilterDefinition("status", status, this.onFilterStatus, -1))

        // Prepare owner for filter
        if (this.state.admins) {
            let ownerList = [
                new ListFilterDefinitionItem(-1, this.context.translate(translations.fields.filter.all_owner)),
                ...this.state.admins.map(s => new ListFilterDefinitionItem(s.id!, s.firstname + " " + s.lastname))
            ]
            filterDefinitions.push(new ListFilterDefinition("owner", ownerList, (items, value) => this.onFilterUser(items, value, "owner"), -1))
        }

        this.setState({filter: filterDefinitions})
    }
    onFilterStatus = (items: Entity[], value: number) => {
        let statusArray = EnumToEntityArray(Status, translations.enum.search_result_status, this.context.translate)
        let status = statusArray.find(s => s.id === value)

        if (status) {
            return items.filter(item => item["status"] === status!.name)
        }

        // Filter deactivated and published items if no specific status is chosen
        return items.filter(item =>
            item["status"] !== translations.enum.search_result_status.deactivated.de &&
            item["status"] !== translations.enum.search_result_status.published.de)
    }
    onFilterUser = (items: Entity[], value: number, field: string) => {
        if (value < 0) {
            return items
        }

        return items.filter(e => {
            let admin = this.state.admins?.find(i => i.id === value)

            if (admin) {
                return e[field] === admin.firstname + " " + admin.lastname
            }
            return false
        })
    }
    onFilterType = (items: Entity[], value: number) => {
        let array = EnumToEntityArray(AdminContentType, translations.enum.admin_content_type, this.context.translate)
        let type = array.find(s => s.id === value)

        if (type) {
            return items.filter(item => item["type"] === type!.name)
        }

        return items
    }

    onSetState = async (item: number, status: Status, user?: User) => {
        try {
            const element = this.state.items.find(d => d.id === item)
            if (element && element.status !== status) {
                SearchResult.setStatus(element, status, user)

                await UpdateSearchResult(element)
                return true
            }
        } catch (e) {
        }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.search_result) + "{0}"}
                              objectType={AdminObjectType.search_result}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={true}
                              allowMultiSelect={true}
                              showActivate={false}
                              showDeactivate={true}
                              paginationOptions={{
                                  allowPagination: true,
                                  showPagination: true,
                                  pageSize: this.state.pageSize,
                                  manipulatePageNumberRepresentation: true
                              }}
                              searchOptions={{showSearch: true, typeAheadSearch: true, showSearchOptions: false}}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "createdOn",
                                  sortDescending: true
                              }}
                              filterOptions={{
                                  externalFilter: false,
                                  filterDefinitions: this.state.filter,
                                  removeDefaultFilter: true
                              }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}
