import React from 'react';
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import {AdminObjectType, ColumnRenderOption, Status} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import Exercise from "../../_model/Exercise";
import {CloneExercise, GetExercise, GetTextExerciseList, UpdateExercise} from "../../_endpoint/ExerciseEndpoint";
import User from "../../_model/User";
import TextExerciseListItem, {ChildStatus} from "./TextExerciseListItem";
import Const from "../../Framework/Const";
import {WSContextType} from "../../Designer/Elements/WSContext";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: Exercise[]
    listItems?: TextExerciseListItem[]
    pageSize: number
}

export default class TextExerciseList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 19, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.exercise_count), "exerciseCount", 8, false, false, ColumnRenderOption.Status),
        new ColumnDefinition(this.context.translate(translations.fields.calculation_type), "calculationType", 15, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.school_level), "schoolLevel", 15, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 13, false, true, ColumnRenderOption.DateTime)
    ]
    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            pageSize: 100
        }
    }

    componentDidMount() {
        this.fetchData()

        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })
    }

    fetchData = () => {
        this.setState({ items: [] })

        GetTextExerciseList().then(
            (itemData) => {

                const listItems = itemData.map(item => {
                    // Status-Text (translated)
                    let status = translations.enum.status[item.status]

                    // Calculation Type-Text (translated)
                    let calculationType = translations.enum.exercise_calculation_type.none
                    if (item.calculationType) {
                        calculationType = translations.enum.exercise_calculation_type[item.calculationType]
                    }

                    let schoolLevel = ""
                    if (item.schoolLevels) {
                        schoolLevel = item.schoolLevels
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(r => r.name).join(", ")
                    }

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    let childStatus = new ChildStatus(0, 0, 0)
                    if (item.exercises) {
                        childStatus = new ChildStatus(
                            item.exercises.filter(c => c.status === Status.published).length,
                            item.exercises.filter(c => c.status === Status.approval).length,
                            item.exercises.filter(c => c.status === Status.draft).length
                        )
                    }
                    
                    return new TextExerciseListItem(
                        item.id!,
                        item.name,
                        this.context.translate(status),
                        childStatus,
                        this.context.translate(calculationType),
                        owner,
                        item.modifiedOn || item.createdOn!,
                        createdBy,
                        schoolLevel,
                    )
                })

                this.setState({ items: itemData, listItems: listItems })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    onCopyItem = async(items: number[]) => {
        try {
            for (const id of items) {
                const exercise = await GetExercise(id)
                if (exercise && exercise.status !== Status.deactivated) {

                    let clone: Exercise = { ...exercise }
                    clone.id = undefined
                    clone.status = Status.draft

                    await CloneExercise(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy), WSContextType.text_exercise_main)
                }
            }

            this.fetchData()
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))
        }
        catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }

    onSetState = async(item: number, status: Status, user?: User) => {
        try {
            const exercise = this.state.items.find(d => d.id === item)
            if (exercise) {
                Exercise.setStatus(exercise, status, user)
                await UpdateExercise(exercise)

                return true
            }
        } catch (e) { }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.text_exercise_main) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.text_exercise_main) + "{0}/list"}
                              objectType={AdminObjectType.text_exercise_main}
                              onCopyItem={this.onCopyItem}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              paginationOptions={{
                                  allowPagination: true,
                                  showPagination: true,
                                  pageSize: this.state.pageSize,
                                  manipulatePageNumberRepresentation: true
                              }}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "name",
                                  sortDescending: false
                              }}
                              filterOptions={{ externalFilter: false }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}

