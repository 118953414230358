import React from 'react';
import Entity from "../../_model/Entity";
import {AdminObjectType, ListStyle, Status} from "../../Framework/Enums";
import List, {ColumnDefinition, ListItem} from "../../Components/List/List";
import ListRibbon from "../../Components/List/ListRibbon";
import {genericSearch} from "../../Framework/Search";
import {ListEntry} from "./ListEntry";
import {MainContext} from "../../_base/MainContext";
import {CardAction, CardDefinition} from "./Card";
import CardList from "./CardList";
import {Util} from "../../Framework/Util";
import {DropDown, DropDownItem, DropDownType} from "../Controls/DropDown";
import User from "../../_model/User";
import {ListFilter, ListFilterDefinition, ListFilterValue} from "./ListFilter";
import {RibbonButtonData} from "../Ribbon/RibbonButtonData";
import {SearchFields} from "../Controls/SearchInput";
import {RouteComponentProps} from "react-router-dom";
import {ListSearchOptions} from "./ListSearchOptions";
import ListPaginationOptions from "./ListPaginationOptions";
import {ListSortOptions} from "./ListSortOptions";
import ListFilterOptions from "./ListFilterOptions";

interface IProps extends RouteComponentProps {
    userList: boolean
    items?: Entity[]
    definitions: ColumnDefinition[]
    cardDefinition?: CardDefinition
    cardActions: CardAction[]
    filter?: ListFilterDefinition[]

    redirectUrl?: string
    redirectTarget?: string
    loading?: boolean

    toggleSelection?: boolean
    selectedEntityTextSingle?: string
    selectedEntityTextMultiple?: string

    buttonNew?: RibbonButtonData
    buttonCopy?: RibbonButtonData
    buttonActivate?: RibbonButtonData
    buttonDeactivate?: RibbonButtonData
    buttonApproval?: RibbonButtonData

    buttonShowAsList?: RibbonButtonData
    buttonShowAsCard?: RibbonButtonData

    searchOptions: ListSearchOptions
    sortOptions: ListSortOptions
    paginationOptions: ListPaginationOptions
    filterOptions: ListFilterOptions

    showRowSelector: boolean
    allowStatusChangeToUserItself: boolean
    allowMultiSelect: boolean

    onCopyItem?: (items: number[]) => void
    onSetState?: (items: number[], status: any, user?: User, title?: string, comment?: string) => void
    customRibbonButtons?: RibbonButtonData[]

    objectType: AdminObjectType
    initialListStyle?: ListStyle
    renderHeader?: () => void

    onLoadThumbnail?: (id: number) => void
    onSelectionChange?: (items: number[]) => void
    onShowFilter?: (show: boolean) => void
    onPageChange?: (page: number) => void
}
interface IState {
    listItems?: ListItem[]

    selectedItems: number[]
    showFilter: boolean
}

export default class DataList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            selectedItems: [],
            showFilter: false
        }
    }

    componentDidMount() {
        this.generateListItems()
        document.addEventListener("click", this.onClick)
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.onClick)
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.filter && prevProps.filter && this.props.filter.length !== prevProps.filter.length) {
            let filter: ListFilter = this.getCurrentFilter()

            this.props.filter.forEach(i => {
                let filterValue: ListFilterValue | undefined = filter.filterValues.find(f => f.filter.name === i.name)
                if (filterValue === undefined) {
                    filter.filterValues.push(new ListFilterValue(i))
                }
            })

            this.context.setListFilter(filter)
        }

        if (this.isItemListDifferent(prevProps.items, this.props.items)) {
            this.generateListItems()
        }

        if (this.props.filterOptions.toggleFilter !== prevProps.filterOptions.toggleFilter) {
            this.onToggleFilter()
        }
        if (this.props.toggleSelection !== prevProps.toggleSelection) {
            this.onSelectionChange([])
        }
    }
    isItemListDifferent = (itemListOld?: Entity[], itemListNew?: Entity[]) : boolean => {
        if(itemListOld === undefined && itemListNew !== undefined) {
            return true
        }
        if(itemListOld !== undefined && itemListNew === undefined) {
            return true
        }
        if((itemListOld === undefined && itemListNew === undefined)
            || itemListOld === null || itemListNew === null) {
            return false
        }

        if(itemListOld!.length !== itemListNew!.length) {
            return true
        }

        let i = 0
        if(i < itemListOld!.length) {
            if(itemListOld![i] !== itemListNew![i]) {
                return true
            }
            i++
        }

        return false
    }

    getSelectedItemCount = () : number => {
        return this.state.selectedItems.length
    }
    getSelectedItems = () : number[] => {
        return this.state.selectedItems
    }

    getCurrentFilter = () => {
        let filter: ListFilter | undefined = this.context.getListFilter(this.props.objectType)
        if (filter === undefined) {
            filter = new ListFilter(
                this.props.objectType,
                this.props.initialListStyle || ListStyle.list,
                "",
                SearchFields.SearchField,
                this.props.filter?.map(i => new ListFilterValue(i)) || []
            )
        }

        return filter
    }
    hasDefaultFilter = () => {
        let listFilter = this.getCurrentFilter()
        return listFilter.filterValues.find(i => i.filter.defaultValue !== i.value) === undefined
    }

    generateListItems = () => {
        if(this.props.items === undefined) {
            this.setState({listItems: undefined})
            return
        }

        let searchableColumns: (keyof Entity)[] = []

        let listFilter = this.getCurrentFilter()

        this.props.definitions
            .filter(columnDefinition => listFilter.searchOption === SearchFields.SearchField ? columnDefinition.searchable : columnDefinition.logicalName === "name")
            .forEach(columnDefinition => searchableColumns.push(columnDefinition.logicalName as keyof Entity))

        if (this.props.items) {
            let listItems: ListItem[]
            let items: Entity[] = this.props.items

            // Filter: Iterate through filter values and filter the column for the selected value
            listFilter.filterValues
                .filter(f => f.value !== undefined)
                .forEach(f => {
                    items = f.filter.onFilter(items, f.value)
                })

            // Search: Check searchable columns for search value
            listItems = items.map(item => new ListItem(item, React.createRef<ListEntry>()))

            // If search is handled within list filter searchable columns
            if (listFilter.searchString !== "" && this.props.searchOptions.onSearch === undefined) {
                listItems = listItems.filter((item: ListItem) => {
                    return genericSearch<Entity>(item.item, searchableColumns, listFilter?.searchString)
                })
            }

            this.setState({listItems: listItems, selectedItems: []})
        }
    }

    copySelectedElements = () => {
        if (this.props.onCopyItem && this.state.selectedItems.length > 0) {
            this.props.onCopyItem(this.state.selectedItems)
            this.onSelectionChange([])
        }
    }

    onClick = (e: MouseEvent) => {
        let target = e.target as HTMLElement

        if (document.body.contains(target) &&
            !Util.isChildOfClass(target, "card") &&
            !Util.isChildOfClass(target, "list-table") &&
            !Util.isChildOfClass(target, "ribbon") &&
            !Util.isChildOfClass(target, "marketplace-details") &&
            !Util.isChildOfClass(target, "modal")) {

            e.stopPropagation()
            this.onSelectionChange([])
        }
    }
    onSelectionChange = (items: number[]) => {
        this.props.onSelectionChange?.(items)
        this.setState({selectedItems: items})
    }
    onActivate = (title?: string, comment?: string) => {
        this.props.onSetState?.(this.state.selectedItems, Status.draft, undefined, title, comment)
        this.onSelectionChange([])
    }
    onDeactivate = (title?: string, comment?: string) => {
        this.props.onSetState?.(this.state.selectedItems, Status.deactivated, undefined, title, comment)
        this.onSelectionChange([])
    }
    onSubmitForApproval = (user: User, title: string, comment?: string) => {
        this.props.onSetState?.(this.state.selectedItems, Status.approval, user, title, comment)
        this.onSelectionChange([])
    }

    onChangeFilter = (name: string, value: string) => {
        let filter: ListFilter = this.getCurrentFilter()

        let filterValue = filter.filterValues.find(i => i.filter.name === name)
        if (filterValue) {
            filterValue.value = +value
            this.context.setListFilter(filter)
        }

        this.setState({selectedItems: []}, () => {
            if (this.props.filterOptions.onFilterChange) {
                this.props.filterOptions.onFilterChange()
            }
            else {
                this.generateListItems()
            }
        })
    }
    onResetFilter = () => {
        let filter: ListFilter = this.getCurrentFilter()

        filter.filterValues.forEach(i => { i.value = i.filter.defaultValue })
        this.context.setListFilter(filter)

        if (this.props.filterOptions.onFilterChange) {
            this.props.filterOptions.onFilterChange()
        }
        else {
            this.generateListItems()
        }
    }
    onToggleFilter = () => {
        const isFilterActive = this.isFilterActive()

        if (this.props.onShowFilter) {
            this.props.onShowFilter(!isFilterActive)
            this.props.onSelectionChange?.([])

            this.setState({showFilter: !isFilterActive, selectedItems: []})
        }
        else {
            if (isFilterActive) {
                this.onResetFilter()
            }
            this.setState({showFilter: !isFilterActive})
        }
    }

    onSearch = (searchValue: string, options?: SearchFields) => {
        // Update list filter stored in context
        let filter: ListFilter = this.getCurrentFilter()
        filter.searchString = searchValue
        if (options) {
            filter.searchOption = options
        }
        this.context.setListFilter(filter)

        if (this.props.searchOptions.onSearch === undefined) {
            this.generateListItems()
        }
        else {
            this.props.searchOptions.onSearch(searchValue)
        }
    }

    isFilterActive = () => {
        return this.state.showFilter || !this.hasDefaultFilter()
    }

    showAsList = () => {
        let filter: ListFilter = this.getCurrentFilter()
        filter.listStyle = ListStyle.list
        this.context.setListFilter(filter)
    }
    showAsCard = () => {
        let filter: ListFilter = this.getCurrentFilter()
        filter.listStyle = ListStyle.card
        this.context.setListFilter(filter)
    }

    getButtonCopy = () => {
        if (this.props.onCopyItem && this.props.buttonCopy && this.state.selectedItems.length > 0) {
            let buttonCopy = this.props.buttonCopy
            buttonCopy.onClick = this.copySelectedElements
            return buttonCopy
        }
    }
    getButtonActivate = () => {
        if (this.props.buttonActivate && this.state.selectedItems.length > 0) {
            return this.props.buttonActivate
        }
    }
    getButtonDeactivate = () => {
        if (this.props.buttonDeactivate && this.state.selectedItems.length > 0) {
            return this.props.buttonDeactivate
        }
    }
    getButtonApproval = () => {
        if (this.props.buttonApproval && this.state.selectedItems.length > 0) {
            return this.props.buttonApproval
        }
    }
    getButtonShowAsList = () => {
        if (this.props.buttonShowAsList && this.props.onLoadThumbnail) {
            let buttonShowAsList = this.props.buttonShowAsList
            buttonShowAsList.onClick = this.showAsList
            return buttonShowAsList
        }
    }
    getButtonShowAsCard = () => {
        if (this.props.buttonShowAsCard && this.props.onLoadThumbnail) {
            let buttonShowAsCard = this.props.buttonShowAsCard
            buttonShowAsCard.onClick = this.showAsCard
            return buttonShowAsCard
        }
    }

    render() {
        let filter = this.getCurrentFilter()
        let showFilter = this.state.showFilter || !this.hasDefaultFilter()

        return <>
            <ListRibbon
                userRibbon={this.props.userList}
                allowStatusChangeToUserItself={this.props.allowStatusChangeToUserItself}
                buttonNew={this.props.buttonNew}
                buttonCopy={this.getButtonCopy()}
                buttonActivate={this.getButtonActivate()}
                buttonDeactivate={this.getButtonDeactivate()}
                buttonApproval={this.getButtonApproval()}
                buttonShowAsList={this.getButtonShowAsList()}
                buttonShowAsCard={this.getButtonShowAsCard()}
                onActivate={this.onActivate}
                onDeactivate={this.onDeactivate}
                onSubmitForApproval={this.onSubmitForApproval}
                listStyle={filter.listStyle}
                onSearch={this.props.searchOptions.showSearch ? this.onSearch : undefined}
                searchValue={filter.searchString}
                searchFieldOption={filter.searchOption}
                showSearchOptions={this.props.searchOptions.showSearchOptions}
                typeAheadSearch={this.props.searchOptions.typeAheadSearch}
                showFilter={showFilter}
                onToggleFilter={this.onToggleFilter}
                customRibbonButtons={this.props.customRibbonButtons}
            />

            {showFilter && filter.filterValues.length > 0 &&
            <div className={"ribbon ribbon-user ribbon-filter-bar"}>
                {filter.filterValues.map(i => {
                    let filterData = i.filter.data.map(i => new DropDownItem(i.id!.toString(), i.name))

                    let value = i.filter.defaultValue
                    if (i.value !== undefined) {
                        value = i.value
                    }

                    return <DropDown id={"filter-" + i.filter.name.toLowerCase()}
                                     key={"filter-" + i.filter.name.toLowerCase()}
                                     type={DropDownType.TEXT}
                                     value={value.toString()}
                                     readonly={false}
                                     autocomplete={false}
                                     required={false}
                                     width={200}
                                     style={{marginLeft: "10px"}}
                                     items={filterData}
                                     onChangeValue={(value: string) => this.onChangeFilter(i.filter.name, value)}
                    />
                    })
                }
            </div>
            }

            {this.props.renderHeader?.()}

            {filter.listStyle === ListStyle.list &&
            <List items={this.state.listItems}
                  loading={(this.props.loading !== undefined ? this.props.loading : this.state.listItems === undefined)}
                  definitions={this.props.definitions}
                  objectType={this.props.objectType}
                  newItemUrl={this.props.buttonNew?.navigateTo}
                  newItemTooltip={this.props.buttonNew?.tooltip}
                  redirectUrl={this.props.redirectUrl}
                  searchTerms={filter.searchString}
                  sortOptions={this.props.sortOptions}
                  allowMultiSelect={this.props.allowMultiSelect}
                  selectedItems={this.state.selectedItems}
                  selectedEntityTextSingle={this.props.selectedEntityTextSingle}
                  selectedEntityTextMultiple={this.props.selectedEntityTextMultiple}
                  onSelectionChange={this.props.showRowSelector ? this.onSelectionChange : undefined}
                  paginationOptions={this.props.paginationOptions}
                  history={this.props.history}
                  location={this.props.location}
                  match={this.props.match}
            />}

            {filter.listStyle === ListStyle.card && this.props.cardDefinition && this.props.onLoadThumbnail &&
            <CardList
                items={this.state.listItems}
                loading={(this.props.loading !== undefined ? this.props.loading : this.state.listItems === undefined)}
                objectType={this.props.objectType}
                definitions={this.props.cardDefinition}
                newItemUrl={this.props.buttonNew?.navigateTo}
                newItemTooltip={this.props.buttonNew?.tooltip}
                redirectUrl={this.props.redirectUrl}
                redirectTarget={this.props.redirectTarget}
                selectedEntityTextSingle={this.props.selectedEntityTextSingle}
                selectedEntityTextMultiple={this.props.selectedEntityTextMultiple}
                selectedItems={this.state.selectedItems}
                onSelectionChange={this.props.showRowSelector ? this.onSelectionChange : undefined}
                cardActions={this.props.cardActions}
                sortOptions={this.props.sortOptions}
                allowMultiSelect={this.props.allowMultiSelect}
                paginationOptions={this.props.paginationOptions}
                onLoadThumbnail={this.props.onLoadThumbnail}
                history={this.props.history}
                location={this.props.location}
                match={this.props.match}
            />
            }
        </>
    }
}

