import React from "react";
import {
    WDToolbarElement,
    WDToolbarElementProps,
    WDToolbarElementState
} from "../../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbarButtonSeparator} from "../../../Toolbar/Button/General/WDToolbarButtonSeparator";
import {WDToolbarButtonSolutionShow} from "../../../Toolbar/Button/Math/WDToolbarButtonSolutionShow";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../../Framework/CategoryImage";
import {WDToolbarButtonGraphics} from "../../../Toolbar/Button/General/WDToolbarButtonGraphics";
import {
    CalculationType,
    WDToolbarButtonCalculationType
} from "../../../Toolbar/Button/Math/WDToolbarButtonCalculationType";
import {WDToolbarButtonTaskGenerator} from "../../../Toolbar/Button/Math/WDToolbarButtonTaskGenerator";
import {ValueRangeMode, WDToolbarButtonValueRange} from "../../../Toolbar/Button/Math/WDToolbarButtonValueRange";
import {WDToolbarButtonRegenerate} from "../../../Toolbar/Button/Math/WDToolbarButtonRegenerate";
import {WDToolbar, WDToolbarOrientation} from "../../../Toolbar/Toolbar/WDToolbar";
import translations from "../../../../Framework/translations.json";
import {WDToolbarSectionTransformation} from "../../../Toolbar/Section/WDToolbarSectionTransformation";
import Converter from "../../../../Framework/Converter";
import {MainContext} from "../../../../_base/MainContext";
import {ElementLayout} from "../../WDElementContainer";
import {WDToolbarSectionObjectGeneral} from "../../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarButtonLineColor} from "../../../Toolbar/Button/General/WDToolbarButtonLineColor";
import {
    NumberPlacement,
    WDToolbarButtonNumberPlacementDifficulty
} from "../../../Toolbar/Button/Math/WDToolbarButtonNumberPlacementDifficulty";
import {WDToolbarButtonSolutionBar} from "../../../Toolbar/Button/Math/WDToolbarButtonSolutionBar";
import {TooltipText} from "../../../../Components/Tooltips";
import {DropDownItem} from "../../../../Components/Controls/DropDown";
import WDCalculationTriangleDropDownValues from "./WDCalculationTriangleDropDownValues";
import {WDToolbarSectionText, WDToolbarSectionTextSettings} from "../../../Toolbar/Section/WDToolbarSectionText";
import {Difficulty, ProductLicense} from "../../../../Framework/Enums";
import {WorksheetItemType, WorksheetItemTypeEnum} from "../../../../_model/WorksheetItemType";
import _ from "lodash";

export interface WDToolbarTriangleProps extends WDToolbarElementProps {
}

export interface WDToolbarTriangleState extends WDToolbarElementState {
}

export class WDCalculationTriangleToolbar extends WDToolbarElement<WDToolbarTriangleProps, WDToolbarTriangleState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    calculationDropDownValues: DropDownItem[]
    elementLicenses: ProductLicense[] = [ProductLicense.employee]

    constructor(props: WDToolbarTriangleProps, state: WDToolbarTriangleState) {
        super(props, state);

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef()
        }

        this.calculationDropDownValues = WDCalculationTriangleDropDownValues.getTriangleCalculationType().map(type => {
            return new DropDownItem(type.key.toString(), this.context.translate(type.value))
        })
    }
    componentDidMount() {
        this.hasValidLicenseForElement(this.elementLicenses)
        this.setState({activeId: -1})
    }
    shouldComponentUpdate(nextProps: Readonly<WDToolbarTriangleProps>, nextState: Readonly<WDToolbarTriangleState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    onChangeToolbarTab = (type: WDToolbarTypeEnum) => {
        // editmode always false - editmode should not be triggered by changing toolbar tabs as all text actions are always changing every text
        if(this.state.activeToolbarType !== type) {
            this.props.changeEditModeWithClickTab(this.props.worksheetItemKeys[0], false)
            this.setState({activeToolbarType: type})
        }
    }

    getElementGraphic = (obj: any) => {
        // #4266 Graphic of changeGraphic button should change matching to the element
        let iconString = ImagePath.getTriangleUrl() + "RD_A_01.svg"
        if(obj) {
            iconString = ImagePath.getTriangleUrl() + CategoryImageValue.getImageByKey([ImageCategory.CALCULATION_TRIANGLE], obj.newValue).toString()
        }

        return iconString
    }

    render () {
        // Get values to display data - important if more than one element is selected
        let showSolutionValueObj = this.multiselectSearchForValue("solutionShow")
        let showSolutionBarValueObj = this.multiselectSearchForValue("showSolutionBar")
        let solutionActionsPossibleValueObj = this.multiselectSearchForValue("solutionActionsPossible")
        let digitExceededObj = this.multiselectSearchForValue("digitExceeded")
        let zeroCalculationObj = this.multiselectSearchForValue("zeroCalculation")
        let taskGeneratorValueObj = this.multiselectSearchForValue("taskGenerator")
        let calculationTypeValueObj = this.multiselectSearchForValue("calculationType")
        let difficultyLevelValueObj = this.multiselectSearchForValue("difficultyLevel")
        let numberPlacementValueObj = this.multiselectSearchForValue("numberPlacement")
        let zrValueObj = this.multiselectSearchForValue("zr")
        let maxValueObj = this.multiselectSearchForValue("maxValue")
        let minValueObj = this.multiselectSearchForValue("minValue")
        let graphics = this.multiselectSearchForValue("imageKey")
        let lineColors = this.multiselectSearchForValue("lineColor")
        let textValue = this.multiselectSearchForValue("textValue")

        const solutionShow = (showSolutionValueObj?.valueEqual && showSolutionValueObj.newValue !== undefined) ? showSolutionValueObj.newValue : false
        const solutionBarShow = (showSolutionBarValueObj?.valueEqual && showSolutionBarValueObj.newValue !== undefined) ? showSolutionBarValueObj.newValue : false
        const solutionActionsPossible = (solutionActionsPossibleValueObj?.valueEqual && solutionActionsPossibleValueObj.newValue !== undefined) ? solutionActionsPossibleValueObj.newValue : false
        const digitExceeded = (digitExceededObj?.valueEqual && digitExceededObj.newValue !== undefined) ? digitExceededObj.newValue : true
        const zeroCalculation = (zeroCalculationObj?.valueEqual && zeroCalculationObj.newValue !== undefined) ? zeroCalculationObj.newValue : true
        const taskGenerator = (taskGeneratorValueObj?.valueEqual && taskGeneratorValueObj.newValue !== undefined) ? taskGeneratorValueObj.newValue : false
        const calculationType = (calculationTypeValueObj?.valueEqual && calculationTypeValueObj.newValue !== undefined) ? calculationTypeValueObj.newValue : CalculationType.ADD
        const difficultyLevel = (difficultyLevelValueObj?.valueEqual && difficultyLevelValueObj.newValue !== undefined) ? difficultyLevelValueObj.newValue : Difficulty.none
        const numberPlacement = (numberPlacementValueObj?.valueEqual && numberPlacementValueObj.newValue !== undefined) ? numberPlacementValueObj.newValue : NumberPlacement.none
        const zr = (zrValueObj?.valueEqual && zrValueObj.newValue !== undefined) ? zrValueObj.newValue : -1
        const maxValue = (maxValueObj?.valueEqual && maxValueObj.newValue !== undefined) ? maxValueObj.newValue : ""
        const minValue = (minValueObj?.valueEqual && minValueObj.newValue !== undefined) ? minValueObj.newValue : ""
        const lineColor = (lineColors?.valueEqual && lineColors.newValue !== undefined) ? lineColors.newValue : undefined

        const bold = (textValue?.valueEqual && textValue.newValue !== undefined) ? textValue.newValue.bold : false
        const italic = (textValue?.valueEqual && textValue.newValue !== undefined) ? textValue.newValue.italic : false
        const strikeThrough = (textValue?.valueEqual && textValue.newValue !== undefined) ? textValue.newValue.strikeThrough : false

        const standardGraphic = (graphics?.valueEqual
            && graphics.newValue !== undefined
            && (graphics.newValue.toString().toLowerCase().includes("standard") || graphics.newValue.toLowerCase().includes("handline")))

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, true, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&

            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={false}
                    isEdited={this.props.editMode}
                    initialLinkState={true}
                    showColorOptions={false}
                    canChangeLinkState={false}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.TEXT &&
                <ul className={"ws-designer-toolbar-buttons"}>
                    <WDToolbarSectionText
                        onToolbarAction={this.props.onToolbarAction}
                        toolbarSettings={new WDToolbarSectionTextSettings(
                            false,
                            false,
                            false,
                            false,
                            true,
                            true,
                            false,
                            true,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,

                            undefined,
                            undefined,
                            bold,
                            italic,
                            undefined,
                            strikeThrough
                        )}
                    />
                </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>
                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTaskGenerator
                            id={6}
                            pressed={taskGenerator!}
                            enabled={this.state.isLicenseValidForElement}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.task_generator_disabled),
                                this.context.translate(translations.tooltip.no_license))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonValueRange
                            id={5}
                            dropDownValues={WDCalculationTriangleDropDownValues.getTriangleNumberRange().map(zr => {
                                return new DropDownItem(zr.key.toString(), zr.value)
                            })}
                            mode={ValueRangeMode.advanced}
                            zr={zr}
                            maxValue={maxValue}
                            maximalMaxValue={10000}
                            minValue={minValue}
                            minimalMinValue={0}
                            digitExceeded={digitExceeded}
                            zeroCalculation={zeroCalculation}
                            pressed={this.state.activeId === 5}
                            clickable={this.state.isLicenseValidForElement && taskGenerator!}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                    this.context.translate(translations.toolbar.change_value_range_disabled),
                                    this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                    this.context.translate(translations.toolbar.change_value_range_disabled),
                                    this.context.translate(translations.tooltip.need_task_generator_disabled))
                            }
                            minValueDisabled={!digitExceeded || calculationType !== CalculationType.ADD}
                            maxValueDisabled={calculationType !== CalculationType.ADD}
                            zrDisabled={calculationType !== CalculationType.ADD}
                            digitExceededDisabled={calculationType !== CalculationType.ADD}
                            zeroCalcDisabled={false}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonNumberPlacementDifficulty
                            id={12}
                            pressed={this.state.activeId === 12}
                            clickable={this.state.isLicenseValidForElement && taskGenerator!}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                    this.context.translate(translations.toolbar.difficulty_level_disabled),
                                    this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                    this.context.translate(translations.toolbar.difficulty_level_disabled),
                                    this.context.translate(translations.tooltip.need_task_generator_disabled))
                            }
                            difficultyValue={difficultyLevel}
                            numberPlacementValue={numberPlacement}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />
                    </div>

                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonRegenerate
                            id={1}
                            pressed={false}
                            clickable={this.state.isLicenseValidForElement && taskGenerator!}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                    this.context.translate(translations.toolbar.regenerate_disabled),
                                    this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                    this.context.translate(translations.toolbar.regenerate_disabled),
                                    this.context.translate(translations.tooltip.need_task_generator_disabled))
                            }
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />

                        <WDToolbarButtonCalculationType
                            id={7}
                            type={calculationType.toString()}
                            pressed={this.state.activeId === 7}
                            dropDownValues={this.calculationDropDownValues}
                            clickable={this.state.isLicenseValidForElement && taskGenerator!}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                    this.context.translate(translations.toolbar.calculation_type_disabled),
                                    this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                    this.context.translate(translations.toolbar.calculation_type_disabled),
                                    this.context.translate(translations.tooltip.need_task_generator_disabled))
                            }
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />

                        <WDToolbarButtonLineColor
                            id={8}
                            clickable={standardGraphic}
                            pressed={lineColor !== undefined}
                            open={this.state.activeId === 8}
                            lineColor={lineColor}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />
                    </div>
                </div>

                <WDToolbarButtonSeparator/>

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarButtonGraphics
                        id={2}
                        icon={this.getElementGraphic(graphics)}
                        pressed={this.state.activeId === 2}
                        clickable={this.state.isLicenseValidForElement}
                        imageCategory={[ImageCategory.CALCULATION_TRIANGLE]}
                        imagePath={ImagePath.getTriangleUrl()}
                        onToggleButton={this.onToggleButton}
                        onActionButton={this.onToolbarAction}
                        imageWidth={100}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic),
                            this.context.translate(translations.tooltip.change_graphic)
                        )}
                        tooltipDisabled={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic_disabled),
                            this.context.translate(translations.tooltip.no_license))
                        }
                        text={this.context.translate(translations.toolbar.graphic)}
                        iconImageWidth={80}
                        iconImageHeight={80}
                        iconButtonWidth={100}
                        iconButtonHeight={69}
                    />
                </div>

                <WDToolbarButtonSeparator/>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonSolutionShow
                            id={401}
                            pressed={solutionShow! && taskGenerator! && solutionActionsPossible}
                            clickable={this.state.isLicenseValidForElement && taskGenerator! && solutionActionsPossible}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                    this.context.translate(translations.toolbar.solution_show_hide_disabled),
                                    this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                    this.context.translate(translations.toolbar.solution_show_hide_disabled),
                                    this.context.translate(translations.tooltip.need_task_generator_solutions_disabled))
                            }
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />
                    </div>

                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonSolutionBar
                            id={402}
                            pressed={solutionBarShow! && solutionActionsPossible}
                            clickable={this.state.isLicenseValidForElement && solutionActionsPossible}
                            tooltipDisabled={!this.state.isLicenseValidForElement ?
                                new TooltipText(
                                    this.context.translate(translations.toolbar.solution_bar_disabled),
                                    this.context.translate(translations.tooltip.no_license))
                                :
                                new TooltipText(
                                    this.context.translate(translations.toolbar.solution_bar_disabled),
                                    this.context.translate(translations.tooltip.need_solutions_disabled))
                            }
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={true}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    worksheetItemType={new WorksheetItemType("", WorksheetItemTypeEnum.CALCULATION_TRIANGLE)}
                    elementProps={this.props.elementProps}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onChangeBorder={this.props.onChangeBorder}
                    onElementDeleted={this.props.onElementDeleted}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onAddRule={this.props.onAddRule}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                />
            </ul>
            }
        </WDToolbar>
    }
}
