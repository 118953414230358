import Entity from "../../_model/Entity";

export default class MPWorksheetListItem extends Entity {
    status: string
    marketplaceStatus: string
    lessonSubject: string
    schoolLevel: string
    submittedBy: string
    modifiedOn: Date

    constructor(id: number, name: string, status: string, marketplaceStatus: string, lessonSubject: string, schoolLevel: string,
                submittedBy: string, modifiedOn: Date) {

        super(name, id);

        this.lessonSubject = lessonSubject
        this.schoolLevel = schoolLevel
        this.marketplaceStatus = marketplaceStatus

        this.submittedBy = submittedBy
        this.modifiedOn = modifiedOn
        this.status = status
    }
}
