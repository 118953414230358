import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {ImagePath} from "../../../Framework/CategoryImage";
import translations from "../../../Framework/translations.json";
import {WDToolbarButtonColor} from "../Button/General/WDToolbarButtonColor";
import {WDToolbarAction} from "../WDToolbarAction";
import {WDToolbarState} from "../Toolbar/WDToolbar";
import {WDToolbarButtonTextDecoration} from "../Button/Text/WDToolbarButtonTextDecoration";
import {WDToolbarButtonHighlight} from "../Button/Text/WDToolbarButtonHighlight";
import {WDToolbarFontType} from "../Button/Text/WDToolbarFontType";
import {WDToolbarFontSize} from "../Button/Text/WDToolbarFontSize";
import {WDToolbarTypeEnum} from "../Toolbar/WDToolbarTabs";
import {TooltipPosition, TooltipText} from "../../../Components/Tooltips";
import {WDToolbarButtonInsertGlyph} from "../Button/Text/WDToolbarButtonInsertGlyph";
import {WDToolbarLineHeight} from "../Button/Text/WDToolbarLineHeight";

export class WDToolbarSectionTextSettings {
    fontTypeAvailable: boolean
    fontSizeAvailable: boolean
    copyFormatAvailable: boolean
    deleteFormatAvailable: boolean
    boldAvailable: boolean
    italicAvailable: boolean
    underlineAvailable: boolean
    strikethroughAvailable: boolean
    subscriptAvailable: boolean
    superScriptAvailable: boolean
    foreColorAvailable: boolean
    backColorAvailable: boolean
    glyphAvailable: boolean
    lineHeightAvailable: boolean

    fontType?: string
    fontSize?: string
    boldPressed?: boolean
    italicPressed?: boolean
    underlinePressed?: boolean
    strikethroughPressed?: boolean
    subscriptPressed?: boolean
    superScriptPressed?: boolean

    maxValueFontSize?: number
    filterConditionFontSize?: {key: string, value: string}

    constructor(fontTypeAvailable: boolean, fontSizeAvailable: boolean, copyFormatAvailable: boolean, deleteFormatAvailable: boolean,
                boldAvailable: boolean, italicAvailable: boolean, underlineAvailable: boolean, strikethroughAvailable: boolean,
                subscriptAvailable: boolean, superScriptAvailable: boolean, foreColorAvailable: boolean, backColorAvailable: boolean,
                glyphAvailable: boolean, lineHeightAvailable: boolean,

                fontType?: string, fontSize?: string, boldPressed?: boolean, italicPressed?: boolean, underlinePressed?: boolean, strikethroughPressed?: boolean,
                subscriptPressed?: boolean, superScriptPressed?: boolean, maxValueFontSize?: number, filterConditionFontSize?: {key: string, value: string}) {

        this.fontTypeAvailable = fontTypeAvailable
        this.fontSizeAvailable = fontSizeAvailable
        this.copyFormatAvailable = copyFormatAvailable
        this.deleteFormatAvailable = deleteFormatAvailable
        this.boldAvailable = boldAvailable
        this.italicAvailable = italicAvailable
        this.underlineAvailable = underlineAvailable
        this.strikethroughAvailable = strikethroughAvailable
        this.subscriptAvailable = subscriptAvailable
        this.superScriptAvailable = superScriptAvailable
        this.foreColorAvailable = foreColorAvailable
        this.backColorAvailable = backColorAvailable
        this.glyphAvailable = glyphAvailable
        this.lineHeightAvailable = lineHeightAvailable

        this.fontSize = fontSize
        this.boldPressed = boldPressed
        this.italicPressed = italicPressed
        this.underlinePressed = underlinePressed
        this.strikethroughPressed = strikethroughPressed
        this.subscriptPressed = subscriptPressed
        this.superScriptPressed = superScriptPressed

        this.maxValueFontSize = maxValueFontSize
        this.filterConditionFontSize = filterConditionFontSize
    }
}

export interface IProps {
    onToolbarAction: (action: WDToolbarAction, data?: Text) => void
    toolbarSettings?: WDToolbarSectionTextSettings
}

export interface IState extends WDToolbarState {
}

export class WDToolbarSectionText extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.TEXT
        }
    }

    onToggleButton = (id: number) => {
        this.setState({activeId: id})
    }
    onToolbarAction = (action: WDToolbarAction, data?: Text) => {
        this.setState({activeId: -1}, () => {
            this.props.onToolbarAction(action, data)
        })
    }

    render () {
        return <div className={"ws-designer-toolbar-section"}>

            <div className={"ws-designer-toolbar-section"}>
                <div className={"ws-designer-toolbar-row"}>
                    <WDToolbarFontType
                        id={201}
                        width={164}
                        action={WDToolbarAction.FONT_TYPE}
                        initialValue={this.props.toolbarSettings?.fontType}
                        readonly={this.props.toolbarSettings ? !this.props.toolbarSettings.fontTypeAvailable : false}
                        filterCondition={this.props.toolbarSettings?.filterConditionFontSize}
                        onActionButton={this.onToolbarAction}/>

                    <WDToolbarFontSize
                        id={202}
                        action={WDToolbarAction.FONT_SIZE}
                        readonly={this.props.toolbarSettings ? !this.props.toolbarSettings.fontSizeAvailable : false}
                        initialValue={this.props.toolbarSettings?.fontSize}
                        maxValue={this.props.toolbarSettings?.maxValueFontSize}
                        onActionButton={this.onToolbarAction}/>

                    {/*<WDToolbarButtonCopyFormat*/}
                    {/*    id={203}*/}
                    {/*    pressed={false}*/}
                    {/*    clickable={this.props.toolbarSettings ? this.props.toolbarSettings.copyFormatAvailable : true}*/}
                    {/*    onActionButton={this.onToolbarAction}/>*/}

                    {/*<WDToolbarButtonDeleteFormat*/}
                    {/*    id={204}*/}
                    {/*    pressed={false}*/}
                    {/*    clickable={this.props.toolbarSettings ? this.props.toolbarSettings.deleteFormatAvailable : true}*/}
                    {/*    onActionButton={this.onToolbarAction}/>*/}

                    <WDToolbarLineHeight id={203}
                                         readonly={this.props.toolbarSettings ? !this.props.toolbarSettings.lineHeightAvailable : false}
                                         action={WDToolbarAction.LINE_HEIGHT}
                                         onActionButton={this.onToolbarAction}
                    />

                    <WDToolbarButtonInsertGlyph
                        id={205}
                        open={this.state.activeId === 205}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.glyphAvailable : true}
                        onToggleButton={this.onToggleButton}
                        onActionButton={this.onToolbarAction}
                    />
                </div>

                <div className={"ws-designer-toolbar-row"}>
                    <WDToolbarButtonTextDecoration
                        id={301}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.boldAvailable : true}
                        pressed={this.props.toolbarSettings?.boldPressed}
                        colorPicker={false}
                        command={"bold"}
                        action={WDToolbarAction.BOLD}
                        icon={ImagePath.getButtonUrl() + "bold.svg"}
                        buttonWidth={26}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.bold),
                            this.context.translate(translations.tooltip.bold))}
                        onActionButton={this.onToolbarAction}/>

                    <WDToolbarButtonTextDecoration
                        id={302}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.italicAvailable : true}
                        pressed={this.props.toolbarSettings?.italicPressed}
                        colorPicker={false}
                        command={"italic"}
                        action={WDToolbarAction.ITALIC}
                        icon={ImagePath.getButtonUrl() + "italic.svg"}
                        buttonWidth={26}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.italic),
                            this.context.translate(translations.tooltip.italic))}
                        onActionButton={this.onToolbarAction}/>

                    <WDToolbarButtonTextDecoration
                        id={303}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.underlineAvailable : true}
                        pressed={this.props.toolbarSettings?.underlinePressed}
                        command={"underline"}
                        colorPicker={true}
                        colorPickerOptions={{
                            action: WDToolbarAction.UNDERLINE_STYLE,
                            open: this.state.activeId === 303,
                            width: 70,
                            height: 150
                        }}
                        action={WDToolbarAction.UNDERLINE}
                        icon={ImagePath.getButtonUrl() + "underline.svg"}
                        buttonWidth={26}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.underline),
                            this.context.translate(translations.tooltip.underline))}
                        tooltipDisabled={new TooltipText(
                            this.context.translate(translations.toolbar.underline_disabled),
                            this.context.translate(translations.tooltip.disabled)
                        )}
                        onActionButton={this.onToolbarAction}
                        onToggleButton={this.onToggleButton}
                    />

                    <div style={{marginLeft: 10}}></div>

                    <WDToolbarButtonTextDecoration
                        id={304}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.strikethroughAvailable : true}
                        pressed={this.props.toolbarSettings?.strikethroughPressed}
                        colorPicker={false}
                        command={"strikeThrough"}
                        action={WDToolbarAction.STRIKETHROUGH}
                        icon={ImagePath.getButtonUrl() + "strikethrough.svg"}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.strikethrough),
                            this.context.translate(translations.tooltip.strikethrough))}
                        onActionButton={this.onToolbarAction}/>

                    <WDToolbarButtonTextDecoration
                        id={305}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.subscriptAvailable : true}
                        pressed={this.props.toolbarSettings?.subscriptPressed}
                        colorPicker={false}
                        command={"subscript"}
                        action={WDToolbarAction.SUBSCRIPT}
                        icon={ImagePath.getButtonUrl() + "subscript.svg"}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.subscript),
                            this.context.translate(translations.tooltip.subscript))}
                        tooltipDisabled={new TooltipText(
                            this.context.translate(translations.toolbar.subscript_disabled),
                            this.context.translate(translations.tooltip.disabled))}
                        onActionButton={this.onToolbarAction}/>

                    <WDToolbarButtonTextDecoration
                        id={306}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.superScriptAvailable : true}
                        pressed={this.props.toolbarSettings?.superScriptPressed}
                        colorPicker={false}
                        command={"superscript"}
                        action={WDToolbarAction.SUPERSCRIPT}
                        icon={ImagePath.getButtonUrl() + "superscript.svg"}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.superscript),
                            this.context.translate(translations.tooltip.superscript))}
                        tooltipDisabled={new TooltipText(
                            this.context.translate(translations.toolbar.superscript_disabled),
                            this.context.translate(translations.tooltip.disabled))}
                        onActionButton={this.onToolbarAction}/>

                    <div style={{marginLeft: 10}}></div>

                    <WDToolbarButtonColor
                        id={307}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.foreColorAvailable : true}
                        command={"foreColor"}
                        options={{
                            action: WDToolbarAction.FONT_COLOR,
                            open: this.state.activeId === 307,
                            width: 70,
                            height: 150
                        }}
                        tooltipPosition={TooltipPosition.above}
                        showColor={true}
                        transparency={false}
                        pressed={false}
                        initialColor={"#000000"}
                        action={WDToolbarAction.FONT_COLOR}
                        icon={ImagePath.getButtonUrl() + "font_color.svg"}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.fontColor),
                            this.context.translate(translations.tooltip.font_color))
                    }
                        onActionButton={this.onToolbarAction}
                        onToggleButton={this.onToggleButton}
                    />

                    <WDToolbarButtonHighlight
                        id={308}
                        clickable={this.props.toolbarSettings ? this.props.toolbarSettings.backColorAvailable : true}
                        command={"backColor"}
                        options={{
                            action: WDToolbarAction.HILITE_COLOR,
                            open: this.state.activeId === 308,
                            width: 70,
                            height: 150
                        }}
                        action={WDToolbarAction.HILITE_COLOR}
                        icon={ImagePath.getButtonUrl() + "highlight_color.svg"}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.highlight),
                            this.context.translate(translations.tooltip.highlight))}
                        tooltipDisabled={new TooltipText(
                            this.context.translate(translations.toolbar.highlight_disabled),
                            this.context.translate(translations.tooltip.disabled))}
                        onActionButton={this.onToolbarAction}
                        onToggleButton={this.onToggleButton}
                    />
                </div>
            </div>
        </div>
    }
}

