import React, {FormEvent, ReactElement} from "react";
import '../assets/css/layout.min.css';
import {MainContext} from "../_base/MainContext";
import {Modal} from "../Components/Modal";
import translations from "../Framework/translations.json";
import {Worksheet} from "../_model/Worksheet";
import SelectBox from "../Components/Controls/SelectBox";
import LessonSubject from "../_model/LessonSubject";
import {GetAllLanguages, GetLessonSubject, GetSchoolLevel} from "../_endpoint/MetadataEndpoint";
import Language from "../_model/Language";
import SchoolLevel from "../_model/SchoolLevel";
import Entity from "../_model/Entity";
import {
    EnumValuesToEntityArray,
    WSStatus
} from "../Framework/Enums";
import {ImagePath} from "../Framework/CategoryImage";
import {GetWorksheetThumbnail} from "../_endpoint/WorksheetEndpoint";
import {TreeView} from "../Components/Controls/TreeView";
import {WorksheetSettings} from "../Designer/WDSettings";
import {ButtonInfo} from "../Components/Controls/ButtonList";
import CheckBox from "../Components/Controls/CheckBox";
import TextBox from "../Components/Controls/TextBox";
import {WDUtils} from "../Designer/Utils/WDUtils";

interface IProps {
    worksheet?: Worksheet
    onClose: () => void
    onSave: (settings: WorksheetSettings) => void
}

interface IState {
    settings: WorksheetSettings
    openSection: string
    thumbnail?: string
    tabHeight: number
    page: number

    copyright: boolean
    acceptMarketplace: boolean

    buttons: ButtonInfo[]
    subject: LessonSubject[]
    language: Language[]
    schoolLevel: SchoolLevel[]
    status: Entity[]
}

export class PublishInMarketplaceModal extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = ({
            settings: this.props.worksheet ? WorksheetSettings.mapWorksheetToSettings(this.props.worksheet) : new WorksheetSettings(""),
            openSection: "menu-setting-general",
            page: 1,
            copyright: false,
            acceptMarketplace: false,
            subject: [],
            language: [],
            schoolLevel: [],
            status: [],
            tabHeight: this.getTabHeight(),
            buttons: this.getButtons(1)
        })
    }

    componentDidMount() {
        GetLessonSubject().then((result) => { this.setState({subject: result })})
        GetAllLanguages().then((result) => {this.setState({language: result})})
        GetSchoolLevel().then((result) => {this.setState({schoolLevel: result})})

        if (this.props.worksheet && this.props.worksheet.id) {
            GetWorksheetThumbnail(this.props.worksheet.id).then((result) => {
                    this.setState({thumbnail: result.thumbnail})
                },
                (_) => {
                    // Ignore error when no thumbnail is available default thumbnail is shown
                })
        }

        window.addEventListener("resize", this.onResizeWindow)

        this.setState({status: EnumValuesToEntityArray(WSStatus, Object.values(WSStatus), translations.enum.ws_status, this.context.translate)})
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.onResizeWindow)
    }

    getButtons = (page: number) => {
        if (page === 1) {
            return [
                new ButtonInfo("btnCancel", "button button-cancel", "button", false,
                    false, this.context.translate(translations.command.cancel), this.onFormCancel,
                    {position: "absolute", left: "50%", marginLeft: "5px"}),
                new ButtonInfo("btnNext", "button button-save", "submit", true,
                    false, this.context.translate(translations.command.next_step), undefined, {})
            ]
        }
        else if (page === 2) {
            return [
                new ButtonInfo("btnBack", "button button-cancel", "button", false,
                    false, this.context.translate(translations.command.back), this.onBack, {}),
                new ButtonInfo("btnNext", "button button-save", "submit", false,
                    false, this.context.translate(translations.command.next_step), undefined, {marginLeft: "10px"})
            ]
        }
        else if (page === 3) {
            return [
                new ButtonInfo("btnBack", "button button-cancel", "button", false,
                    false, this.context.translate(translations.command.back), this.onBack, {}),
                new ButtonInfo("btnNext", "button button-save", "submit", true,
                    false, this.context.translate(translations.command.submit), undefined, {marginLeft: "10px"})
            ]
        }

        return []
    }
    getTabHeight = () => {
        return window.innerHeight * 0.7 - 100
    }
    isTargetRequired = () => {
        return (!this.state.settings.marketplaceTargetParents && !this.state.settings.marketplaceTargetSchool &&
            !this.state.settings.marketplaceTargetPupils && !this.state.settings.marketplaceTargetTeacher)
    }
    scrollOptionsToTop = () => {
        let options = document.querySelector(".modal-publish-worksheet-options")
        if (options) {
            options.scrollTo({top: 0})
        }
    }

    onFormSubmit = (event: FormEvent) => {
        event.preventDefault();

        if(this.state.page > 2) {
            this.props.onSave(this.state.settings)
        }
        else {
            this.onForward()
        }

        return true
    }
    onFormCancel = () => {
        this.props.onClose()
    }
    onForward = () => {
        this.setState({
            page: this.state.page + 1,
            buttons: this.getButtons(this.state.page + 1)
        }, this.scrollOptionsToTop)
    }
    onBack = () => {
        this.setState({
            page: this.state.page - 1,
            buttons: this.getButtons(this.state.page - 1)
        }, this.scrollOptionsToTop)
    }

    onChangeSettingsText = (name: string, value: string) => {
        let newSettings = this.state.settings
        newSettings[name] = value
        this.setState({settings: newSettings })
    }
    onChangeSelection = (field: string, newValue: number) => {
        let newSettings = this.state.settings
        newSettings[field] = this.state[field].find(value => value.id === newValue)
        this.setState({settings: newSettings })
    }
    onChangeSchoolLevel = (values: SchoolLevel[]) => {
        let newSettings = this.state.settings
        newSettings.schoolLevel = values
        this.setState({settings: newSettings })
    }
    onChangeSettingsFlag = (name: string) => {
        let newSettings = this.state.settings
        newSettings[name] = !newSettings[name]
        this.setState({settings: newSettings })
    }

    onChangeCopyright = () => {
        this.setState({copyright: !this.state.copyright})
    }
    onChangeAcceptMarketplace = () => {
        this.setState({acceptMarketplace: !this.state.acceptMarketplace})
    }
    onResizeWindow = () => {
        this.setState({tabHeight: this.getTabHeight()})
    }

    render () {
        let tabHeight = this.getTabHeight()

        let orientation = this.props.worksheet?.orientation
        if (this.props.worksheet?.pages && this.props.worksheet.pages.length > 0) {
            let firstPage = WDUtils.getPagesSorted(this.props.worksheet.pages)[0]
            orientation = firstPage.orientation
        }
        let thumbnailClass = "ws-designer-marketplace-thumbnail"
        if(!orientation) {
            thumbnailClass = thumbnailClass.concat(" ws-designer-marketplace-thumbnail-landscape")
        }

        let agreementText = this.context.translate(translations.tooltip.agreement_marketplace).split("%%")
        let agreementMP: ReactElement = <>
            {agreementText[0]}
            <a href={"https://www.vicido.at/allgemeine-nutzungsbedingungen"} rel="noreferrer" target="_blank">
                {this.context.translate(translations.text.marketplace.usage_terms)}
            </a>
            {agreementText[1]}
            <a href={"https://www.vicido.at/marktplatz-regeln"} rel="noreferrer" target="_blank">
                {this.context.translate(translations.text.marketplace.mp_rules)}
            </a>
            {agreementText[2]}
        </>

        return <Modal id={"settings"}
                      onFormSubmit={this.onFormSubmit}
                      onFormCancel={this.onFormCancel}
                       title={this.context.translate(translations.text.marketplace.send_to_marketplace)}
                       buttons={this.state.buttons}
                       dialogStyle={{width: "70%", height: "70%"}}
                       contentStyle={{paddingTop: 0, paddingLeft: 0, paddingRight: 0, overflowY: "hidden"}}
                       contentAlignment={"flex-start"}
        >

            <div className={"modal-publish-worksheet"} style={{height: tabHeight}}>
                <div className="modal-publish-worksheet-preview">
                    <img src={this.state.thumbnail
                        ? ("data:image/jpeg;base64," + this.state.thumbnail)
                        : process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                         alt={"thumbnail"} className={thumbnailClass} />
                </div>

                <div className="modal-publish-worksheet-options" style={{width: "50%"}}>
                    {/* Step 1: Checkboxes */}
                    {this.state.page === 1 &&
                        <>
                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <CheckBox id={"editingAllowed"}
                                          label={this.context.translate(translations.text.marketplace.editing_allowed)}
                                          labelClass={"label label-primary"}
                                          description={this.context.translate(translations.tooltip.editing_allowed)}
                                          checked={this.state.settings.marketplaceIsEditingAllowed}
                                          readonly={false}
                                          required={false}
                                          style={{width: "100%"}}
                                          onChange={() => this.onChangeSettingsFlag("marketplaceIsEditingAllowed")}
                                />
                            </div>
                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <CheckBox id={"showName"}
                                          label={this.context.translate(translations.text.marketplace.show_name)}
                                          labelClass={"label label-primary"}
                                          description={this.context.translate(translations.tooltip.show_name)}
                                          checked={this.state.settings.marketplaceShowName}
                                          readonly={false}
                                          required={false}
                                          style={{width: "100%"}}
                                          onChange={() => this.onChangeSettingsFlag("marketplaceShowName")}
                                />
                            </div>
                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <CheckBox id={"showEmail"}
                                          label={this.context.translate(translations.text.marketplace.show_email)}
                                          labelClass={"label label-primary"}
                                          description={this.context.translate(translations.tooltip.show_email)}
                                          checked={this.state.settings.marketplaceShowEmail}
                                          readonly={false}
                                          required={false}
                                          style={{width: "100%"}}
                                          onChange={() => this.onChangeSettingsFlag("marketplaceShowEmail")}
                                />
                            </div>

                            <div className={"form-row form-separator"} style={{marginBottom: "20px"}}>
                                <label className="ws-designer-settings-label-big"
                                       htmlFor="worksheetName">{this.context.translate(translations.text.marketplace.infos_for_download)}:</label>
                            </div>

                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <TextBox id={"worksheetName"}
                                         label={this.context.translate(translations.text.worksheet_settings.title)}
                                         labelClass={"label-primary"}
                                         value={this.state.settings.worksheetName || ""}
                                         required={true}
                                         readonly={false}
                                         multiline={false}
                                         style={{width: "100%"}}
                                         onChange={(value) => this.onChangeSettingsText("worksheetName", value)}
                                 />
                            </div>

                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <TextBox id={"description"}
                                         label={this.context.translate(translations.text.worksheet_settings.description)}
                                         labelClass={"label-primary"}
                                         value={this.state.settings.description || ""}
                                         required={false}
                                         readonly={false}
                                         multiline={true}
                                         style={{width: "100%"}}
                                         onChange={(value) => this.onChangeSettingsText("description", value)}
                                 />
                            </div>
                    </>
                    }

                    {/* Step 2: Worksheet settings */}
                    {this.state.page === 2 &&
                        <div className="form-group" style={{marginTop: "10px"}}>
                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <SelectBox id={"selSubject"}
                                           label={this.context.translate(translations.text.worksheet_settings.subject) + ":"}
                                           labelClass={"label-primary"}
                                           labelWidth={120}
                                           width={305}
                                           data={this.state.subject}
                                           onChange={(id) => this.onChangeSelection("subject", id)}
                                           value={this.state.settings?.subject?.id}
                                           required={false}
                                           readonly={false}
                                           positioningRow={false}
                                />
                            </div>

                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <SelectBox id={"selLanguage"}
                                           label={this.context.translate(translations.text.worksheet_settings.language) + ":"}
                                           labelClass={"label-primary"}
                                           labelWidth={120}
                                           width={305}
                                           data={this.state.language}
                                           onChange={(id) => this.onChangeSelection("language", id)}
                                           value={this.state.settings?.language?.id}
                                           required={false}
                                           readonly={false}
                                           positioningRow={false}
                                />
                            </div>

                            {this.state.schoolLevel &&
                                <div className={"form-row"} style={{marginBottom: "20px"}}>
                                    <TreeView
                                        id={"lstSubSchoolLevel"}
                                        data={this.state.schoolLevel}
                                        selectedData={this.state.settings?.schoolLevel || []}
                                        required={true}
                                        readonly={false}
                                        parent={"parent"}
                                        width={250}
                                        onChange={this.onChangeSchoolLevel}
                                    />
                                </div>
                            }

                            <div className={"form-row"}>
                                <CheckBox id={"blackWhite"}
                                          label={this.context.translate(translations.text.worksheet_settings.black_white)}
                                          checked={this.state.settings.blackWhite}
                                          readonly={false}
                                          required={false}
                                          style={{width: "100%"}}
                                          onChange={() => this.onChangeSettingsFlag("blackWhite")}
                                />
                            </div>

                            <div className={"form-row form-separator"} style={{marginBottom: "20px"}}>
                                <div className={"form-group"} style={{width: "50%"}}>
                                    <CheckBox id={"targetSchool"}
                                              label={this.context.translate(translations.text.marketplace.target_school)}
                                              checked={this.state.settings.marketplaceTargetSchool}
                                              readonly={false}
                                              required={this.isTargetRequired()}
                                              style={{width: "100%"}}
                                              onChange={() => this.onChangeSettingsFlag("marketplaceTargetSchool")}
                                    />
                                    <CheckBox id={"targetPupils"}
                                              label={this.context.translate(translations.text.marketplace.target_pupils)}
                                              checked={this.state.settings.marketplaceTargetPupils}
                                              readonly={false}
                                              required={this.isTargetRequired()}
                                              style={{width: "100%"}}
                                              onChange={() => this.onChangeSettingsFlag("marketplaceTargetPupils")}
                                    />
                                </div>
                                <div className={"form-group"}>
                                    <CheckBox id={"targetTeacher"}
                                              label={this.context.translate(translations.text.marketplace.target_teacher)}
                                              checked={this.state.settings.marketplaceTargetTeacher}
                                              readonly={false}
                                              required={this.isTargetRequired()}
                                              style={{width: "100%"}}
                                              onChange={() => this.onChangeSettingsFlag("marketplaceTargetTeacher")}
                                    />
                                    <CheckBox id={"targetParents"}
                                              label={this.context.translate(translations.text.marketplace.target_parents)}
                                              checked={this.state.settings.marketplaceTargetParents}
                                              readonly={false}
                                              required={this.isTargetRequired()}
                                              style={{width: "100%"}}
                                              onChange={() => this.onChangeSettingsFlag("marketplaceTargetParents")}
                                    />
                                </div>
                            </div>

                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <TextBox id={"keywords"}
                                         label={this.context.translate(translations.text.worksheet_settings.keywords)}
                                         labelClass={"label-primary"}
                                         value={this.state.settings.marketplaceKeywords || ""}
                                         placeholder={this.context.translate(translations.text.worksheet_settings.keywords_placeholder)}
                                         required={false}
                                         readonly={false}
                                         multiline={true}
                                         style={{width: "90%"}}
                                         onChange={(value) => this.onChangeSettingsText("marketplaceKeywords", value)}
                                />
                            </div>
                        </div>
                    }

                    {this.state.page === 3 &&
                        <>
                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <CheckBox id={"confirmCopyright"}
                                          label={this.context.translate(translations.text.marketplace.confirm_copyrights)}
                                          labelClass={"label label-primary"}
                                          description={this.context.translate(translations.tooltip.confirm_copyrights)}
                                          checked={this.state.copyright}
                                          readonly={false}
                                          required={true}
                                          style={{width: "100%"}}
                                          onChange={this.onChangeCopyright}
                                />
                            </div>
                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <CheckBox id={"agreementMarketplace"}
                                          label={this.context.translate(translations.text.marketplace.agreement_marketplace)}
                                          labelClass={"label label-primary"}
                                          descriptionHTML={agreementMP}
                                          checked={this.state.acceptMarketplace}
                                          readonly={false}
                                          required={true}
                                          style={{width: "100%"}}
                                          onChange={this.onChangeAcceptMarketplace}
                                />
                            </div>
                            <div className={"form-row"} style={{marginBottom: "20px"}}>
                                <CheckBox id={"advertisement"}
                                          label={this.context.translate(translations.text.marketplace.advertisement) + " (optional)"}
                                          labelClass={"label label-primary"}
                                          description={this.context.translate(translations.tooltip.advertisement)}
                                          checked={this.state.settings.marketplaceAdvertisement}
                                          readonly={false}
                                          required={false}
                                          style={{width: "100%"}}
                                          onChange={() => this.onChangeSettingsFlag("marketplaceAdvertisement")}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    }
}
