import {WorksheetItemTypeEnum} from "../../_model/WorksheetItemType";
import ProblemReport from "../../_model/base/ProblemReport";

export enum WDActionLogType {
    Info,
    Warning,
    Error
}

export enum WDActionLogCategory {
    save = "save",
    error = "error",
    create = "create",
    delete = "delete",
    resize = "resize",
    move = "move",
    flip = "flip",
    rotate = "rotate",
    skew = "skew",
    position = "position",
    arrange = "arrange",
    unwrap = "unwrap",
    border = "border",
    background = "background",
    lock = "lock",
    align = "align",
    content = "content",
    toolbar_action = "toolbar_action",
    distribute = "distribute",
    presentation_action = "presentation_action",
    syllable = "syllable",
    undo = "undo",
    redo = "redo",
    paste = "paste",
    duplicate = "duplicate",
    orientation = "orientation",
    unknown = "unknown",

    add_page = "add_page",
    delete_page = "delete_page",
    copy_page = "copy_page",
    move_page = "move_page",
    page_solution = "page_solution",
    page_border = "page_border"
}

export class WDActionLogEntry {
    date: Date
    type: WDActionLogType
    category: WDActionLogCategory
    details: WDActionLogEntryDetails[]
    description?: string
    report?: ProblemReport[]

    constructor(type: WDActionLogType, category: WDActionLogCategory, details: WDActionLogEntryDetails[], description?: string, report?: ProblemReport[]) {
        this.date = new Date()
        this.type = type
        this.category = category
        this.details = details
        this.description = description
        this.report = report
    }
}

export class WDActionLogEntryDetails {
    itemKey: string
    itemType: WorksheetItemTypeEnum
    before: string
    after: string

    constructor(itemKey: string, itemType: WorksheetItemTypeEnum, before: any, after: any) {
        this.itemKey = itemKey
        this.itemType = itemType
        this.before = this.serialize(before)
        this.after = this.serialize(after)
    }


    serialize(data: any) {
        return JSON.stringify(data, (key, value) => {
            if (key === "content" || key === "ref") return undefined;
            else return value;
        })
    }
}
