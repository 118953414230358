import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../../WDElementBase";
import {ResizeInfo, WDElementContainer} from "../../WDElementContainer";
import {WDToolbarAction} from "../../../Toolbar/WDToolbarAction";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../../Framework/CategoryImage";
import {CalculationType} from "../../../Toolbar/Button/Math/WDToolbarButtonCalculationType";
import translations from "../../../../Framework/translations.json";
import {Difficulty, NotificationStatus, SolutionForceMode} from "../../../../Framework/Enums";
import Const from "../../../../Framework/Const";
import {MainContext} from "../../../../_base/MainContext";
import {WDCalculationValue} from "../WDCalculationValue";
import {FloatingHint} from "../../../../Components/Notification/FloatingHint";
import {Util} from "../../../../Framework/Util";
import {WDCalculationTriangleTextData} from "../CalculationTriangle/WDCalculationTriangle";
import {NotificationData} from "../../../../Components/Notification/Hint";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../../Utils/WorksheetItemUpdate";
import {WDPresentationAction} from "../../../Presentation/WDPresentationAction";
import {WDActionLogCategory} from "../../../ActionLog/WDActionLogEntry";
import Converter from "../../../../Framework/Converter";

export class WDCalculationTowerData extends WDElementBaseData {
    id?: number
    data: CalculationValueRow[]
    zr: number
    maxValue: number
    minValue: number
    calculationType: CalculationType
    difficultyLevel: Difficulty
    imageKey: string
    solutionShow: boolean
    solutionBarShow: boolean
    solutionBarValues: string[]
    taskGenerator: boolean
    digitExceeded: boolean
    zeroCalculation: boolean
    textValue: WDCalculationTriangleTextData
    rowAmount: number
    roofNumber?: string
    lineColor?: string

    constructor(zr: number, maxValue: number, minValue: number, calculationType: CalculationType, difficultyLevel: Difficulty, data: CalculationValueRow[],
                solutionShow: boolean, solutionBarShow: boolean, taskGenerator: boolean, digitExceeded: boolean, zeroCalculation: boolean,
                image: string, textValue: WDCalculationTriangleTextData, rowAmount: number, roofNumber?: string, solutionBarValues?: string[], lineColor?: string) {

        super()
        this.zr = zr
        this.data = data
        this.maxValue = maxValue
        this.minValue = minValue
        this.calculationType = calculationType
        this.difficultyLevel = difficultyLevel
        this.solutionShow = solutionShow
        this.solutionBarShow = solutionBarShow
        this.solutionBarValues = solutionBarValues || []
        this.taskGenerator = taskGenerator
        this.digitExceeded = digitExceeded
        this.zeroCalculation = zeroCalculation
        this.imageKey = image
        this.textValue = textValue
        this.rowAmount = rowAmount
        this.roofNumber = roofNumber
        this.lineColor = lineColor
    }
}

export class CalculationValueRow {
    firstValue: WDCalculationValue
    secondValue: WDCalculationValue
    rowPos?: number

    constructor(firstValue: WDCalculationValue, secondValue: WDCalculationValue, rowPos?: number) {
        this.firstValue = firstValue
        this.secondValue = secondValue
        this.rowPos = rowPos
    }
}

interface IProps extends WDElementBaseProps {
    data: WDCalculationTowerData
}

interface IState extends WDElementBaseState {
    infoText?: string
}

export class WDCalculationTower extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    initialFontSize: number = 18
    maxRowAmount: number = 12
    headRowRatio = 4.1916 // used for row height calculation - head image has first row included - dividing head by ratio gives height of row

    resizeInfoTower: ResizeInfo =
        new ResizeInfo(
            true, true, true, true, false, false, false, false,
            250, Const.MaxElementSize, 250, Const.MaxElementSize)

    constructor(props: IProps) {
        super(props)

        // Initialize data if not set
        if (this.props.data.data.length === 0) {
            let data = this.getData()
            let update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(data)})
            this.props.onUpdateElement(update,
                {actionCategory: WDActionLogCategory.content})
        }

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef()
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getDefaultWidth = () => {
        return Converter.toMmGrid(300)
    }
    static getDefaultHeight = () => {
        return Converter.toMmGrid(300)
    }

    static getImageNameByKey = (jsonData: any) => {
        let imageKey = "M-AT-RT-STANDARD"

        if (jsonData.imageKey && jsonData.imageKey !== "") {
            imageKey = jsonData.imageKey
        }

        return imageKey.includes("THUMB") ? WDCalculationTower.replaceThumbImage(imageKey) : WDCalculationTower.replaceCutKeyByImage(imageKey)
    }
    /**
     * Removes specific position (floor, roof, row) of tower graphic to save generic tower graphic name
     */
    static replaceCutKeyByImage = (imageKey: string) => {
        let newImage = CategoryImageValue.getImageByKey([ImageCategory.CALCULATION_TOWER], imageKey + "-FL")
        return newImage.substring(0, newImage.lastIndexOf("-floor.svg"))
    }
    /**
     * Replaces the thumb by the matching graphic
     * @param thumbImageKey to find thumb image svg
     */
    static replaceThumbImage = (thumbImageKey: string) => {
        let category = ImageCategory.CALCULATION_TOWER_THUMBNAIL
        let thumbImage = CategoryImageValue.getImageByKey([category], thumbImageKey)

        return thumbImage.substring(0, thumbImage.lastIndexOf(".svg")).toUpperCase()
    }

    onHideInfo = () => {
        this.setState({infoText: undefined})
    }

    getData = () => {
        let data = this.props.data

        if (data.zeroCalculation === undefined) {
            data.zeroCalculation = true
        }
        data.imageKey = WDCalculationTower.getImageNameByKey(data)

        if (data.data.length === 0) {
            data.data = this.calculateValues(
                data.difficultyLevel,
                data.calculationType,
                data.maxValue,
                data.minValue,
                true,
                true,
                data.rowAmount,
            )
            data.rowAmount = data.data.length
            data.roofNumber = this.getRoofValue(data.data, data.calculationType).toString()
        }

        if (data.solutionBarValues === undefined) {
            data.solutionBarValues = this.getSolutionBarValues(data.data)
        }

        let fontSize = this.calculateFontSize(this.props.element.width)

        if (data.textValue === undefined) {
            data.textValue = new WDCalculationTriangleTextData(
                fontSize,
                "#1e1f28",
                false,
                false,
                false
            )
        }

        // Always calculate fontsize!
        data.textValue.fontSize = fontSize

        return data
    }
    setEmptyValues = (rowAmount: number): CalculationValueRow[] => {
        let calculationValues: CalculationValueRow[] = []

        for (let i = 0; i < rowAmount; i++) {
            calculationValues.push(this.addEmptyRow())
        }
        return calculationValues
    }

    updateCalculationData(data: WDCalculationTowerData) {
        data.data = this.calculateValues(
            data.difficultyLevel,
            data.calculationType,
            data.maxValue,
            data.minValue,
            data.digitExceeded,
            data.zeroCalculation,
            data.rowAmount)

        data.rowAmount = data.data.length
        data.roofNumber = this.getRoofValue(data.data, data.calculationType).toString()

        if (data.solutionBarShow) {
            data.solutionBarValues = this.getSolutionBarValues(data.data)
        }
    }

    calculateValues = (difficultyLevel: Difficulty, calculationType: CalculationType, maxValue: number, minValue: number, digitExceeded: boolean,
                       zeroCalculation: boolean, rowAmount: number): CalculationValueRow[] => {
        let minZeroCalcNumber = zeroCalculation ? 0 : 1
        let min = Math.max(minValue, rowAmount)
        if (min < 1) {
            min = minZeroCalcNumber
        }
        if (min > maxValue) {
            min = maxValue
        }

        let roofValue = Math.round(Math.random() * (maxValue - min) + min)
        let calculationValues: CalculationValueRow[] = []

        if (calculationType === CalculationType.MULTIPLY) {
            roofValue = Math.round(Math.random() * (10 - minZeroCalcNumber)) + minZeroCalcNumber
        } else if (calculationType === CalculationType.DIVIDE) {
            roofValue = Math.floor(Math.random() * 10) + 1
        } else if (calculationType === CalculationType.ADD) {
            // check that roof number is small enough that all rows can be filled and max value is not exceeded
            let max = maxValue - rowAmount
            if (max === 0 && !zeroCalculation) {
                max = 1
            }
            roofValue = Math.floor(Math.random() * (max - minZeroCalcNumber)) + minZeroCalcNumber
        }

        for (let i = 0; i < rowAmount; i++) {
            let row = this.addRow(
                roofValue,
                calculationType,
                difficultyLevel,
                calculationValues,
                maxValue,
                digitExceeded,
                zeroCalculation)
            if (row !== undefined) {
                calculationValues.push(row)
            }
        }

        // if with new roofValue calculation less combinations are possible > reduce container
        // if (this.state && (calculationValues.length < this.props.data.data.length)) {
        //     this.getElementHeight(calculationValues.length, false)
        // }

        return calculationValues
    }
    addEmptyRow = (): CalculationValueRow => {
        return new CalculationValueRow(
            new WDCalculationValue("", false),
            new WDCalculationValue("", false))
    }
    addRow = (roofNumber: number, calculationType: CalculationType, difficultyLevel: Difficulty, calculationData: CalculationValueRow[],
              maxValue: number, digitExceeded: boolean, zeroCalculation: boolean): CalculationValueRow | undefined => {
        let calculationCorrect = false
        let newValueBasis = 0
        let newValueFirst = 0
        let newValueSecond = 0
        let chosen = false

        if (calculationType === CalculationType.COMPLEMENT) {
            let minNumber = zeroCalculation ? 0 : 1
            let maxNumber = zeroCalculation ? roofNumber : roofNumber - 1

            newValueBasis = Math.round(Math.random() * (maxNumber - minNumber)) + minNumber
            newValueFirst = newValueBasis

            if (newValueBasis === minNumber) {
                newValueBasis = roofNumber
            }

            if (digitExceeded) {
                while ((chosen = this.isNumberAlreadyChosen(calculationData, newValueFirst, true)) && newValueFirst !== newValueBasis - 1) {
                    newValueFirst = newValueFirst < maxNumber ? (newValueFirst + 1) : minNumber
                }
                if (!chosen) {
                    newValueSecond = (roofNumber - newValueFirst)
                    calculationCorrect = true
                }

            } else {
                // get unit place of numbers to check if digit exceeded
                let roofModulo = roofNumber % 10
                let newValueFirstModulo = newValueFirst % 10

                while (((chosen = this.isNumberAlreadyChosen(calculationData, newValueFirst, true)) || roofModulo < newValueFirstModulo)
                && (newValueFirst !== newValueBasis - 1)) {
                    newValueFirst = newValueFirst < roofNumber ? (newValueFirst + 1) : minNumber
                    newValueFirstModulo = newValueFirst % 10
                }

                if (!chosen && roofModulo >= newValueFirstModulo) {
                    newValueSecond = (roofNumber - newValueFirst)
                    calculationCorrect = true
                }
            }

        } else if (calculationType === CalculationType.ADD) {
            let minNumber = zeroCalculation ? 0 : 1
            let limit = maxValue - roofNumber
            newValueBasis = Math.round(Math.random() * (limit - minNumber)) + minNumber
            newValueFirst = newValueBasis

            if (newValueBasis === minNumber) {
                newValueBasis = limit
            }

            if (digitExceeded) {
                while ((chosen = this.isNumberAlreadyChosen(calculationData, newValueFirst, true)) && newValueFirst !== newValueBasis - 1) {
                    newValueFirst = newValueFirst < limit ? (newValueFirst + 1) : minNumber
                }
                if (!chosen) {
                    newValueSecond = (roofNumber + newValueFirst)
                    calculationCorrect = true
                }

            } else {
                // get unit place of numbers to check if digit exceeded
                let roofModulo = roofNumber % 10
                let newValueFirstModulo = newValueFirst % 10
                let sumModulo = roofModulo + newValueFirstModulo

                while (((chosen = this.isNumberAlreadyChosen(calculationData, newValueFirst, true)) || sumModulo > 10)
                && (newValueFirst !== newValueBasis - 1)) {
                    newValueFirst = newValueFirst < limit ? (newValueFirst + 1) : minNumber
                    newValueFirstModulo = newValueFirst % 10
                    sumModulo = newValueFirstModulo + roofModulo
                }

                if (!chosen && sumModulo <= 10) {
                    newValueSecond = (roofNumber + newValueFirst)
                    calculationCorrect = true
                }
            }

        } else if (calculationType === CalculationType.MULTIPLY) {
            let minNumber = zeroCalculation ? 0 : 1
            newValueBasis = Math.round(Math.random() * (10 - minNumber)) + minNumber
            newValueFirst = newValueBasis
            newValueSecond = roofNumber * newValueFirst

            if (newValueBasis === minNumber) {
                newValueBasis = 10
            }

            while ((chosen = this.isNumberAlreadyChosen(calculationData, newValueFirst, true)) && newValueFirst !== newValueBasis - 1) {
                newValueFirst = newValueFirst < 10 ? (newValueFirst + 1) : minNumber
                newValueSecond = roofNumber * newValueFirst
            }
            if (!chosen) {
                calculationCorrect = true
            }

        } else if (calculationType === CalculationType.DIVIDE) {
            newValueBasis = Math.floor(Math.random() * 10) + 1
            newValueSecond = newValueBasis
            newValueFirst = roofNumber * newValueSecond

            if (newValueBasis === 1) {
                newValueBasis = 10
            }

            while ((chosen = this.isNumberAlreadyChosen(calculationData, newValueSecond, false)) && newValueSecond !== newValueBasis - 1) {
                newValueSecond = newValueSecond < 10 ? (newValueSecond + 1) : 1
                newValueFirst = newValueSecond * roofNumber
            }
            if (!chosen) {
                calculationCorrect = true
            }
        }

        // Value does not exist in list => push
        if (calculationCorrect) {
            let calculationValue = new CalculationValueRow(
                new WDCalculationValue(newValueFirst.toString(), false),
                new WDCalculationValue(newValueSecond.toString(), true))
            return this.solutionByDifficultyLevel(difficultyLevel, calculationValue)
        }

    }
    deleteRow = (calculationData: CalculationValueRow[]): CalculationValueRow[] | undefined => {
        calculationData.pop()
        return calculationData
    }
    solutionByDifficultyLevel = (difficultyLevel: Difficulty, calculationValue: CalculationValueRow): CalculationValueRow => {
        let difficulty = difficultyLevel
        if (difficulty === Difficulty.none) {
            let randomNumber = Math.random()

            if (randomNumber < 0.4) {
                difficulty = Difficulty.easy
            } else if (randomNumber > 0.65) {
                difficulty = Difficulty.hard
            } else {
                difficulty = Difficulty.medium
            }
        }

        switch (difficulty) {
            case Difficulty.easy:
                calculationValue.firstValue.solution = false
                calculationValue.secondValue.solution = true
                break

            case Difficulty.medium:
                calculationValue.firstValue.solution = true
                calculationValue.secondValue.solution = false
                break

            case Difficulty.hard:
                let random = Math.random() * 9
                random < 5 ? calculationValue.firstValue.solution = true : calculationValue.firstValue.solution = false
                random >= 5 ? calculationValue.secondValue.solution = true : calculationValue.secondValue.solution = false
                break

            default:
                calculationValue.firstValue.solution = false
                calculationValue.secondValue.solution = true
                break
        }

        return calculationValue
    }
    getRoofValue = (data: CalculationValueRow[], calculationType: CalculationType): number => {
        const firstValue = +data[0].firstValue.data
        const secondValue = +data[0].secondValue.data
        let roofValue = firstValue + secondValue

        switch (calculationType) {
            case CalculationType.ADD:
                roofValue = secondValue - firstValue
                break

            case CalculationType.MULTIPLY:
                roofValue = secondValue / firstValue

                // to make sure that division by 0 never happens
                if (isNaN(roofValue)) {
                    roofValue = (+data[data.length - 1].secondValue.data) / (+data[data.length - 1].firstValue.data)
                }
                break

            case CalculationType.DIVIDE:
                roofValue = firstValue / secondValue

                // to make sure that division by 0 never happens
                if (isNaN(roofValue)) {
                    roofValue = (+data[data.length - 1].firstValue.data) / (+data[data.length - 1].secondValue.data)
                }
                break
        }

        return roofValue
    }
    getOperatorString = (): string => {
        let operator = ""
        switch (this.props.data.calculationType) {
            case CalculationType.DIVIDE:
                operator = ":"
                break
            case CalculationType.MULTIPLY:
                operator = '\u00B7';
                break
            case CalculationType.ADD:
                operator = "+"
                break
        }
        return operator
    }
    isNumberAlreadyChosen = (chosenData: CalculationValueRow[], value: number, first: boolean): boolean => {
        return first
            ? (chosenData.find(data => {
                return +data.firstValue.data === value
            }) !== undefined)
            : (chosenData.find(data => {
                return +data.secondValue.data === value
            }) !== undefined)
    }

    onChangeInputValue = async (event: React.ChangeEvent, row: number, firstValue: boolean) => {
        const value = (event.target as HTMLInputElement).value

        if (!isNaN(+value)) {
            let newData = {...this.props.data}

            if (row < 0) {
                newData.roofNumber = (event.target as HTMLInputElement).value
            } else if (firstValue) {
                newData.data[row].firstValue.data = (event.target as HTMLInputElement).value
            } else if (!firstValue) {
                newData.data[row].secondValue.data = (event.target as HTMLInputElement).value
            }

            this.props.onUpdateElement(new WorksheetItemUpdate(
                    this.props.id, {content: this.serializeElementData(newData)}),
                {actionCategory: WDActionLogCategory.content}
            )
        }
    }
    onChangeSolution = async (event: React.ChangeEvent, solution: number) => {
        const value = (event.target as HTMLInputElement).value

        if (!isNaN(+value)) {
            let newData = {...this.props.data}
            newData.solutionBarValues[solution] = value

            this.props.onUpdateElement(new WorksheetItemUpdate(
                    this.props.id, {content: this.serializeElementData(newData)}),
                {actionCategory: WDActionLogCategory.content}
            )
        }
    }

    getSolutionBarValues = (data: CalculationValueRow[]) => {
        let solutionValues: string[] = []
        for (let i = 0; i < data.length; i++) {
            solutionValues.push(this.getSolutionValue(data, i))
        }

        return Util.shuffleArray(solutionValues)
    }
    private getSolutionValue = (data: CalculationValueRow[], rowNumber: number): string => {
        if (data[rowNumber].firstValue.solution) {
            return Util.formatNumber(+data[rowNumber].firstValue.data, 'de-at').toString()
        } else if (data[rowNumber].secondValue !== undefined && data[rowNumber].secondValue.solution) {
            return Util.formatNumber(+data[rowNumber].secondValue.data, 'de-at').toString()
        }

        return ""
    }
    private getCorrectSolutionImageNumber = (): number => {
        return Math.max(Math.ceil(this.props.data.rowAmount / 4), 1);
    }

    calculateFontSize = (width: number) => {
        let fontSize = this.initialFontSize / WDCalculationTower.getDefaultWidth()
        return fontSize * width
    }

    getFirstRowHeight = () => {
        return this.props.element.width * 0.472
    }
    getLastRowHeight = () => {
        return this.props.element.width * 0.416
    }
    getRowHeight = () => {
        return this.getFirstRowHeight() / this.headRowRatio
    }

    /**
     * Overridden methods
     */
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    getElementHeight = (rows: number) => {
        return Util.roundTo(this.getFirstRowHeight() + this.getLastRowHeight() + (this.getRowHeight() * (rows - 2)), 2)
    }
    recalculateSize = (width: number, height: number): WorksheetItemUpdate => {
        let newData = {...this.props.data}
        if (newData.textValue === undefined) {
            newData.textValue = new WDCalculationTriangleTextData(18, "#1e1f28", false, false, false)
        }
        newData.textValue.fontSize = this.calculateFontSize(width)

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData),
            width: width,
            height: height
        })
    }
    isEditModeAllowed = (): boolean => {
        return !this.props.data.taskGenerator
    }
    onEdit = (editMode: boolean) => {
        this.forceUpdate()
        this.props.onElementEdit?.(this.props.id, editMode)
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDCalculationTowerData.serialize(data as WDCalculationTowerData)
    }

    doAction = (action: WDToolbarAction, data?: any) => {
        let newData = {...this.props.data}

        let update: WorksheetItemUpdate = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.GENERATE_TASK:
                if (data) {
                    let generateTask = data["generateTask"]
                    let newCalc: CalculationValueRow[] = []

                    if (generateTask) {
                        this.updateCalculationData(newData)
                    } else {
                        newCalc = this.setEmptyValues(newData.rowAmount)
                        newData.roofNumber = ""
                        newData.solutionBarValues = []
                    }

                    newData.data = newCalc
                    newData.rowAmount = newCalc.length
                    newData.taskGenerator = generateTask
                }
                break

            case WDToolbarAction.REGENERATE:
                if (newData.taskGenerator) {
                    this.updateCalculationData(newData)
                }
                break

            case WDToolbarAction.CHANGE_GRAPHIC:
                if (data && data["image"]) {
                    newData.imageKey = WDCalculationTower.replaceThumbImage(data["image"])

                    // #3113 make sure that image will not be colored when it is not standard
                    if (!newData.imageKey.toString().toLowerCase().includes("standard")) {
                        newData.lineColor = undefined
                    }
                }
                break

            case WDToolbarAction.LINE_COLOR:
                if (data) {
                    newData.lineColor = data["lineColor"]
                }
                break

            case WDToolbarAction.CHANGE_SOLUTION_SHOW:
                if (data) {
                    newData.solutionShow = data["showSolution"]
                }
                break

            case WDToolbarAction.SOLUTION_BAR:
                if (data) {
                    newData.solutionBarShow = data["showSolutionBar"]
                    newData.solutionBarValues = this.getSolutionBarValues(newData.data)
                }
                break

            case WDToolbarAction.CHANGE_DIGIT_EXCEEDED:
                if (data) {
                    newData.digitExceeded = data["digitExceeded"]
                    this.updateCalculationData(newData)
                }
                break

            case WDToolbarAction.CHANGE_ZERO_CALCULATION:
                if (data) {
                    newData.zeroCalculation = data["zeroCalculation"]
                    this.updateCalculationData(newData)
                }
                break

            case WDToolbarAction.CHANGE_VALUE_RANGE:
                if (data) {
                    newData.maxValue = data["maxValue"]
                    newData.minValue = data["minValue"]
                    newData.zr = data["zr"]

                    this.updateCalculationData(newData)
                }
                break

            case WDToolbarAction.CHANGE_CALCULATION_TYPE:
                if (data) {
                    newData.calculationType = +data["calculationType"]

                    if (newData.taskGenerator) {
                        if (newData.calculationType === CalculationType.MULTIPLY || newData.calculationType === CalculationType.DIVIDE) {
                            newData.maxValue = 100
                            newData.zr = 100
                            newData.digitExceeded = true
                        }

                        this.updateCalculationData(newData)
                    }
                }
                break

            case WDToolbarAction.CHANGE_DIFFICULTY_LEVEL:
                if (data) {
                    newData.difficultyLevel = data["difficultyLevel"]
                    newData.data.map(row => {
                        return this.solutionByDifficultyLevel(newData.difficultyLevel, row)
                    })
                }
                break

            case WDToolbarAction.ADD_ROW:
                if (newData.rowAmount >= this.maxRowAmount) {
                    this.setState({infoText: (this.context.translate(translations.notification.max_row_amount_reached))})
                } else {
                    let addedRow = newData.taskGenerator ? this.addRow(
                        +newData.roofNumber!,
                        newData.calculationType,
                        newData.difficultyLevel,
                        newData.data,
                        newData.maxValue,
                        newData.digitExceeded,
                        newData.zeroCalculation
                    ) : this.addEmptyRow()

                    if (addedRow) {
                        newData.rowAmount++
                        newData.data.push(addedRow)

                        if (newData.solutionBarShow) {
                            newData.solutionBarValues = this.getSolutionBarValues(newData.data)
                        }

                    } else {
                        this.setState({infoText: (this.context.translate(translations.notification.max_row_amount_reached_calc))})
                    }
                }
                break

            case WDToolbarAction.DELETE_ROW:
                if (newData.rowAmount > 2) {
                    let leftRows = this.deleteRow(newData.data)
                    if (leftRows) {
                        newData.rowAmount--
                        newData.data = leftRows

                        if (newData.solutionBarShow) {
                            newData.solutionBarValues = this.getSolutionBarValues(newData.data)
                        }
                    }
                } else {
                    this.setState({infoText: (this.context.translate(translations.notification.min_row_amount_reached))})
                }
                break

            case WDToolbarAction.BOLD:
                newData.textValue.bold = !newData.textValue.bold
                break

            case WDToolbarAction.ITALIC:
                newData.textValue.italic = !newData.textValue.italic
                break

            case WDToolbarAction.STRIKETHROUGH:
                newData.textValue.strikeThrough = !newData.textValue.strikeThrough
                break

            case WDToolbarAction.FONT_COLOR:
                if (data) {
                    newData.textValue.color = data["color"]
                }
                break
        }

        update.value.content = this.serializeElementData(newData)
        update.value.height = this.getElementHeight(newData.rowAmount)
        return update
    }
    doPresentationAction = (action: WDPresentationAction, data?: any): WorksheetItemUpdate => {
        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDPresentationAction.CHANGE_SOLUTION_SHOW:
                update.value.renderSolutionInPresentationMode = !this.props.element.renderSolutionInPresentationMode
                break
        }

        return update
    }

    renderSolution = (): boolean => {
        if (this.props.inPresentationMode) {
            return this.props.element.renderSolutionInPresentationMode
        } else {
            return (this.props.solutionForceMode === SolutionForceMode.ForceShow ||
                (this.props.solutionForceMode === SolutionForceMode.Off && this.props.data.solutionShow))
        }
    }

    render() {
        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }
        const editMode = this.state.elementRef.current?.state.editMode === true
        let data = this.props.data
        let renderSolution = this.renderSolution()

        // check if line color must be applied
        let colorClass = ""
        if (data.lineColor !== undefined) {
            colorClass = "svg-color-" + data.lineColor.toUpperCase()
        }

        const imageUrl = process.env.PUBLIC_URL + ImagePath.getTowerUrl() + data.imageKey.toLowerCase().replace("-thumb", "")
        const rowHeight = this.getRowHeight()

        let textValue = data.textValue
        if (textValue === undefined) {
            let fontSize = this.calculateFontSize(this.props.element.width)
            textValue = new WDCalculationTriangleTextData(fontSize, "#1e1f28", false, false, false)
        }

        // rows
        let rows: JSX.Element[] = []
        for (let i = data.rowAmount - 2; i >= 1; i--) {
            rows.push(<div className={"ws-designer-calculation-tower-row"}
                           key={"ws-designer-calc-tower-row-" + this.props.id + "-" + i}
            >
                <img id={"calcTower-" + this.props.id + "-row-img"}
                     key={"calcTower-" + this.props.id + "-row-img-" + i}
                     className={colorClass}
                     style={{width: this.props.element.width, height: rowHeight}}
                     src={imageUrl + "-row.svg"}
                     alt={this.context.translate(translations.math.elements.calculation_triangle)}
                />
                <div className={"ws-designer-calculation-tower-row-input"}
                     key={"ws-designer-calc-tower-row-input-" + this.props.id + "-" + i}>
                    {(renderSolution || !data.data[i].firstValue.solution) &&
                        <input className={data.data[i].firstValue.solution
                            ? "ws-designer-calculation-tower-row-input-left solution"
                            : "ws-designer-calculation-tower-row-input-left"}
                               type={"text"}
                               key={"ws-designer-calc-tower-row-input-" + this.props.id + "-" + i + "-first"}
                               readOnly={data.taskGenerator || !editMode}
                               onChange={(e) => this.onChangeInputValue(e, i, true)}
                               style={{
                                   fontSize: textValue.fontSize + "pt",
                                   fontWeight: textValue.bold ? "bold" : "normal",
                                   fontStyle: textValue.italic ? "italic" : "normal",
                                   textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                   color: data.data[i].firstValue.solution ? undefined : textValue.color
                               }}
                               value={data.data[i].firstValue.data
                                   ? Util.formatNumber(+data.data[i].firstValue.data, 'de-at')
                                   : ""}
                        />
                    }
                    {(renderSolution || !data.data[i].secondValue.solution) &&
                        <input className={data.data[i].secondValue.solution
                            ? "ws-designer-calculation-tower-row-input-right solution"
                            : "ws-designer-calculation-tower-row-input-right"}
                               type={"text"}
                               key={"ws-designer-calc-tower-row-input-" + this.props.id + "-" + i + "-second"}
                               readOnly={data.taskGenerator || !editMode}
                               onChange={(e) => this.onChangeInputValue(e, i, false)}
                               style={{
                                   fontSize: textValue.fontSize + "pt",
                                   fontWeight: textValue.bold ? "bold" : "normal",
                                   fontStyle: textValue.italic ? "italic" : "normal",
                                   textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                   color: data.data[i].secondValue.solution ? undefined : textValue.color
                               }}
                               value={data.data[i].secondValue.data
                                   ? Util.formatNumber(+data.data[i].secondValue.data, 'de-at')
                                   : ""}
                        />
                    }
                </div>
            </div>)
        }

        // solution bar values
        let solutionBarValues: JSX.Element[] = []
        if (data.solutionBarShow) {
            for (let i = 0; i < data.rowAmount; i++) {
                solutionBarValues.push(
                    <div className={"ws-designer-calc-element-solution  ws-designer-calc-tower-solution"}
                         key={"calc-tower-" + this.props.id + "-solution-" + i}>

                        <input id={"calc-tower-" + this.props.id + "-solution-input-" + i}
                               key={"calc-tower-" + this.props.id + "-solution-input-" + i}
                               type={"text"}
                               readOnly={data.taskGenerator || !editMode}
                               style={{
                                   fontSize: textValue.fontSize + "pt",
                                   fontWeight: textValue.bold ? "bold" : "normal",
                                   fontStyle: textValue.italic ? "italic" : "normal",
                                   color: textValue.color
                               }}
                               onChange={(event) => this.onChangeSolution(event, i)}
                               value={data.solutionBarValues[i] || ""}/>

                        {(data.taskGenerator && renderSolution) &&
                            <div className={"ws-designer-calc-element-solution-values-checked"}
                                 key={"ws-designer-calc-element-solution-values-checked-" + this.props.id + "-" + i}/>
                        }
                    </div>
                )
            }
        }

        let roofImage = data.calculationType !== CalculationType.COMPLEMENT ? "-operator" : "-roof"
        let kindOfSolutionImage = this.getCorrectSolutionImageNumber()

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            onEdit={this.onEdit}
            hasResizeOnCreate={false}
            resizeInfo={this.resizeInfoTower}
            renderWrapper={true}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.props.onElementResize}
            isEditModeAllowed={this.isEditModeAllowed}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
        >

            <div id={"calcTower-" + this.props.id} className="ws-designer-calculation-tower print">
                <div id={"calcTower-" + this.props.id + "-first"}
                     className={"ws-designer-calculation-tower-first"}>

                    <img id={"calcTower-" + this.props.id + roofImage + "-img"}
                         className={colorClass}
                         src={imageUrl + roofImage + ".svg"}
                         style={{width: this.props.element.width, height: this.getFirstRowHeight()}}
                         alt={this.context.translate(translations.math.elements.calculation_triangle)}
                    />
                    {data.calculationType !== CalculationType.COMPLEMENT &&
                        <input className={"ws-designer-calculation-tower-operator"}
                               type={"text"}
                               readOnly={true}
                               style={{fontSize: data.textValue.fontSize + "pt"}}
                               value={this.getOperatorString()}/>}
                    <input className={"ws-designer-calculation-tower-solution"}
                           type={"text"}
                           readOnly={data.taskGenerator || !editMode}
                           onChange={(e) => this.onChangeInputValue(e, -1, true)}
                           style={{
                               fontSize: textValue.fontSize + "pt",
                               fontWeight: textValue.bold ? "bold" : "normal",
                               fontStyle: textValue.italic ? "italic" : "normal",
                               textDecoration: textValue.strikeThrough ? "line-through" : "none",
                               color: textValue.color
                           }}
                           value={data.roofNumber ? Util.formatNumber(+data.roofNumber, 'de-at') : ""}
                    />
                    <div className={"ws-designer-calculation-tower-row-input"}>
                        {(renderSolution || !data.data[data.rowAmount - 1].firstValue.solution) &&
                            <input className={data.data[data.rowAmount - 1].firstValue.solution
                                ? "ws-designer-calculation-tower-row-input-left solution"
                                : "ws-designer-calculation-tower-row-input-left"}
                                   type={"text"}
                                   readOnly={data.taskGenerator || !editMode}
                                   onChange={(e) => this.onChangeInputValue(e, data.data.length - 1, true)}
                                   style={{
                                       fontSize: textValue.fontSize + "pt",
                                       fontWeight: textValue.bold ? "bold" : "normal",
                                       fontStyle: textValue.italic ? "italic" : "normal",
                                       textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                       color: data.data[data.rowAmount - 1].firstValue.solution ? undefined : textValue.color,
                                   }}
                                   value={data.data[data.rowAmount - 1].firstValue.data
                                       ? Util.formatNumber(+data.data[data.rowAmount - 1].firstValue.data, 'de-at')
                                       : ""}
                            />
                        }
                        {(renderSolution || !data.data[data.rowAmount - 1].secondValue.solution) &&
                            <input className={data.data[data.rowAmount - 1].secondValue.solution
                                ? "ws-designer-calculation-tower-row-input-right solution"
                                : "ws-designer-calculation-tower-row-input-right"}
                                   type={"text"}
                                   readOnly={data.taskGenerator || !editMode}
                                   onChange={(e) => this.onChangeInputValue(e, data.data.length - 1, false)}
                                   style={{
                                       fontSize: textValue.fontSize + "pt",
                                       fontWeight: textValue.bold ? "bold" : "normal",
                                       fontStyle: textValue.italic ? "italic" : "normal",
                                       textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                       color: data.data[data.rowAmount - 1].secondValue.solution ? undefined : textValue.color
                                   }}
                                   value={data.data[data.rowAmount - 1].secondValue.data
                                       ? Util.formatNumber(+data.data[data.rowAmount - 1].secondValue.data, 'de-at')
                                       : ""}
                            />
                        }
                    </div>
                </div>

                {rows}

                <div id={"calcTower-" + this.props.id + "-last"}
                     className={"ws-designer-calculation-tower-last"}>

                    <img id={"calcTower-" + this.props.id + "-floor-img"}
                         className={colorClass}
                         src={imageUrl + "-floor.svg"}
                         style={{width: this.props.element.width, height: this.getLastRowHeight()}}
                         alt={this.context.translate(translations.math.elements.calculation_triangle)}
                    />
                    <div className={"ws-designer-calculation-tower-row-input"}>
                        {(renderSolution || !data.data[0].firstValue.solution) &&
                            <input className={data.data[0].firstValue.solution
                                ? "ws-designer-calculation-tower-row-input-left solution"
                                : "ws-designer-calculation-tower-row-input-left"}
                                   type={"text"}
                                   readOnly={data.taskGenerator || !editMode}
                                   onChange={(e) => this.onChangeInputValue(e, 0, true)}
                                   style={{
                                       fontSize: textValue.fontSize + "pt",
                                       fontWeight: textValue.bold ? "bold" : "normal",
                                       fontStyle: textValue.italic ? "italic" : "normal",
                                       textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                       color: data.data[0].firstValue.solution ? undefined : textValue.color
                                   }}
                                   value={data.data[0].firstValue.data
                                       ? Util.formatNumber(+data.data[0].firstValue.data, 'de-at')
                                       : ""}
                            />
                        }
                        {(renderSolution || !data.data[0].secondValue.solution) &&
                            <input className={data.data[0].secondValue.solution
                                ? "ws-designer-calculation-tower-row-input-right solution"
                                : "ws-designer-calculation-tower-row-input-right"}
                                   type={"text"}
                                   readOnly={data.taskGenerator || !editMode}
                                   onChange={(e) => this.onChangeInputValue(e, 0, false)}
                                   style={{
                                       fontSize: textValue.fontSize + "pt",
                                       fontWeight: textValue.bold ? "bold" : "normal",
                                       fontStyle: textValue.italic ? "italic" : "normal",
                                       textDecoration: textValue.strikeThrough ? "line-through" : "none",
                                       color: data.data[0].secondValue.solution ? undefined : textValue.color,
                                   }}
                                   value={data.data[0].secondValue.data
                                       ? Util.formatNumber(+data.data[0].secondValue.data, 'de-at')
                                       : ""}
                            />
                        }
                    </div>
                </div>
            </div>

            {this.state.infoText &&
                <FloatingHint id={"info-calculation-tower-row-number"}
                              notificationData={new NotificationData(NotificationStatus.info, this.state.infoText)}
                              style={{width: "200px", left: "22px", top: "75%"}}
                              onHide={this.onHideInfo}/>
            }

            {data.solutionBarShow &&
                <div id={"calc-tower-" + this.props.id + "-solution"}
                     className={"ws-designer-calc-element-solution-bar print"}>

                    <img id={"calc-tower-" + this.props.id + "-solution-bar-image"}
                         className={"image-in-container " + colorClass}
                         draggable={false}
                         src={imageUrl + CategoryImageValue.getImageByKey([ImageCategory.CALCULATION_TRIANGLE_SOLUTION_BAR], data.imageKey) + "-solutions" + kindOfSolutionImage + ".svg"}
                         alt={this.context.translate(translations.math.elements.calculation_tower)}/>

                    <div className={"ws-designer-calc-element-solution-values  ws-designer-calc-tower-solution-values"}
                         style={{top: kindOfSolutionImage === 1 ? "102%" : (kindOfSolutionImage === 2 ? "101.5%" : "101%")}}>
                        {solutionBarValues}
                    </div>

                    {/*Overlay to prevent input values being selected*/}
                    {data.taskGenerator && <div className={"ws-designer-element-solution-bar-overlay"}/>}
                </div>
            }
        </WDElementContainer>
    }
}
