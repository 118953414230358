import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarButtonGraphics} from "../../Toolbar/Button/General/WDToolbarButtonGraphics";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../Framework/CategoryImage";
import translations from "../../../Framework/translations.json";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import Converter from "../../../Framework/Converter";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarSectionObjectGeneral} from "../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import {ElementLayout} from "../WDElementContainer";
import {WDToolbarButtonSeparator} from "../../Toolbar/Button/General/WDToolbarButtonSeparator";
import {WDToolbarButtonTextDecoration} from "../../Toolbar/Button/Text/WDToolbarButtonTextDecoration";
import {WDToolbarButtonList} from "../../Toolbar/Button/Text/WDToolbarButtonList";
import {WDToolbarSectionText, WDToolbarSectionTextSettings} from "../../Toolbar/Section/WDToolbarSectionText";
import {WDToolbarButtonColor} from "../../Toolbar/Button/General/WDToolbarButtonColor";
import {TooltipPosition, TooltipText} from "../../../Components/Tooltips";
import {WDToolbarButtonSolution} from "../../Toolbar/Button/Admin/WDToolbarButtonSolution";
import {WSContext} from "../WSContext";
import {SyllableMethod} from "../../../_model/Dictionary/Word";
import {WDToolbarButtonConvertToSyllableText} from "../../Toolbar/Button/Text/WDToolbarButtonConvertToSyllableText";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {WDHistoryAction} from "../../History/Enum/WDHistoryAction";
import {Util} from "../../../Framework/Util";

export interface WDToolbarBalloonProps extends WDToolbarElementProps {
    syllableMethod: SyllableMethod
}
export interface WDToolbarBalloonState extends WDToolbarElementState { }

export class WDBalloonToolbar extends WDToolbarElement<WDToolbarBalloonProps, WDToolbarBalloonState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    imageCategories: ImageCategory[] = [ImageCategory.BALLOON, ImageCategory.BALLOON_THINK, ImageCategory.BALLOON_COMIC]

    textToolbarSettings: WDToolbarSectionTextSettings = new WDToolbarSectionTextSettings(true, true,
        true, true, true, true, true, true
        , true, true, true, true, true, true)

    constructor(props: WDToolbarBalloonProps) {
        super(props)

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef()
        }
    }

    componentDidUpdate(prevProps: WDToolbarBalloonProps) {
        // Check if new selected element is different from previous selected element by data
        if (this.props.editMode === prevProps.editMode &&
            this.props.worksheetItemData.length === prevProps.worksheetItemData.length &&
            this.props.worksheetItemData.every((value, index) => { return value === prevProps.worksheetItemData[index]})) {

            return
        }

        this.evaluateToolbarType(prevProps.editMode, this.props.editMode)

        this.resetActiveId()
    }
    componentDidMount() {
        this.resetActiveId()
    }

    onChangeTransparency = (transparency: number) => {
        this.props.onUpdateSelectedElements(new WorksheetItemUpdate("", {
            transparency: transparency
        }), { historyAction: WDHistoryAction.TRANSPARENCY })
    }
    onChangeFillColor = (color: string) => {
        this.props.onUpdateSelectedElements(new WorksheetItemUpdate("", {
            fillColor: color
        }), { historyAction: WDHistoryAction.FILL_COLOR })
        this.resetActiveId()
    }
    onChangeBorderColor = (action: WDToolbarAction, data: string) => {
        if (data["color"]) {
            let border = this.props.elementProps.border
            border.color = data["color"]
            border.visible = true
            border.style = "solid"

            this.props.onChangeBorder(border)
            this.resetActiveId()
        }
    }

    getElementGraphic = (obj: any) => {
        // #4266 Graphic of changeGraphic button should change matching to the element
        let imageKey: string = obj.newValue
        let iconString = ImagePath.getBalloonUrl() + "d-at-speak01-icon.svg"

        if(imageKey) {
            let image: string | undefined = undefined
            let categoryAmount = this.imageCategories.length

            while (categoryAmount >= 0) {
                image = CategoryImageValue.getImageByKey(this.imageCategories, imageKey)

                if(image) {
                    return ImagePath.getBalloonUrl() + image
                }

                categoryAmount -= 1
            }
        }

        return iconString
    }

    render () {
        let graphicsBalloon = this.multiselectSearchForValue("image")
        let activateSyllableValueObj = this.multiselectSearchForValue("syllableActivated")
        const textObj = this.multiselectSearchForValueFromObject(this.props.additionalToolbarData, "textData")

        const activateSyllable = (activateSyllableValueObj?.valueEqual && activateSyllableValueObj.newValue !== undefined) ? activateSyllableValueObj.newValue : false

        // set initial fontSize
        let fontSize: string | null = null
        let fontType: string | null = null
        if (textObj) {
            let textNode = this.getTextNode(textObj)

            if (textNode) {
                fontSize = (textNode.style && textNode.style.fontSize) ? textNode.style.fontSize : Util.getChildStyle(textNode, "fontSize", true)
                fontType = (textNode.style && textNode.style.fontFamily) ? textNode.style.fontFamily : Util.getChildStyle(textNode, "fontFamily", true)
            }
        }
        this.textToolbarSettings.fontSize = fontSize !== null ? fontSize : undefined
        this.textToolbarSettings.fontType = fontType !== null ? fontType : undefined

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, true, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={false}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={true}
                    canChangeLinkState={true}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarButtonGraphics
                        id={5}
                        pressed={this.state.activeId === 5}
                        clickable={true}
                        onToggleButton={this.onToggleButton}
                        onActionButton={this.props.onToolbarAction}

                        icon={this.getElementGraphic(graphicsBalloon)}
                        iconButtonWidth={90}
                        iconButtonHeight={72}
                        iconImageWidth={70}
                        iconImageHeight={72}

                        imageCategory={[ImageCategory.BALLOON, ImageCategory.BALLOON_THINK, ImageCategory.BALLOON_COMIC]}
                        imageWidth={155}
                        imagePath={ImagePath.getBalloonUrl()}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.change_graphic),
                            this.context.translate(translations.tooltip.change_graphic)
                        )}/>
                </div>

                <div className={"ws-designer-toolbar-row"} style={{height: "100%"}}>

                    <WDToolbarButtonSeparator/>

                    <div className={"ws-designer-toolbar-section"}>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonColor
                                id={12}
                                clickable={true}
                                command={"borderColor"}
                                options={{
                                    action: WDToolbarAction.BORDER_COLOR,
                                    open: this.state.activeId === 12,
                                    width: 70,
                                    height: 150
                                }}
                                tooltipPosition={TooltipPosition.above}
                                showColor={false}
                                transparency={false}
                                pressed={false}
                                initialColor={this.props.elementProps.border.color}
                                action={WDToolbarAction.BORDER_COLOR}
                                icon={ImagePath.getButtonUrl() + "frame.svg"}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.border.border_options),
                                    this.context.translate(translations.tooltip.border))
                                }
                                onActionButton={this.onChangeBorderColor}
                                onToggleButton={this.onToggleButton}
                            />
                        </div>

                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonColor
                                id={11}
                                clickable={true}
                                command={"foreColor"}
                                options={{
                                    action: WDToolbarAction.FILL_COLOR,
                                    open: this.state.activeId === 11,
                                    width: 70,
                                    height: 150
                                }}
                                tooltipPosition={TooltipPosition.above}
                                showColor={true}
                                transparency={false}
                                pressed={false}
                                transparencyAmount={this.props.elementProps.color.transparency}
                                onChangeTransparency={this.onChangeTransparency}
                                initialColor={this.props.elementProps.color.fillColor}
                                action={WDToolbarAction.FILL_COLOR}
                                icon={ImagePath.getButtonUrl() + "fill_color.svg"}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.fillColor),
                                    this.context.translate(translations.tooltip.fill_color))
                                }
                                onActionButton={(action, data) => this.onChangeFillColor(data.color)}
                                onToggleButton={this.onToggleButton}
                            />
                        </div>
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={false}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    elementProps={this.props.elementProps}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onChangeBorder={this.props.onChangeBorder}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onElementDeleted={this.props.onElementDeleted}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.TEXT &&

                <ul className={"ws-designer-toolbar-buttons"}>
                    <WDToolbarSectionText
                        toolbarSettings={this.textToolbarSettings}
                        onToolbarAction={this.onToolbarAction} />

                    <WDToolbarButtonSeparator/>

                    {/* Justify content */}
                    <div className={"ws-designer-toolbar-section"}>
                        <div className={"ws-designer-toolbar-row"}>

                            <WDToolbarButtonTextDecoration
                                id={401}
                                clickable={true}
                                colorPicker={false}
                                command={"justifyLeft"}
                            action={WDToolbarAction.JUSTIFY_LEFT}
                            icon={ImagePath.getButtonUrl() + "justify_left.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.justify_left))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonTextDecoration
                            id={403}
                            clickable={true}
                            colorPicker={false}
                            command={"justifyRight"}
                            action={WDToolbarAction.JUSTIFY_RIGHT}
                            icon={ImagePath.getButtonUrl() + "justify_right.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.justify_right))}
                            onActionButton={this.onToolbarAction}/>
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonTextDecoration
                            id={402}
                            clickable={true}
                            colorPicker={false}
                            command={"justifyCenter"}
                            action={WDToolbarAction.JUSTIFY_CENTER}
                            icon={ImagePath.getButtonUrl() + "justify_center.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.justify_center))}
                            onActionButton={this.onToolbarAction}/>

                        <WDToolbarButtonTextDecoration
                            id={404}
                            clickable={true}
                            colorPicker={false}
                            command={"justifyFull"}
                            action={WDToolbarAction.JUSTIFY_FULL}
                            icon={ImagePath.getButtonUrl() + "justify_block.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.justify_full))}
                            onActionButton={this.onToolbarAction}/>
                    </div>
                </div>

                    {/* list options */}
                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonList
                            id={501}
                            clickable={true}
                            command={"insertUnorderedList"}
                            action={WDToolbarAction.UNORDERED_LIST}
                            options={{
                                action: WDToolbarAction.UNORDERED_LIST_IMAGE,
                                open: this.state.activeId === 501,
                                imageCategory: ImageCategory.UNORDERED_BULLET_LIST,
                                imagePath: ImagePath.getListStyleUrl(),
                                width: 215,
                                height: 150,
                                itemWidth: 55,
                                itemHeight: 55
                            }}
                            icon={ImagePath.getButtonUrl() + "bulletpoints.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.unordered_list))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}/>
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonList
                            id={502}
                            clickable={true}
                            command={"insertOrderedList"}
                            action={WDToolbarAction.ORDERED_LIST}
                            options={{
                                action: WDToolbarAction.ORDERED_LIST_IMAGE,
                                open: this.state.activeId === 502,
                                imageCategory: ImageCategory.ORDERED_BULLET_LIST,
                                imagePath: ImagePath.getListStyleUrl(),
                                width: 215,
                                height: 150,
                                itemWidth: 55,
                                itemHeight: 55
                            }}
                            icon={ImagePath.getButtonUrl() + "numbering.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.toolbar.ordered_list))}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}/>
                    </div>
                </div>

                <WDToolbarButtonSeparator/>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonConvertToSyllableText
                            id={604}
                            pressed={activateSyllable}
                            clickable={this.props.syllableMethod !== SyllableMethod.noSyllable}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                            onShowConfirmation={this.props.onShowConfirmation}
                        />
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                    </div>
                </div>

                {/* Only in admin mode! */}
                {WSContext.showToolbarButtonMarkSolution(this.props.context) &&
                    <>
                        <WDToolbarButtonSeparator/>

                        <WDToolbarButtonSolution
                            id={602}
                            clickable={true}
                            onActionButton={this.onToolbarAction}
                            icon={"solution_mark.svg"}
                            action={WDToolbarAction.SOLUTION}
                        />
                    </>
                }

            </ul>
            }
        </WDToolbar>
    }
}
