import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {DropDown, DropDownItem, DropDownType} from "../../../../Components/Controls/DropDown";
import {Util} from "../../../../Framework/Util";
import Const from "../../../../Framework/Const";
import {Tooltips, TooltipText} from "../../../../Components/Tooltips";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";

/**
 * Number input and drop down in Text Toolbar
 * Effects selected text of an element
 * If a value is entered or selected from the list the font size is changed
 */

interface IProps {
    id: number
    action: WDToolbarAction
    readonly: boolean
    width?: number
    initialValue?: string

    filterCondition?: {key: string, value: string}
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    value: string
}

export class WDToolbarFontType extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    private fontTypes: DropDownItem[] = [
        { value: "VicidoSans", name: "VICIDo Sans", style: {fontFamily: "VicidoSans"} },
        { value: "VicidoLS", name: "VICIDo Leseschrift", style: {fontFamily: "VicidoLS"} },
        { value: "VicidoKT", name: "VICIDo Konturenschrift", style: {fontFamily: "VicidoKT"} },
        { value: "Arial", name: "Arial", style: {fontFamily: "Arial"} },
        { value: "Century Gothic", name: "Century Gothic", style: {fontFamily: "Century Gothic"} },
        { value: "Comic Sans MS", name: "Comic Sans", style: {fontFamily: "Comic Sans MS"} },
        { value: "OpenSansRegular", name: "Open Sans Regular", style: {fontFamily: "OpenSansRegular" } },
        { value: "sans-serif", name: "Sans Serif", style: {fontFamily: "sans-serif"} },
        { value: "Times New Roman", name: "Times New Roman", style: {fontFamily: "Times New Roman"} },
    ]

    constructor(props: IProps, state: IState) {
        super(props, state);

        let initialValue = this.props.initialValue ? this.getFontNameByFamily(this.props.initialValue) : undefined

        this.state = {
            value: initialValue || Const.FONT_VICIDO_SANS
        }

        if(this.props.filterCondition) {
            this.fontTypes = this.fontTypes.filter((item) => item[this.props.filterCondition!.key].includes(this.props.filterCondition!.value))
        }
    }

    componentDidMount() {
        document.addEventListener('selectionchange', this.onSelectionChange, false);
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({value: this.props.initialValue || Const.FONT_VICIDO_SANS})
        }
    }
    componentWillUnmount() {
        document.removeEventListener('selectionchange', this.onSelectionChange, false);
    }

    getFontNameByFamily = (fontFamily: string) => {
        let fontName = fontFamily.replaceAll("\"", "").trim()

        // Get display name from item collection
        let item = this.fontTypes.find(i => i.value === fontName)
        if (item) {
            fontName = item.value
        }

        return fontName
    }
    onSelectionChange = () => {
        const selection = window.getSelection()
        if (selection && selection.focusNode) {
            const selectedElement = selection.focusNode as HTMLElement

            // Get the textbox element and check if selected item is in text
            const parent = Util.getParentByClass(selectedElement, "ws-designer-textbox")
            if (parent) {

                // Default font size
                let fontName = this.state.value

                // Get the font tag "above" the current node, stop at textbox which is the container
                const font = Util.getParentByStyle(selectedElement, "font-family", "", parent.id, [])

                // If found, get the font family and set it as current value
                if (font) {
                    const fontFamily = font.style.fontFamily
                    fontName = this.getFontNameByFamily(fontFamily)
                }

                this.setState({value: fontName})
            }
        }
    }

    onChangeValue = (value: string) => {
        this.setState({value: value})

        this.props.onActionButton(this.props.action, {command: "fontName", name: value})
    }

    render() {
        return <li
            key={"ws-designer-toolbar-font-type"}
            style={{paddingRight: 5, paddingLeft: 2}}
            className={this.props.readonly ? "ws-designer-toolbar-button-disabled tooltip-above" : "tooltip-above"}
            id={"font-family"}>

            <DropDown id={"font-family"}
                      className={"tooltip-control"}
                      width={this.props.width || 155}
                      readonly={this.props.readonly}
                      required={false}
                      autocomplete={false}
                      type={DropDownType.TEXT}
                      value={this.state.value}
                      items={this.fontTypes}
                      onChangeValue={this.onChangeValue} />

            <Tooltips
                text={new TooltipText(
                    this.context.translate(translations.toolbar.font_type),
                    this.context.translate(translations.tooltip.font_type)
                )}
                translateX={-5} translateY={-34} />
        </li>
    }
}
