import React from 'react';
import {Link} from "react-router-dom";
import Entity from "../../_model/Entity";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import ListPagination from "./ListPagination";
import {genericSort} from "../../Framework/Sort";
import {MainContext} from "../../_base/MainContext";
import Card, {CardAction, CardDefinition} from "./Card";
import {Hint, NotificationData} from "../Notification/Hint";
import {NotificationStatus} from "../../Framework/Enums";
import {Tooltips} from "../Tooltips";
import {ListType, ListTypeProps, ListTypeState} from "./ListType";
import ListFooter from "./ListFooter";

interface IProps extends ListTypeProps {
    redirectTarget?: string

    definitions: CardDefinition
    cardActions: CardAction[]

    onLoadThumbnail: (id: number) => void
}
interface IState extends ListTypeState {
}

export default class CardList extends ListType<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        let sortValueContext = this.getCurrentSortValue()
        let sortValueNew = {
            property: sortValueContext.sortString as keyof Entity,
            isDescending: sortValueContext.descending
        }

        this.state = {
            page: this.getCurrentPage(),
            sortValue: sortValueNew
        }

        this.getThumbnails()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if(prevProps.items?.length !== this.props.items?.length ||
            prevProps.items?.filter(item => {return item.item.thumbnail !== undefined}).length
            !== this.props.items?.filter(item => {return item.item.thumbnail !== undefined}).length) {

            this.getThumbnails()
        }

        if (this.props.loading && this.props.loading !== prevProps.loading) {
            this.scrollDown()
        }
    }

    getThumbnails = () => {
        this.props.items?.forEach(item => {
            if(item.item.thumbnail === undefined) {
                this.props.onLoadThumbnail(item.item.id!)
            }
        })
    }

    render() {
        let footer: string | undefined = undefined
        if (this.props.allowMultiSelect) {
            footer = this.getFooterText()
        }

        let currentPage = this.getCurrentPage()

        let dynamicScrolling = this.props.paginationOptions.allowPagination && !this.props.paginationOptions.showPagination
        let cardAreaClass = "card-area" + ((dynamicScrolling && footer === undefined) ? " card-area-scroll-height" : " card-area-no-scroll-height")

        return <div className={cardAreaClass} onScroll={dynamicScrolling ? this.onScrollContent : undefined} id={"scrollContainer"}>
            <div className={"card-rows"}>

                {this.props.items && this.props.items
                    .sort((itemA, itemB) => {
                        return genericSort<Entity>(itemA.item, itemB.item, this.state.sortValue);})
                    .map((value, i) => {
                        if (this.props.paginationOptions.externalPagination === true ||
                            (i >= (this.props.paginationOptions.pageSize * this.state.page) && i < (this.props.paginationOptions.pageSize * (this.state.page + 1)))) {
                            return <Card
                                value={value}
                                selected={
                                    value.item.id
                                    ? this.props.selectedItems.includes(value.item.id)
                                    : false
                                }
                                onSelectItem={this.onItemSelect}
                                onUnselectItem={this.onItemUnselect}

                                cardDefinition={this.props.definitions}
                                cardActions={this.props.cardActions}
                                redirectUrl={this.props.redirectUrl}
                                redirectTarget={this.props.redirectTarget}
                                key={"card" + value.item.id}/>
                        }
                        return undefined
                    })
                }

            </div>

            {dynamicScrolling && !this.props.loading && this.props.items !== undefined && !this.props.paginationOptions.lastElementReached &&
                <div className={"list-load-more-link"} onClick={this.onClickLoadContent}>
                    {this.context.translate(translations.text.load_more)}
                </div>
            }

            {(this.props.loading || this.props.items === undefined) &&
                <div className={"list-notification-container"}>
                    <Hint id={"list-loading"}
                          style={{width: "50%", marginTop: 20, marginBottom: 20, height: 50}}
                          notificationData={new NotificationData(NotificationStatus.loading, this.context.translate(translations.notification.loading))}/>
                </div>
            }
            {this.props.items !== undefined && !this.props.loading && this.props.items.length === 0 &&
                <div className={"list-notification-container"}>
                    <Hint id={"list-empty"}
                          style={{width: "50%", marginTop: 20, height: 50}}
                          notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.image_search_no_result))}/>
                </div>
            }

            {this.props.newItemUrl &&
                <div className={"plus-button-container tooltip-above"} style={{bottom: 5}}>
                    <div className={"plus-button tooltip-control"}>
                        <Link id="btnAddEntry" style={{textDecoration: "none"}} to={this.props.newItemUrl}>
                            <input type="image" alt={this.context.translate(translations.command.add)} className={"plus-button-main"}
                                   src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "plus.svg"}/>
                        </Link>
                    </div>

                    {this.props.newItemTooltip &&
                        <Tooltips
                            text={this.props.newItemTooltip}
                            translateX={15} translateY={110} />
                    }
                </div>
            }

            {this.props.paginationOptions.showPagination &&
                <ListPagination page={currentPage}
                                pageCount={this.getPageCount()}
                                onPageChange={this.onPageChange}
                                manipulatePageNumberRepresentation={this.props.paginationOptions.manipulatePageNumberRepresentation || false}
                                text={footer}
                />
            }
            {dynamicScrolling && footer !== undefined && footer &&
                <div className="list-pagination">
                    <ListFooter text={footer}/>
                </div>
            }
        </div>
    }
}

