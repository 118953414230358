import React from "react";
import {UserSettings} from "../_model/UserSettings";
import {Resource} from "../Framework/Util";
import {Notification} from "../Components/Notification/NotificationHandler";
import {Log} from "../Framework/Log";
import {ListFilter} from "../Components/List/ListFilter";
import {AdminDataListSortSettings} from "../Admin/Maintenance/AdminDataListSortSettings";
import {ListRecord} from "../Components/List/ListRecord";
import {AdminObjectType} from "../Framework/Enums";
import Announcement from "../_model/Announcement";
import {VicidoProduct} from "../_model/VicidoProduct";
import {WorksheetFilter, WorksheetMarketplaceFilter} from "../_model/Worksheet";
import {ICacheObject} from "../Framework/Cache/ICacheObject";
import {WorksheetItemTypeEnum} from "../_model/WorksheetItemType";
import {Coords} from "../Framework/Coords";
import {
    WDActionLogCategory,
    WDActionLogEntry,
    WDActionLogEntryDetails,
    WDActionLogType
} from "../Designer/ActionLog/WDActionLogEntry";
import {TutorialData, TutorialStepData} from "../Designer/Tutorial/TutorialData";
import ProblemReport from "../_model/base/ProblemReport";

// Implementation in AppStart
export const MainContext = React.createContext({
        /** Core */
        translate: (r: Resource): string => {
            return r.de
        },
        log: new Log(),

        /** Error Handling */
        handleError: (error: unknown, message?: string, notificationId?: string, title?: string, category?: WDActionLogCategory) => {
        },

        /** User */
        getUserSettings: (): Promise<UserSettings> => {
            return new Promise<UserSettings>(resolve => {
                resolve(UserSettings.getDefaultSettings())
            })
        },
        updateUserSettings: (userSettings: UserSettings) => {
        },
        getUserProductLicense: (): Promise<VicidoProduct[]> => {
            return new Promise<VicidoProduct[]>(resolve => {
                resolve([])
            })
        },

        /** List filter and sorting */
        getListFilter: (entityType: AdminObjectType): ListFilter | undefined => {
            return undefined
        },
        setListFilter: (filter: ListFilter) => {
        },
        getListSortValue: (entityType: AdminObjectType): AdminDataListSortSettings | undefined => {
            return undefined
        },
        setListSortValue: (sortValue: AdminDataListSortSettings) => {
        },
        getListRecords: (): ListRecord[] | undefined => {
            return undefined
        },
        setListRecords: (data: ListRecord[]) => {
        },

        /** Worksheet filter */
        getWorksheetFilter: (): WorksheetFilter => {
            return WorksheetFilter.initFilter()
        },
        setWorksheetFilter: (filter: WorksheetFilter, callback?: () => void) => {
        },

        /** Marketplace filter */
        getMarketplaceFilter: (): WorksheetMarketplaceFilter => {
            return WorksheetMarketplaceFilter.initFilter()
        },
        setMarketplaceFilter: (filter: WorksheetMarketplaceFilter, callback?: () => void) => {
        },

        /** Announcements */
        getGlobalAnnouncements: (): Announcement[] => {
            return []
        },

        /** Zoom */
        getZoom: (): number => {
            return 1
        },
        setZoom: (zoom: number) => {
        },

        /** Notifications */
        setNotification: (notification: Notification, notificationId?: string) => {
        },
        removeNotification: (notificationId: string) => {
        },
        removeContext: () => {
        },

        /** Image Cache */
        getCacheObject: (id: number, objectType: WorksheetItemTypeEnum): ICacheObject | undefined => {
            return undefined
        },
        setCacheObject: (obj: ICacheObject) => {
        },

        /** Manual Toolbar Coords */
        getManualToolbarCoords: (): Coords | undefined => {
            return undefined
        },
        setManualToolbarCoords: (coords: Coords | undefined) => { },

        /** Designer Action Log */
        initWDActionLog: () => { },
        addWDAction: (type: WDActionLogType, category: WDActionLogCategory, details: WDActionLogEntryDetails[], description?: string, report?: ProblemReport[]) => { },
        getWDActionLog: (): WDActionLogEntry[] => { return [] },
        setOnSendErrorReportDelegate: (onSendErrorReport: (message: string) => void) => {},

        /** Tutorials */
        loadTutorials: () => { },
        initTutorial: (tutorialKey: string, steps: TutorialStepData[]) => {
        },
        getTutorial: (tutorialKey: string): TutorialData | undefined => {
            return undefined
        },
        setTutorialStep: (tutorialKey: string, step: number) => {
        },
        closeTutorial: (tutorialKey: string) => {
        }
    }
)
