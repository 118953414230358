import React, {ReactElement} from "react";

interface IProps {
    id: string
    label: string
    labelClass?: string
    description?: string
    descriptionHTML?: ReactElement
    checked: boolean
    readonly: boolean
    required: boolean
    style?: React.CSSProperties

    onChange?: () => void
}

export default class CheckBox extends React.Component<IProps> {

    onChange = () => {
        this.props.onChange?.()
    }

    render() {
        return <div className="form-group" style={{padding: 0}}>
            <div className={"checkbox"} style={this.props.style}>
                <div className={"checkbox-element"}>
                    <input type={"checkbox"}
                           className={""}
                           id={this.props.id}
                           value={this.props.id}
                           disabled={this.props.readonly}
                           checked={this.props.checked}
                           required={this.props.required}
                           onChange={() => {}}
                    />
                    <label className={(this.props.labelClass || "label") + (this.props.readonly ? " label-disabled" : "")}
                           htmlFor={this.props.id}
                           onClick={this.onChange}
                    >
                        {this.props.label}
                    </label>
                </div>

                {this.props.description &&
                <div className={"checkbox-description"}>
                    {this.props.description.split(/\n/).map((line, i) => <div key={i}>{line}&nbsp;</div>)}
                </div>
                }
                {this.props.descriptionHTML &&
                    <div className={"checkbox-description"}>
                        {this.props.descriptionHTML}
                    </div>
                }
            </div>
        </div>
    }
}
