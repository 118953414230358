import React from 'react';
import {CloneName, GetAllNamesAdmin, GetName, UpdateName,} from "../../_endpoint/NameEndpoint";
import {ColumnDefinition} from "../../Components/List/List";
import {Resource} from "../../Framework/Util";
import translations from "../../Framework/translations.json";
import Name from "../../_model/Name";
import {AdminObjectType, ColumnRenderOption, Status} from "../../Framework/Enums";
import NameListItem from "./NameListItem";
import {Notification} from "../../Components/Notification/NotificationHandler";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import User from "../../_model/User";
import Const from "../../Framework/Const";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: Name[]
    listItems?: NameListItem[],
    pageSize: number
}

export default class NameList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.name), "name", 15, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.language), "languages", 27, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.name_fields.gender), "gender", 10, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.name_fields.difficulty), "difficulty", 10, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 10, false, true, ColumnRenderOption.DateTime)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            pageSize: 100
        }
    }

    componentDidMount() {
        this.fetchData()

        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })
    }

    fetchData = () => {
        this.setState({ items: [] })

        GetAllNamesAdmin().then(
            (itemData) => {
                const listItems = itemData.map(item => {
                    // Enums (translated)
                    let status = translations.enum.status[item.status]

                    let gender: Resource | null = null
                    if(item.gender) { gender = translations.enum.gender[item.gender] }

                    let difficulty: Resource | null = null
                    if(item.difficulty) { difficulty = translations.enum.difficulty[item.difficulty] }

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    let languages = ""
                    if (item.languages) {
                        languages = item.languages
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(r => r.name).join(", ")
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    return new NameListItem(
                        item.id!,
                        item.name,
                        item.notes ? item.notes : "",
                        owner,
                        item.modifiedOn || item.createdOn!,
                        languages,
                        gender !== null ? this.context.translate(gender) : "",
                        difficulty !== null ? this.context.translate(difficulty) : "",
                        this.context.translate(status),
                        createdBy
                    )
                })

                this.setState({ items: itemData, listItems: listItems })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    onCopyItem = async(items: number[]) => {
        try {
            for (const id of items) {
                const name = await GetName(id)
                if (name && name.status !== Status.deactivated) {

                    let clone: Name = { ...name }
                    clone.id = undefined
                    clone.status = Status.draft

                    await CloneName(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy))
                }
            }

            this.fetchData()
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

        }
        catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }

    onSetState = async(item: number, status: Status, user?: User) => {
        try {
            const name = this.state.items.find(d => d.id === item)
            if (name && name.status !== status) {
                Name.setStatus(name, status, user)

                await UpdateName(name)
                return true
            }
        } catch (e) { }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.name) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.name) + "{0}"}
                              objectType={AdminObjectType.name}
                              onCopyItem={this.onCopyItem}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              paginationOptions={{
                                  allowPagination: true,
                                  showPagination: true,
                                  pageSize: this.state.pageSize,
                                  manipulatePageNumberRepresentation: true
                              }}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "name",
                                  sortDescending: false
                              }}
                              filterOptions={{ externalFilter: false }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}
