import React, {RefObject} from "react";
import {ElementLayout, ResizeInfo, WDElementContainer} from "../WDElementContainer";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import Const from "../../../Framework/Const";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../Framework/CategoryImage";
import {WDTextbox, WDTextboxData} from "../Textbox/WDTextbox";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import WDBalloonPolygon from "./WDBalloonPolygon";
import {RESIZE_NODE} from "../Enum/WDElementContainerResizeNode";
import _ from "lodash";
import {WDUtils} from "../../Utils/WDUtils";
import {Coords} from "../../../Framework/Coords";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {WDHistoryAction} from "../../History/Enum/WDHistoryAction";
import {WDElementHistoryItem} from "../../History/WDElementHistoryItem";
import {VerticalAlignment} from "../../../Framework/Enums";
import {MainContext} from "../../../_base/MainContext";
import Converter from "../../../Framework/Converter";
import {WDActionLogCategory, WDActionLogEntryDetails, WDActionLogType} from "../../ActionLog/WDActionLogEntry";

export class PeakThinkData {
    bigCoord: Coords
    bigSize: number
    middleCoords: Coords
    middleSize: number
    smallCoords: Coords
    smallSize: number
    borderWidth: number

    constructor(
        bigCoord: Coords,
        bigSize: number,
        middleCoords: Coords,
        middleSize: number,
        smallCoords: Coords,
        smallSize: number,
        borderWidth: number
    ) {
        this.bigCoord = bigCoord
        this.bigSize = bigSize
        this.middleCoords = middleCoords
        this.middleSize = middleSize
        this.smallCoords = smallCoords
        this.smallSize = smallSize
        this.borderWidth = borderWidth
    }
}

export class WDBalloonData extends WDElementBaseData {
    id?: number
    data: WDTextboxData
    text: string
    image: string
    peakX: number
    peakY: number
    paddingTop: number

    constructor(
        text: string,
        data: WDTextboxData,
        image: string,
        peakX: number,
        peakY: number,
        paddingTop: number) {

        super()
        this.text = text
        this.data = data
        this.image = image
        this.peakX = peakX
        this.peakY = peakY
        this.paddingTop = paddingTop
    }

    static serialize(data: WDBalloonData): string {
        return JSON.stringify(data, (key, value) => {
            if (key === "url" || key === "width" || key === "height") return undefined;
            else return value;
        })
    }
}

interface WDBalloonProps extends WDElementBaseProps {
    data: WDBalloonData
    hasResizeOnCreate: boolean
    style?: React.CSSProperties
    className?: string
    borderColor: string
}

interface WDBalloonState extends WDElementBaseState {
    polygonClass: string

    textboxRef: RefObject<WDTextbox>
}

export class WDBalloon extends WDElementBase<WDBalloonProps, WDBalloonState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    resizeNodes: ResizeInfo =
        new ResizeInfo(true, true, true, true, true, true, true, true,
            180, Const.MaxElementSize, 100, Const.MaxElementSize)

    peakGrabberHistory: boolean = false
    click: Coords = new Coords(0, 0)
    creating: boolean = false
    imageCategories: ImageCategory[] = [ImageCategory.BALLOON, ImageCategory.BALLOON_THINK, ImageCategory.BALLOON_COMIC]

    constructor(props: WDBalloonProps) {
        super(props);

        this.creating = this.props.hasResizeOnCreate

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            polygonClass: (this.props.data && this.props.data.image) ?
                WDBalloonPolygon.getWrapperClassForImage(this.props.data.image) :
                WDBalloonPolygon.getWrapperClassForImage("SPEAK01"),
            elementRef: React.createRef(),
            textboxRef: React.createRef()
        }
    }

    static getDefaultWidth = () => {
        return 360
    }
    static getDefaultHeight = () => {
        return 190
    }

    componentDidMount() {

    }

    shouldComponentUpdate(nextProps: Readonly<WDBalloonProps>, nextState: Readonly<WDBalloonState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getDefaultContent = (): string => {
        return JSON.stringify(WDBalloon.getDefaultContentData())
    }
    static getDefaultContentData = (): WDBalloonData => {
        return new WDBalloonData("", WDTextboxData.defaultContent(),
            "SPEAK01",
            WDBalloon.getDefaultWidth() * 0.8,
            WDBalloon.getDefaultHeight() * 0.8,
            WDBalloon.getDefaultHeight() * 0.5 - Converter.ptToPx(Const.FONT_SIZE) * 0.5)
    }

    getPeakGrabberDistance = (container: ElementLayout) => {
        let centerX = (container.width * 0.5)
        let centerY = (container.height * 0.5)

        let minDistance = Math.min(container.width * 0.4, container.height * 0.4)
        let distance = Math.sqrt(Math.pow(centerX - this.props.data.peakX, 2) + Math.pow(centerY - this.props.data.peakY, 2))
        if (distance < minDistance) {
            distance = minDistance
        }
        if (distance > Math.max(container.height * 0.6, container.width * 0.6)) {
            distance = Math.max(container.height * 0.6, container.width * 0.6)
        }
        return distance
    }
    recalculatePeakGrabberPosition = (data: WDBalloonData, container: ElementLayout, width: number, height: number) => {
        // When creating, position the peak grabber in bottom right corner (80% / 80%)
        // Otherwise calculate the position as percentage and reposition accordingly
        const posX = this.creating ? 0.8 : this.props.data.peakX / container.width
        const posY = this.creating ? 0.8 : this.props.data.peakY / container.height

        data.peakX = width * posX
        data.peakY = height * posY
        data.paddingTop = this.getPaddingTop()
    }
    getPeakThinkPosition = () => {
        // Calculate size ratio for further usage
        let maxSizeBalloon = this.resizeNodes.maxHeight
        let minSizeBalloon = this.resizeNodes.minHeight

        let currentSize = Math.max(this.props.element.height, this.props.element.width)
        let sizeRatio = (currentSize - minSizeBalloon) / (maxSizeBalloon - minSizeBalloon)

        // Calculate border width (maxBorderWidth - minBorderWidth) * ratio
        let borderWidth = 10 * sizeRatio + 1

        // Calculate bubble sizes (maxSize - minSize) * ratio
        let sizeBig = 120 * sizeRatio + 12
        let sizeMiddle = 100 * sizeRatio + 10
        let sizeSmall = 80 * sizeRatio + 8

        // Calculate position
        let fromX = this.props.element.width * 0.5
        let fromY = this.props.element.height * 0.5

        let maxDistance = Math.max(this.props.element.height * 0.6, this.props.element.width * 0.6)
        let defaultDistance = Math.sqrt(Math.pow(fromX - this.props.data.peakX, 2) + Math.pow(fromY - this.props.data.peakY, 2))
        let distanceRatio = Math.min(defaultDistance / maxDistance, 1)

        let distanceThinkBig = Math.min(defaultDistance, Math.max(this.props.element.height * 0.2, this.props.element.width * 0.2) * distanceRatio)
        let distanceThinkMiddle = Math.min(defaultDistance, Math.max(this.props.element.height * 0.1, this.props.element.width * 0.1) * distanceRatio)
        let distanceThinkSmall = Math.min(defaultDistance, Math.max(this.props.element.height * 0.01, this.props.element.width * 0.01) * distanceRatio)

        // get distance ratio
        let tBig = distanceThinkBig / defaultDistance
        let tMiddle = distanceThinkMiddle / defaultDistance
        let tSmall = distanceThinkSmall / defaultDistance

        // calculate point on line
        return new PeakThinkData(
            new Coords(
                (1 - tBig) * this.props.data.peakX + tBig * fromX,
                (1 - tBig) * this.props.data.peakY + tBig * fromY
            ),
            sizeBig,
            new Coords(
                (1 - tMiddle) * this.props.data.peakX + tMiddle * fromX,
                (1 - tMiddle) * this.props.data.peakY + tMiddle * fromY
            ),
            sizeMiddle,
            new Coords(
                (1 - tSmall) * this.props.data.peakX + tSmall * fromX,
                (1 - tSmall) * this.props.data.peakY + tSmall * fromY
            ),
            sizeSmall,
            borderWidth
        )
    }

    onResizeElement = async (proportional: boolean, x: number, y: number) => {
        this.state.textboxRef.current?.setTextOverflow()

        let newData = {...this.props.data}
        this.recalculatePeakGrabberPosition(
            newData,
            ElementLayout.createFromWorksheetItem(this.props.element),
            this.props.element.width, this.props.element.height
        )
        this.props.onUpdateElement(
            new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)}),
            {actionCategory: WDActionLogCategory.content}
        )

        this.props.onElementResize?.(!proportional, x, y)
    }

    // Overridden methods
    getDataFromTextUpdate = (update: WorksheetItemUpdate) => {
        let itemUpdate = new WorksheetItemUpdate(this.props.id, {})

        let newData = {...this.props.data}

        if (update.value.height) {
            itemUpdate.value.height = update.value.height

            this.recalculatePeakGrabberPosition(
                newData,
                ElementLayout.createFromWorksheetItem(this.props.element),
                this.props.element.width, update.value.height
            )
        }
        if (update.value.content) {
            newData.data = JSON.parse(update.value.content)
        }

        itemUpdate.value.content = this.serializeElementData(newData)

        return itemUpdate
    }
    pushHistory = (before: WorksheetItemUpdate[], after: WorksheetItemUpdate[]) => {
        this.props.pushHistory([this.getDataFromTextUpdate(before[0])], [this.getDataFromTextUpdate(after[0])])
    }
    updateHistory = (value: WDElementHistoryItem) => {
        this.props.updateHistory(new WDElementHistoryItem([this.getDataFromTextUpdate(value.updates[0])]))
    }

    updateTextData = async (update: WorksheetItemUpdate) => {
        let itemUpdate = this.getDataFromTextUpdate(update)
        this.props.onUpdateElement(itemUpdate,
            {
                historyAction: update.value.height ? WDHistoryAction.RESIZE : undefined,
                actionCategory: WDActionLogCategory.content
            })
    }
    onEditElement = async (editMode: boolean) => {
        await this.state.textboxRef.current?.onEditElement(editMode)

        if (!editMode) {
            this.state.elementRef.current?.onStopEdit()
        }

        this.props.onElementEdit?.(this.props.id, editMode)
    }
    getNameConfigInstances = (): number[] => {
        if (this.state.textboxRef.current) {
            return this.state.textboxRef.current.getNameConfigInstances()
        } else {
            return []
        }
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDBalloonData.serialize(data as WDBalloonData)
    }

    setResizeState = (state: boolean, nodeType?: RESIZE_NODE) => {
        // Stop creation mode when resize state changes first time
        this.state.elementRef.current?.setResizeState(state, nodeType)

        if (!state) {
            this.creating = false
        }

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(this.props.data),
            width: this.props.element.width,
            height: this.props.element.height,
            resized: state
        })
    }
    setLoaded = () => {
        setTimeout(() => this.state.textboxRef.current?.setTextOverflow(), 500)
    }

    resizeElement = (proportional: boolean, x: number, y: number, nodeType?: RESIZE_NODE) => {
        // Tell embedded WSDesignerElementContainer to resize
        let update = this.state.elementRef.current?.resizeElement(proportional, x, y, nodeType)
        if (update) {
            let newData = {...this.props.data}
            this.recalculatePeakGrabberPosition(
                newData,
                ElementLayout.createFromWorksheetItem(this.props.element),
                update.value.width!, update.value.height!
            )

            update.value.content = this.serializeElementData(newData)
        }

        return update
    }

    doAction = (action: WDToolbarAction, data?: any) => {
        let newData = {...this.props.data}
        let update = new WorksheetItemUpdate(this.props.id, {})

        switch (action) {
            case WDToolbarAction.CHANGE_GRAPHIC:
                if (data && data["image"]) {
                    newData.image = data["image"]
                    this.setState({polygonClass: WDBalloonPolygon.getWrapperClassForImage(newData.image)})
                }
                break

            case WDToolbarAction.CONVERT_TO_SYLLABLE:
                if (data && data["syllableActivated"] !== undefined) {
                    this.state.textboxRef.current?.textToSyllableText(data["syllableActivated"]).then(
                        (textboxData) => {
                            newData.data.text = WDTextboxData.serialize(textboxData)
                            newData.data = textboxData

                            update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
                            this.props.onUpdateElement(update,
                                {
                                    historyAction: WDHistoryAction.CONTENT_CHANGED,
                                    actionCategory: WDActionLogCategory.syllable
                                })
                        }
                    )
                }
                break

            default:
                let u = this.state.textboxRef.current?.doAction(action, data)
                if (u && u.value.content) {
                    let o = JSON.parse(u.value.content) as WDTextboxData
                    Object.keys(o).forEach(key => {
                        newData.data[key] = o[key]
                    })
                }
        }

        if (action !== WDToolbarAction.CONVERT_TO_SYLLABLE) {
            update.value.content = this.serializeElementData(newData)
        }

        return update
    }

    onMouseDownPeakGrabber = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        this.click = WDUtils.convertCoordsToAnchorObjectCoordinates(this.props.id, new Coords(e.clientX, e.clientY))
        this.peakGrabberHistory = false

        document.addEventListener("mousemove", this.onMouseMovePeakGrabber)
        document.addEventListener("mouseup", this.onMouseUpPeakGrabber)
    }
    onMouseMovePeakGrabber = async (e: MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        let pos = WDUtils.convertCoordsToAnchorObjectCoordinates(this.props.id, new Coords(e.clientX, e.clientY))

        let offsetX = this.click.x - pos.x
        let offsetY = this.click.y - pos.y

        this.click = new Coords(
            (this.click.x - offsetX) / this.context.getZoom(),
            (this.click.y - offsetY) / this.context.getZoom()
        )

        let fromX = this.props.element.posX + (this.props.element.width * 0.5)
        let fromY = this.props.element.posY + (this.props.element.height * 0.5)

        let distance = Math.sqrt(Math.pow(fromX - this.click.x, 2) + Math.pow(fromY - this.click.y, 2))
        distance = Math.min(distance, Math.max(this.props.element.height * 0.6, this.props.element.width * 0.6))

        let x: number, y: number

        //if line is vertical
        if (fromX === this.click.x) {
            x = this.click.x
            y = this.click.y > fromY ? fromY + distance : fromY - distance
        }
        //if line is horizontal
        else if (fromY === this.click.y) {
            x = this.click.x > fromX ? fromX + distance : fromX - distance
            y = this.click.y

        } else {
            //get each side of original triangle length
            let adjacent = this.click.y - fromY
            let opposite = this.click.x - fromX

            //find the angle
            let angle = Math.atan(opposite / -adjacent)
            if (fromY - this.click.y < 0) {
                angle += Math.PI
            }
            // Make angle positive
            if (angle < 0) {
                angle += (2 * Math.PI)
            }

            //calculate new opposite and adjacent sides
            let newOpposite = Math.sin(angle) * distance
            let newAdjacent = Math.cos(angle) * distance

            //calculate new x/y, see which direction it's going
            x = fromX + newOpposite
            y = fromY - newAdjacent
        }

        let newData = {...this.props.data}
        newData.peakX = x - this.props.element.posX - 4
        newData.peakY = y - this.props.element.posY - 4

        let update = new WorksheetItemUpdate(
            this.props.id, {content: this.serializeElementData(newData)})

        // Create or update history entry
        if (!this.peakGrabberHistory) {
            this.props.pushHistory(
                [new WorksheetItemUpdate(this.props.id, {
                    content: this.serializeElementData(this.props.data)
                })],
                [update]
            )
            this.peakGrabberHistory = true
        }
        else {
            this.props.updateHistory(new WDElementHistoryItem([update]))
        }

        await this.props.onUpdateElement(update)
    }
    onMouseUpPeakGrabber = () => {
        document.removeEventListener("mousemove", this.onMouseMovePeakGrabber)
        document.removeEventListener("mouseup", this.onMouseUpPeakGrabber)

        this.context.addWDAction(WDActionLogType.Info, WDActionLogCategory.content,
            [new WDActionLogEntryDetails(this.props.element.itemKey, this.props.element.worksheetItemTypeId, undefined,
                {peakX: this.props.data.peakX, peakY: this.props.data.peakY})])
    }

    getPaddingTop = () => {
        const text = document.getElementById("txt-balloon-txt-" + this.props.id)
        if (text) {
            // Set height to "auto" and get height of text, set height back then
            let padding = +text.style.paddingTop.replace("px", "")
            let height = text.style.height
            text.style.height = "auto"
            let textHeight = text.scrollHeight - padding
            text.style.height = height

            // Padding top is half the bounding rect - half of text height
            return (this.props.element.height * 0.5 - textHeight * 0.5)
        }

        return 0
    }

    buildPeakTransform = () => {
        let centerX = (this.props.element.width * 0.5)
        let centerY = (this.props.element.height * 0.5)

        // Calculate angle based on offset between peak grabber and center of balloon in degree
        let angle = Math.atan((this.props.data.peakX - centerX) / -(this.props.data.peakY - centerY)) * 180 / Math.PI
        // Add 180 degree if peak grabber is in upper half (0 - 180°)
        if (this.props.data.peakY - centerY < 0) {
            angle += 180
        }
        // Make angle positive
        if (angle < 0) {
            angle += 360
        }

        // Calculate distance of peak grabber to center
        let distance = this.getPeakGrabberDistance(ElementLayout.createFromWorksheetItem(this.props.element))

        let translateX = distance * 0.3
        let translateY = distance * 0.5

        // Move by half container width / height - half width / height of peak
        let offsetX = centerX - translateX / 1.5
        let offsetY = centerY - translateY

        return {
            transform: "translateX(" + offsetX + "px) translateY(" + offsetY + "px) rotate(" + angle + "deg) translateY(" + translateY + "px)",
            width: translateX * 1.5,
            height: (translateY * 2)
        }
    }
    isThinkBubble = () => {
        return this.props.data.image && this.props.data.image.toLowerCase().includes("think")
    }

    getAdditionalToolbarData = () => {
        return JSON.stringify({
            textData: this.props.data.data.text
        })
    }

    render() {
        if (this.props.element.deleted) {
            return <></>
        }

        let imageUrl = CategoryImageValue.getImageByKey(this.imageCategories, this.props.data.image || "SPEAK01")
        let isComic = imageUrl.toLowerCase().includes("comic")
        let isThink = this.isThinkBubble()

        const peakTransform = this.buildPeakTransform()
        let thinkPeak: PeakThinkData | undefined = undefined

        if (isThink) {
            thinkPeak = this.getPeakThinkPosition()
        }

        let contouringImageUrl = imageUrl.replace("-thumb", "")
        let fillingImageUrl = imageUrl.replace("-thumb", "-color")
        let peakImageUrl = imageUrl.replace("-thumb", "-peak")
        let peakColorImageUrl = imageUrl.replace("-thumb", "-peak-color")

        let balloonColor: string = ""
        if (this.props.element.fillColor !== "transparent") {
            balloonColor = "svg-color-" + this.props.element.fillColor.toUpperCase().replace("#", "")
        }
        let borderColor = "svg-color-" + this.props.element.borderColor.toUpperCase().replace("#", "")

        return <WDElementContainer id={this.props.id}
                                   element={this.props.element}
                                   hasResizeOnCreate={this.props.hasResizeOnCreate}
                                   onEdit={this.onEditElement}
                                   resizeInfo={this.resizeNodes}
                                   renderWrapper={true}
                                   renderBackgroundColor={false}
                                   renderBorder={false}
                                   onUnlockElement={this.unlockElement}
                                   onResizeStateChanged={this.props.onResizeStateChanged}
                                   onResizeElement={this.onResizeElement}
                                   isEditModeAllowed={this.isEditModeAllowed}
                                   isReadOnly={this.props.isReadOnly}
                                   onContextMenu={this.props.onContextMenu}
                                   ref={this.state.elementRef}
        >

            <div className={"ws-designer-balloon print"}>
                {/* Layer 1: balloon peak */}
                {!isComic && !isThink &&
                    <img className={"ws-designer-balloon-image " + borderColor}
                         style={{...peakTransform, ...{zIndex: 6}}}
                         src={process.env.PUBLIC_URL + ImagePath.getBalloonUrl() + peakImageUrl} alt={""}
                    />
                }

                {/* Layer 2: balloon filling */}
                <img className={"ws-designer-balloon-image " + balloonColor}
                     src={process.env.PUBLIC_URL + ImagePath.getBalloonUrl() + fillingImageUrl} alt={""}
                     style={{zIndex: 7}}
                />

                {/* Layer 3: balloon contouring*/}
                <img className={"ws-designer-balloon-image " + borderColor}
                     src={process.env.PUBLIC_URL + ImagePath.getBalloonUrl() + contouringImageUrl} alt={""}
                     style={{zIndex: 8}}
                />

                {/* Layer 4: balloon peak filling */}
                {!isComic && !isThink &&
                    <img className={"ws-designer-balloon-image " + balloonColor}
                         style={{...peakTransform, ...{zIndex: 9}}}
                         src={process.env.PUBLIC_URL + ImagePath.getBalloonUrl() + peakColorImageUrl} alt={""}
                    />
                }

                {/* Peak grabber */}
                {!isComic && this.props.element.selected &&
                    <div className={"ws-designer-balloon-peak-grabber"}
                         style={{zIndex: 20, left: this.props.data.peakX, top: this.props.data.peakY}}
                         onMouseDown={this.onMouseDownPeakGrabber}
                    />
                }
                {/* Think peak */}
                {isThink && thinkPeak &&
                    <>
                        <div className={"ws-designer-balloon-peak-think-big"}
                             style={{
                                 zIndex: 6,
                                 left: thinkPeak.bigCoord.x,
                                 top: thinkPeak.bigCoord.y,
                                 borderWidth: thinkPeak.borderWidth,
                                 width: thinkPeak.bigSize,
                                 height: thinkPeak.bigSize / 3 * 2,
                                 borderColor: this.props.element.borderColor === "transparent" ? "black" : this.props.element.borderColor,
                                 backgroundColor: this.props.element.fillColor
                             }}
                        />
                        <div className={"ws-designer-balloon-peak-think-medium"}
                             style={{
                                 zIndex: 6,
                                 left: thinkPeak.middleCoords.x,
                                 top: thinkPeak.middleCoords.y,
                                 borderWidth: thinkPeak.borderWidth,
                                 width: thinkPeak.middleSize,
                                 height: thinkPeak.middleSize / 3 * 2,
                                 borderColor: this.props.element.borderColor === "transparent" ? "black" : this.props.element.borderColor,
                                 backgroundColor: this.props.element.fillColor
                             }}
                        />
                        <div className={"ws-designer-balloon-peak-think-small"}
                             style={{
                                 zIndex: 6,
                                 left: thinkPeak.smallCoords.x,
                                 top: thinkPeak.smallCoords.y,
                                 borderWidth: thinkPeak.borderWidth,
                                 width: thinkPeak.smallSize,
                                 height: thinkPeak.smallSize / 3 * 2,
                                 borderColor: this.props.element.borderColor === "transparent" ? "black" : this.props.element.borderColor,
                                 backgroundColor: this.props.element.fillColor
                             }}
                        />
                    </>
                }

                <div className={"ws-designer-balloon-text"}>
                    <span className={this.state.polygonClass}/>

                    {/* Text area overlay */}
                    <WDTextbox
                        id={"balloon-txt-" + this.props.id}
                        element={this.props.element}
                        style={{paddingTop: this.props.data.paddingTop + "px"}}
                        className={"ws-designer-balloon-textbox"}
                        isIndependentElement={false}
                        fireUpdateOnBlur={true}
                        hasSpellCheck={true}
                        hasResizeOnCreate={false}
                        isReadOnly={this.props.isReadOnly}
                        resizeOptions={{showResizer: true, showError: true}}
                        showNonPrintableObjects={this.state.showNonPrintableObjects}
                        onShowAttentionInForeground={this.props.onShowAttentionInForeground}
                        onUpdateElement={(update) => this.updateTextData(update)}
                        solutionForceMode={this.props.solutionForceMode}
                        inPresentationMode={this.props.inPresentationMode}
                        data={this.props.data.data === undefined ? new WDTextboxData(this.props.data.text, false, false, VerticalAlignment.top) : this.props.data.data}
                        updateHistory={this.updateHistory}
                        pushHistory={this.pushHistory}
                        context={this.props.context}
                        ref={this.state.textboxRef}
                    />

                </div>
            </div>

        </WDElementContainer>
    }
}
