import React, {FormEvent} from "react";
import translations from "../../../Framework/translations.json";
import {MainContext} from "../../../_base/MainContext";
import Conf from "../../../Framework/Conf";
import {SidebarFilterBase} from "../SidebarFilterBase";
import {
    SidebarContentBaseProps,
} from "../SidebarContentBase";
import {SidebarFilterButtonData} from "../SidebarFilterButtonData";
import Const from "../../../Framework/Const";
import {TooltipPosition} from "../../../Components/Tooltips";
import {CreateSupportTicket} from "../../../_endpoint/SupportEndpoint";
import {Worksheet} from "../../../_model/Worksheet";
import TextBox from "../../../Components/Controls/TextBox";
import {ButtonInfo, ButtonList} from "../../../Components/Controls/ButtonList";

interface IProps extends SidebarContentBaseProps {
    worksheet: Worksheet
    sendErrorReport: boolean
    description?: string

    onErrorReportSent?: () => void
}

interface IState {
    sendErrorReport: boolean
    errorReportSending: boolean
    errorReportSuccess: boolean
    description?: string
}

export class SidebarHelp extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            sendErrorReport: this.props.sendErrorReport,
            description: this.props.description,
            errorReportSending: false,
            errorReportSuccess: false,
        }
    }

    onClickNavigateToOdoo = (e: MouseEvent, url: string) => {
        e.stopPropagation()
        e.preventDefault()

        window.open(url, '_blank');
    }

    onClickGetInContact = (object: string) => {
        window.location.href = 'mailto:' + Const.SUPPORT_EMAIL + '?subject=' + this.context.translate(translations.text_fragment.report) + '%20' + object + '&body=' + object + ':%20'
            + this.context.translate(translations.notification.report_help_support) + ':%0D%0A'
    }
    onClickToggleErrorReport = (errorReportState: boolean) => {
        if (!errorReportState && this.props.onErrorReportSent) {
            this.props.onErrorReportSent()
        }

        this.setState({
            sendErrorReport: errorReportState,
            errorReportSuccess: false,
            description: ""
        })
    }
    onClickSendErrorReport = async (e: FormEvent) => {
        e.preventDefault()

        this.setState({errorReportSending: true}, async () => {
            await CreateSupportTicket(this.props.worksheet.id, this.state.description || "", this.context.getWDActionLog())

            this.context.initWDActionLog()

            this.setState({errorReportSuccess: true, errorReportSending: false})
        })
        return false
    }

    renderErrorReportForm = () => {
        if (this.state.errorReportSuccess) {
            return <div className={"ws-designer-error-report-success"}>
                {this.context.translate(translations.fields.error_report.sent).split(/\n/).map((line, i) => <div key={i}>{line}&nbsp;</div>)}

                <div className={"ws-designer-error-report-success-button"}>
                    <button id={"btnBack"}
                            className={"button button-cancel"} type={"button"}
                            onClick={() => this.onClickToggleErrorReport(false)}>
                        {this.context.translate(translations.command.back)}
                    </button>
                </div>
            </div>
        } else {
            return <form id={"formErrorReport"} onSubmit={this.onClickSendErrorReport}>
                <TextBox id={"txtDescription"}
                         width={400}
                         className={"form-textarea-big"}
                         label={this.context.translate(translations.fields.error_report.description)}
                         labelClass={"label"}
                         style={{margin: "0 0 10px 0"}}
                         required={true}
                         multiline={true}
                         rows={6}
                         readonly={false}
                         value={this.state.description || ""}
                         onChange={(value) => this.setState({description: value})}
                />

                <ButtonList
                    containerStyle={{width: "auto"}}
                    buttons={[
                        new ButtonInfo("btnCancel",
                            "button button-cancel",
                            "button",
                            false,
                            this.state.errorReportSending,
                            this.context.translate(translations.command.cancel),
                            () => this.onClickToggleErrorReport(false),
                            {}),
                        new ButtonInfo("btnSend",
                            "button button-save",
                            "submit",
                            true,
                            false,
                            this.context.translate(translations.command.send),
                            () => {
                            },
                            {marginLeft: "10px"})
                    ]}
                    loading={this.state.errorReportSending}
                />
            </form>
        }
    }
    renderHelp = () => {
        return <>
            <div>
                {this.context.translate(translations.text.help.help_question)}
            </div>

            <div className={"form-group"} style={{marginLeft: 0, paddingLeft: 0}}>
                <div
                    className={"label-bold"}> {this.context.translate(translations.text.help.documented_help) + '\n'} </div>
                <div> {this.context.translate(translations.text.help.find_answers)} </div>

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div className={"link"}
                                 onClick={(event) => this.onClickNavigateToOdoo(event.nativeEvent, Conf.WEBSITE_URL() + "/online-bibliotek")}>
                                {this.context.translate(translations.text.help.channel_documentation)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className={"link"}
                                 onClick={(event) => this.onClickNavigateToOdoo(event.nativeEvent, Conf.WEBSITE_URL() + "/forum")}>
                                {this.context.translate(translations.text.help.channel_faq)}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={"form-group"} style={{marginLeft: 0, paddingLeft: 0}}>
                <div
                    className={"label-bold"}> {this.context.translate(translations.text.help.individual_help) + '\n'} </div>
                <div> {this.context.translate(translations.text.help.contact_us_support)} </div>

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div className={"link"}
                                 onClick={() => this.onClickToggleErrorReport(true)}>
                                {this.context.translate(translations.text.help.channel_self_service)}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={"form-group"} style={{marginLeft: 0, paddingLeft: 0}}>
                {this.context.translate(translations.text.help.contact_us_other)}

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <span>{this.context.translate(translations.text.help.channel_telephone) + ": "}</span>
                        </td>
                        <td>
                            <a className={"link"}
                               href={"tel: " + Const.TELEPHONE}>{Const.TELEPHONE_STRING}</a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>{this.context.translate(translations.text.help.channel_email) + ": "}</span>
                        </td>
                        <td>
                            <a className={"link"} href={"mailto:" + Const.SUPPORT_EMAIL}>
                                {Const.SUPPORT_EMAIL}
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={"form-group"} style={{marginLeft: 0, paddingLeft: 0}}>
                {this.context.translate(translations.text.help.available_opening_times)}

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <span>{this.context.translate(translations.text.help.opening_times.monday) + ":"}</span>
                        </td>
                        <td>
                            <span>{"8:00 - 17:00 Uhr"}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>{this.context.translate(translations.text.help.opening_times.tuesday) + ":"}</span>
                        </td>
                        <td>
                            <span>{"8:00 - 17:00 Uhr"}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>{this.context.translate(translations.text.help.opening_times.wednesday) + ":"}</span>
                        </td>
                        <td>
                            <span>{"8:00 - 17:00 Uhr"}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>{this.context.translate(translations.text.help.opening_times.thursday) + ":"}</span>
                        </td>
                        <td>
                            <span>{"8:00 - 17:00 Uhr"}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>{this.context.translate(translations.text.help.opening_times.friday) + ":"}</span>
                        </td>
                        <td>
                            <span>{"8:00 - 12:00 Uhr"}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>{this.context.translate(translations.text.help.opening_times.weekend) + ":"}</span>
                        </td>
                        <td>
                            <span>{this.context.translate(translations.text.help.opening_times.closed)}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    }

    render() {
        let buttons: SidebarFilterButtonData[] = []

        if (this.state.sendErrorReport) {
            buttons.push(
                new SidebarFilterButtonData(1,
                    this.context.translate(translations.command.back),
                    this.context.translate(translations.tooltip.back),
                    "navigation_backward.svg",
                    false,
                    () => this.onClickToggleErrorReport(false),
                    TooltipPosition.belowRight, -138, -5))
        } else {
            buttons.push(
                new SidebarFilterButtonData(2,
                    this.context.translate(translations.command.get_in_contact),
                    this.context.translate(translations.tooltip.get_in_contact),
                    "mail.svg",
                    false,
                    () => this.onClickGetInContact(this.context.translate(translations.text.help_support)),
                    TooltipPosition.belowRight, -138, -5))
            buttons.push(
                new SidebarFilterButtonData(3,
                    this.context.translate(translations.command.send_error),
                    this.context.translate(translations.tooltip.send_error),
                    "send_bug.svg",
                    false,
                    () => this.onClickToggleErrorReport(true),
                    TooltipPosition.belowRight, -138, -5))
        }

        return <div className={"ws-designer-sidebar-container"}>
            {/* Search and navigation */}
            <SidebarFilterBase buttons={buttons}/>

            <div className={"ws-designer-sidebar-content"} style={{overflowY: "hidden"}}>
                <div className={"ws-designer-sidebar-content-form"}>
                    <div className={"ws-designer-sidebar-title label-primary"}>
                        {this.context.translate(translations.text.help_support)}
                    </div>

                    {this.state.sendErrorReport ?
                        this.renderErrorReportForm()
                        :
                        this.renderHelp()
                    }
                </div>
            </div>
        </div>
    }
}
