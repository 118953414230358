import React, {CSSProperties} from "react";
import {ResizeInfo, WDElementContainer} from "../../WDElementContainer";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../../WDElementBase";
import Const from "../../../../Framework/Const";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../../Framework/CategoryImage";
import {WDToolbarAction} from "../../../Toolbar/WDToolbarAction";
import {MainContext} from "../../../../_base/MainContext";
import {WDMathLineatureData, WDMathLineatureDataCell, WDMathLineatureLineType} from "./WDMathLineatureData";
import {BorderType} from "../../../Toolbar/Button/Lineature/WDToolbarButtonCellBorder";
import {Util} from "../../../../Framework/Util";
import {Coords} from "../../../../Framework/Coords";
import {WDDataCellBorder} from "../../General/WDDataCellBorder";
import {RESIZE_NODE} from "../../Enum/WDElementContainerResizeNode";
import {BracketPosition} from "../../../Toolbar/Button/Math/WDToolbarButtonReckonBracket";
import {GlyphData, WDMathLineaturePositioning} from "../../../../Framework/GlyphData";
import Converter from "../../../../Framework/Converter";
import _ from 'lodash';
import {WorksheetItemUpdate} from "../../../Utils/WorksheetItemUpdate";
import {LogLevel} from "../../../../Framework/Log";
import {ManualBorderMode, RenderingMedia, SolutionForceMode, WDTableDimensionMode} from "../../../../Framework/Enums";
import {BorderUtils} from "../../../Utils/BorderUtils";
import {WDActionLogCategory} from "../../../ActionLog/WDActionLogEntry";
import {ToolboxElementValuesTable} from "../../Table/WDTable";

interface WSDesignerMathLineatureProps extends WDElementBaseProps {
    data: WDMathLineatureData
    isIndependentElement: boolean
    renderingMedia: RenderingMedia

    onFocus?: () => void
}

interface WSDesignerMathLineatureState extends WDElementBaseState {
    selectedCells: Coords[]
    borderMode: ManualBorderMode
    borderColor: string
    operatorMode: boolean

    bold: boolean
    italic: boolean
    color: string
}

export class WDMathLineature extends WDElementBase<WSDesignerMathLineatureProps, WSDesignerMathLineatureState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    clickX: number = 0
    clickY: number = 0
    borderStack: BorderUtils[] = []

    // Selector mode (row or column)
    selectorElementMode?: WDTableDimensionMode = undefined
    selectorStartIndex?: number

    constructor(props: WSDesignerMathLineatureProps) {
        super(props);

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            borderMode: ManualBorderMode.Off,
            borderColor: "",
            operatorMode: true,
            bold: false,
            italic: false,
            color: "#000000",
            selectedCells: [],
            elementRef: React.createRef()
        }
    }

    componentDidMount() {
        let update = new WorksheetItemUpdate(this.props.id, {})
        let width = this.getTotalWidth()
        let height = this.getTotalHeight()

        if (this.props.element.width !== width) {
            update.value.width = width
        }
        if (this.props.element.height !== height) {
            update.value.height = height
        }

        if (Object.keys(update.value).length > 0) {
            this.props.onUpdateElement(update)
        }
    }
    componentDidUpdate(prevProps: Readonly<WSDesignerMathLineatureProps>) {
        if (this.props.element.deleted !== prevProps.element.deleted) {
            this.setElementDeleted()
        }
    }
    shouldComponentUpdate(nextProps: Readonly<WSDesignerMathLineatureProps>, nextState: Readonly<WSDesignerMathLineatureState>): boolean {
        // console.log("Item: " + this.props.id + " shouldComponentUpdate", this.props, nextProps)
        // console.log(this.getObjectDiff(this.props, nextProps))
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getImageNameByKey = (lineatureSize: string, jsonData: any) => {
        let imageKey = "M-AT-" + lineatureSize.toUpperCase() + "-LINE-THUMB"

        if (jsonData.image) {
            imageKey = jsonData.image
        }

        return WDMathLineature.replaceThumbImage(imageKey, lineatureSize)
    }

    static getDefaultWidth = (lineatureSize: string | undefined) => {
        let width = WDMathLineatureData.getCellWidth(lineatureSize)

        // Add default border width
        width += 1

        // Multiply by default columns
        return width * 10
    }
    static getDefaultHeight = (lineatureSize: string | undefined) => {
        let height = WDMathLineatureData.getCellHeight(lineatureSize)

        // Add default border height
        height += 1

        // Multiply by default rows
        return height * 2
    }
    static getToolboxEntryMathLineatureInfo = (configData: any, data: WDMathLineatureData) : ToolboxElementValuesTable | undefined => {
        return new ToolboxElementValuesTable(
            (Converter.mmToPx(data.cellWidth) + data.borderWidth) * data.cols * configData.numberOfElementsWidth + 2 * Const.ELEMENT_PADDING * configData.numberOfElementsWidth,
            (Converter.mmToPx(data.cellHeight) + data.borderWidth) * data.rows * configData.numberOfElementsHeight + 2 * Const.ELEMENT_PADDING * configData.numberOfElementsHeight,
            configData.numberOfElementsHeight,
            configData.numberOfElementsWidth,
            data.rows,
            data.cols
        )
    }

    getMinWidth = () => {
        return this.getTotalCellWidth() + (2 * Const.ELEMENT_PADDING)
    }
    getMinHeight = () => {
        return this.getTotalCellHeight() + (2 * Const.ELEMENT_PADDING)
    }

    recalculateSize = (width: number, height: number, adjustSize: boolean): WorksheetItemUpdate => {
        let update = new WorksheetItemUpdate(this.props.id, {
            width: width, height: height
        })

        let newData = {...this.props.data}
        const cells = this.calculateCells(width, height)
        newData.cols = Math.max(1, cells.x)
        newData.rows = Math.max(1, cells.y)
        update.value.content = this.serializeElementData(newData)

        if (adjustSize) {
            update.value.width = this.getTotalWidth(newData)
            update.value.height = this.getTotalHeight(newData)
        }

        return update
    }

    doAction = (action: WDToolbarAction, data?: any) => {
        let newData = {...this.props.data}

        let update: WorksheetItemUpdate = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.ADD_ROW:
            case WDToolbarAction.ADD_ROW_BELOW:
            case WDToolbarAction.ADD_ROW_ABOVE:
                update = this.addRow(newData, action)
                break
            case WDToolbarAction.DELETE_ROW:
                update = this.deleteRow(newData)
                break

            case WDToolbarAction.ADD_COLUMN:
            case WDToolbarAction.ADD_COLUMN_RIGHT:
            case WDToolbarAction.ADD_COLUMN_LEFT:
                update = this.addColumn(newData, action)
                break
            case WDToolbarAction.DELETE_COLUMN:
                update = this.deleteColumn(newData)
                break

            case WDToolbarAction.CELL_SIZE:
                if (data && data["width"] !== undefined && data["height"] !== undefined) {
                    newData.cellWidth = data["width"]
                    newData.cellHeight = data["height"]

                    update = new WorksheetItemUpdate(this.props.id, {
                        width: this.getTotalWidth(newData),
                        height: this.getTotalHeight(newData)
                    })
                }
                break

            case WDToolbarAction.CHANGE_GRAPHIC:
                if (data && data["image"]) {
                    let imageKey: string = data["image"].toString()
                    newData.lineType = WDMathLineatureData.mapLineStyle(imageKey)
                }
                break

            case WDToolbarAction.LINE_COLOR:
                if (data && data["color"] !== undefined) {
                    newData.lineColor = data["color"]
                }
                break

            case WDToolbarAction.INSERT_ICON:
                if (data && data["icon"] !== undefined) {
                    let icon = data["icon"], color = data["color"]
                    if (this.state.selectedCells && this.state.selectedCells.length > 0) {
                        for (const item of this.state.selectedCells) {
                            this.setCellValue(newData, item.x, item.y, "", "", "", icon, color)
                        }

                        if (this.state.selectedCells.length === 1) {
                            this.moveRight()
                        }
                    }
                }
                break

            case WDToolbarAction.BOLD:
                if (this.state.selectedCells && this.state.selectedCells.length > 0) {
                    update = this.setCellDecoration("bold")
                }
                break

            case WDToolbarAction.ITALIC:
                if (this.state.selectedCells && this.state.selectedCells.length > 0) {
                    update = this.setCellDecoration("italic")
                }
                break

            case WDToolbarAction.FONT_COLOR:
                if (this.state.selectedCells && this.state.selectedCells.length > 0) {
                    update = this.setCellDecoration("color", data["color"])
                }
                break

            case WDToolbarAction.INSERT_GLYPH:
                if (data && data["code"] !== undefined) {
                    let glyph = data["code"]
                    let positioning = GlyphData.getGlyphPositionByCode(glyph)

                    if (this.state.selectedCells && this.state.selectedCells.length > 0) {
                        switch (positioning) {
                            case WDMathLineaturePositioning.middle:
                                update = this.fillInMiddleCell(glyph)
                                break

                            case WDMathLineaturePositioning.left:
                                update = this.fillInLeftCell(glyph)
                                break

                            case WDMathLineaturePositioning.right:
                                update = this.fillInRightCell(glyph)
                                break
                        }
                    }
                }
                break

            case WDToolbarAction.CELL_FILL_COLOR:
                if (data && data["color"] !== undefined) {
                    let color = data["color"]

                    // Set cell background color for selected cells or all cells
                    if (this.state.selectedCells && this.state.selectedCells.length > 0) {
                        for (const item of this.state.selectedCells) {
                            this.setCellBackground(newData.data, item.x, item.y, color)
                        }

                        if (this.state.selectedCells.length === 1) {
                            this.moveRight()
                        } else {
                            this.setState({selectedCells: []})
                        }
                    } else {
                        for (let i = 0; i < newData.rows; i++) {
                            for (let j = 0; j < newData.cols; j++) {
                                this.setCellBackground(newData.data, i, j, color)
                            }
                        }
                    }

                    update.value.content = this.serializeElementData(newData)
                }
                break

            case WDToolbarAction.CELL_BORDER_COLOR:
                if (data && data["type"] !== undefined) {
                    let type = data["type"].toLowerCase()

                    let borderMode = ManualBorderMode.Off
                    if (type === BorderType.manual && this.state.borderMode === ManualBorderMode.Off) {
                        borderMode = ManualBorderMode.Border
                    } else if (type === BorderType.erase && this.state.borderMode === ManualBorderMode.Off) {
                        borderMode = ManualBorderMode.Erase
                    }
                    this.toggleBorderMode(borderMode, data["color"])

                    update = this.setCellBorder(type, data["color"])
                    if (this.state.selectedCells.length === 1) {
                        this.moveRight()
                    }
                }
                break

            case WDToolbarAction.CELL_SUM:
                update = this.setCellBorder(BorderType.middle, data["color"])
                if (this.state.selectedCells.length === 1) {
                    this.moveRight()
                }
                break

            case WDToolbarAction.CELL_BORDER_MANUAL:
                let borderModeManual = ManualBorderMode.Border
                if (this.state.borderMode === ManualBorderMode.Border) {
                    borderModeManual = ManualBorderMode.Off
                } else {
                    this.onEditElement(true)
                    this.state.elementRef.current?.onEdit()
                }

                this.toggleBorderMode(borderModeManual, data["color"])
                break
            case WDToolbarAction.CELL_BORDER_MANUAL_COLOR:
                this.toggleBorderMode(this.state.borderMode, data["color"])
                break
            case WDToolbarAction.CELL_BORDER_ERASE:
                let borderModeErase = ManualBorderMode.Erase
                if (this.state.borderMode === ManualBorderMode.Erase) {
                    borderModeErase = ManualBorderMode.Off
                } else {
                    this.onEditElement(true)
                    this.state.elementRef.current?.onEdit()
                }

                this.toggleBorderMode(borderModeErase)
                break

            case WDToolbarAction.CELL_BORDER_DOUBLE:
                update = this.setCellBorder(BorderType.bottom_double, data["color"])
                if (this.state.selectedCells.length === 1) {
                    this.moveRight()
                }
                break

            case WDToolbarAction.RECKON_BRACKET:
                if (data && data["position"] !== undefined) {
                    if (this.state.selectedCells && this.state.selectedCells.length > 0) {
                        for (const item of this.state.selectedCells) {
                            this.setCellReckonBracket(newData.data, item.x, item.y, data["position"] as BracketPosition, data["color"])
                        }
                    }
                }
                break

            case WDToolbarAction.OPERATOR_MODE_ACTIVE:
                this.setState({operatorMode: true})
                break
            case WDToolbarAction.OPERATOR_MODE_INACTIVE:
                this.setState({operatorMode: false})
                break

            case WDToolbarAction.SOLUTION:
                if (this.state.selectedCells && this.state.selectedCells.length > 0) {
                    for (const item of this.state.selectedCells) {
                        this.setSolution(newData.data, item.x, item.y)
                    }

                    if (this.state.selectedCells.length === 1) {
                        this.moveRight()
                    } else {
                        this.setState({selectedCells: []})
                    }
                }
                break

            case WDToolbarAction.CHANGE_SOLUTION_SHOW:
                newData.showSolution = !newData.showSolution
                break
        }

        update.value.content = this.serializeElementData(newData)
        return update
    }

    /**
     * Replaces the thumb by the matching graphic
     * @param thumbImageKey to find thumb image svg
     * @param newLineatureSize is the size of the graphic; needed to replace thumb image size
     */
    static replaceThumbImage = (thumbImageKey: string, newLineatureSize: string) => {
        let category = ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY

        if (WDMathLineatureData.isUneven(newLineatureSize)) {
            category = ImageCategory.MATH_LINEATURE_THUMBNAIL_UNEVENLY
        }

        let thumbImage = CategoryImageValue.getImageByKey([category], thumbImageKey)
        return thumbImage.substring(0, thumbImage.lastIndexOf("-thumb.svg"))
    }

    calculateCells = (width: number, height: number): Coords => {
        return new Coords(
            Math.round((width - (2 * Const.ELEMENT_PADDING) - this.props.data.borderWidth) / this.getTotalCellWidth()),
            Math.round((height - (2 * Const.ELEMENT_PADDING) - this.props.data.borderWidth) / this.getTotalCellHeight() )
        )
    }
    unselectCells = () => {
        this.setState({selectedCells: [], borderMode: ManualBorderMode.Off})
    }

    getAdditionalToolbarData = () => {
        let firstCellData : WDMathLineatureDataCell | undefined = undefined

        if (this.props.data.data.length > 0
            && this.state.selectedCells.length > 0
            && this.props.data.data[this.state.selectedCells[0].x]
            && this.props.data.data[this.state.selectedCells[0].x][this.state.selectedCells[0].y]) {

            firstCellData = this.props.data.data[this.state.selectedCells[0].x][this.state.selectedCells[0].y]
        }
        else if (this.props.data.data.length > 0 && this.props.data.data[0] && this.props.data.data[0][0]) {
            firstCellData = this.props.data.data[0][0]
        }

        return JSON.stringify({
            borderMode: this.state.borderMode,
            selectedCells: JSON.stringify(this.state.selectedCells),
            firstCellData: JSON.stringify(firstCellData)
        })
    }
    getAdditionalActionDataElement = (): any => {
        return {
            selectedCells: this.state.selectedCells.map(c => {
                return {x: c.x, y: c.y}
            })
        }
    }

    onEditElement = async (editMode: boolean) => {
        if (editMode) {
            // Get click coords and calculate cell
            let coords = this.state.elementRef.current?.getEditCoords()
            if (coords) {
                coords = new Coords(
                    (coords.x / this.context.getZoom()) - this.props.element.posX - Const.ELEMENT_PADDING,
                    (coords.y / this.context.getZoom()) - this.props.element.posY - Const.ELEMENT_PADDING
                )

                let clickCoords = new Coords(
                    Math.floor(coords.y / this.getTotalCellHeight()),
                    Math.floor(coords.x / this.getTotalCellWidth())
                )

                if (clickCoords.x >= 0 && clickCoords.y >= 0 &&
                    clickCoords.x < this.props.data.rows && clickCoords.y < this.props.data.cols) {

                    this.setState({selectedCells: [new Coords(clickCoords.x, clickCoords.y)]})
                }
            }

            // Reset edit coords
            this.state.elementRef.current?.setEditCoords(undefined)

            // Add key down event handler
            document.addEventListener('keydown', this.onKeyDownCell)

            this.setState({isEdited: true})
        } else {
            document.removeEventListener('keydown', this.onKeyDownCell)

            this.state.elementRef.current?.onStopEdit()

            // Cancel border mode
            this.toggleBorderMode(ManualBorderMode.Off)

            // Remove selection
            this.setState({selectedCells: [], isEdited: false})
        }

        this.props.onElementEdit?.(this.props.id, editMode)
    }

    toggleBorderMode = (mode: ManualBorderMode, color?: string) => {
        if (mode) {
            document.addEventListener("mousedown", this.onMouseDownBorder)
            document.addEventListener("mousemove", this.onMouseMoveBorder)
        } else {
            document.removeEventListener("mousedown", this.onMouseDownBorder)
            document.removeEventListener("mousemove", this.onMouseMoveBorder)
        }

        this.setState({
            borderMode: mode,
            borderColor: color || "",
            selectedCells: (mode === ManualBorderMode.Border || mode === ManualBorderMode.Erase) ? [] : this.state.selectedCells
        })
    }
    isBorderModeOff = () => {
        return this.state.borderMode === undefined || this.state.borderMode === ManualBorderMode.Off
    }
    drawBorder = async (e: MouseEvent, borderMode: ManualBorderMode) => {
        if (e.target) {
            let cell: HTMLElement | undefined

            // Get table cell of element clicked
            let td = Util.getParentByTag(e.target as HTMLElement, "td", this.props.id)
            if (td) {
                // Find cell container div
                let children = td.getElementsByTagName("div")
                for (let i = 0; i < children.length; i++) {
                    if (children[i].getAttribute("id")?.startsWith("cell-")) {
                        cell = children[i]
                    }
                }
            }

            if (cell) {
                e.preventDefault()
                e.stopPropagation()

                let rect = cell.getBoundingClientRect()

                let offsetX = e.clientX - rect.left, offsetY = e.clientY - rect.top

                let x = +cell.id.substring(5, cell.id.indexOf("-", 5))
                let y = +cell.id.substring(cell.id.indexOf("-", 5 + x.toString().length) + 1, cell.id.indexOf("-", 5 + x.toString().length + 1))

                let newData = {...this.props.data}
                let current = (borderMode === ManualBorderMode.Erase)

                let thresholdX = rect.width * 0.2, thresholdY = rect.height * 0.2
                if (offsetX < thresholdX && offsetY > thresholdY && offsetY < rect.height - thresholdY) {
                    this.updateCellBorder(newData.data, BorderType.left, this.state.borderColor, x, y, current)
                } else if (offsetX > rect.width - thresholdX && offsetY > thresholdY && offsetY < rect.height - thresholdY) {
                    this.updateCellBorder(newData.data, BorderType.right, this.state.borderColor, x, y, current)
                } else if (offsetY < thresholdY && offsetX > thresholdX && offsetX < rect.width - thresholdX) {
                    this.updateCellBorder(newData.data, BorderType.top, this.state.borderColor, x, y, current)
                } else if (offsetY > rect.height - thresholdY && offsetX > thresholdX && offsetX < rect.width - thresholdX) {
                    this.updateCellBorder(newData.data, BorderType.bottom, this.state.borderColor, x, y, current)
                }

                this.props.onUpdateElement(new WorksheetItemUpdate(
                        this.props.id, {content: this.serializeElementData(newData)}),
                    {actionCategory: WDActionLogCategory.content}
                )
            }
        }
    }

    onMouseDownBorder = async (e: MouseEvent) => {
        this.borderStack = []
        await this.drawBorder(e, this.state.borderMode)
    }
    onMouseMoveBorder = async (e: MouseEvent) => {
        if (e.buttons === 1) {
            await this.drawBorder(e, this.state.borderMode)
        }
    }
    onMouseDownCell = (x: number, y: number) => {
        if (this.isBorderModeOff() && !this.props.element.locked) {
            this.clickX = x
            this.clickY = y

            // Tell parent element that the math lineature has the focus
            this.props.onFocus?.()

            this.setState({selectedCells: [new Coords(x, y)]})
        }
    }
    onMouseMoveCell = (x: number, y: number) => {
        if (this.isBorderModeOff() && !this.props.element.locked) {
            let selectedCells: Coords[] = []

            for (let i = Math.min(this.clickX, x); i <= Math.max(this.clickX, x); i++) {
                for (let j = Math.min(this.clickY, y); j <= Math.max(this.clickY, y); j++) {
                    selectedCells.push(new Coords(i, j))
                }
            }

            this.setState({selectedCells: selectedCells})
        }
    }
    onKeyDownCell = async (e: KeyboardEvent) => {
        if (e.defaultPrevented || this.props.element.locked) {
            return; // Do nothing if the event was already processed or the item is locked
        }

        let newData = {...this.props.data}
        if (this.state.selectedCells.length > 0) {
            if (e.key === 'ArrowLeft') {
                this.moveLeft()
                e.preventDefault()
            } else if ((e.key === 'ArrowRight') || (e.key === 'Tab')) {
                this.moveRight()
                e.preventDefault()
            } else if (e.key === 'ArrowUp') {
                this.moveUp()
                e.preventDefault()
            } else if (e.key === 'ArrowDown') {
                this.moveDown()
                e.preventDefault()
            } else if (e.key === ' ') {
                this.clearCellValue(newData, this.state.selectedCells[0].x, this.state.selectedCells[0].y)

                this.moveRight()
                this.props.onUpdateElement(new WorksheetItemUpdate(
                        this.props.id, {content: this.serializeElementData(newData)}),
                    {actionCategory: WDActionLogCategory.content}
                )

                e.preventDefault()
            } else if (e.key === 'Backspace') {
                this.clearCellValue(newData, this.state.selectedCells[0].x, this.state.selectedCells[0].y)

                this.moveLeft()
                this.props.onUpdateElement(new WorksheetItemUpdate(
                        this.props.id, {content: this.serializeElementData(newData)}),
                    {actionCategory: WDActionLogCategory.content}
                )

                e.preventDefault()
            } else if (e.key === 'Delete') {
                for (const item of this.state.selectedCells) {
                    this.clearCellValue(newData, item.x, item.y);
                }

                this.props.onUpdateElement(new WorksheetItemUpdate(
                        this.props.id, {content: this.serializeElementData(newData)}),
                    {actionCategory: WDActionLogCategory.content}
                )

                e.preventDefault()
            } else if (e.key.length === 1 && e.key.match("^[0-9A-Za-zÄÖÜäüöß|+=*%/€$<>?!-]")
            ) {
                let key = e.key

                if (this.state.operatorMode) {
                    switch (key) {
                        case "*":
                            key = '\u2022'
                            break

                        case "/":
                            key = '\uE013' // TODO: change with new font
                            break

                        case "-":
                            key = '\u2212'
                            break
                    }
                }
                this.props.onUpdateElement(this.fillInMiddleCell(key),
                    {actionCategory: WDActionLogCategory.content})
                e.preventDefault()
            } else if (e.key === "(") {
                this.props.onUpdateElement(this.fillInLeftCell(e.key),
                    {actionCategory: WDActionLogCategory.content})
                e.preventDefault()
            } else if (e.key === ")" || e.key === "," || e.key === "." || e.key === ":" || e.key === "²" || e.key === "³") {
                this.props.onUpdateElement(this.fillInRightCell(e.key),
                    {actionCategory: WDActionLogCategory.content})
                e.preventDefault()
            } else if (e.key === 'Home') {
                this.setState({selectedCells: [new Coords(this.state.selectedCells[0].x, 0)]})
            } else if (e.key === 'End') {
                this.setState({selectedCells: [new Coords(this.state.selectedCells[0].x, this.props.data.cols - 1)]})
            } else if (e.key === 'Enter') {
                let x = this.state.selectedCells[0].x
                if (x < newData.rows - 1) {
                    x++
                    this.setState({selectedCells: [new Coords(x, 0)]})
                }
            }
        }
    }

    fillInRightCell = (key: string) => {
        let x = this.state.selectedCells[0].x
        let y = this.state.selectedCells[0].y

        // If selected field is first in a row, set coords to last cell of row above, otherwise prev. cell
        if (y === 0 && x > 0) {
            x--
            y = this.props.data.cols - 1
        } else if (y > 0) {
            y--
        }

        let newData = {...this.props.data}
        this.setCellValue(newData, x, y, undefined, undefined, key)

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }
    fillInLeftCell = (key: string) => {
        let newData = {...this.props.data}
        this.setCellValue(newData, this.state.selectedCells[0].x, this.state.selectedCells[0].y, key, undefined, undefined)

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }
    fillInMiddleCell = (key: string) => {
        let newData = {...this.props.data}
        this.setCellValue(newData, this.state.selectedCells[0].x, this.state.selectedCells[0].y, undefined, key, undefined)

        this.moveRight()

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }

    renderBorder = (data: WDMathLineatureDataCell[][], borderType: BorderType, x: number, y: number) => {
        if (data[x] === undefined || data[x] === null || data[x][y] === null || data[x][y] === undefined) {
            return false
        }

        let solutionMode = this.renderSolution()
        let renderSolution = solutionMode === SolutionForceMode.ForceShow || (solutionMode === SolutionForceMode.Off && this.props.data.showSolution)

        let solution2 = false
        let result = false

        if (borderType === BorderType.left) {
            if (y > 0 && data[x][y - 1]) {
                solution2 = data[x][y - 1].solution
                result = data[x][y - 1].border ? data[x][y - 1].border.rightShow : false
            }

            // If solutions should not be rendered, we don't want to show the border if the cell or its neighbour is part of the solution
            if (this.renderSolution() && (data[x][y].solution || solution2)) {
                return false
            } else if (data[x][y].border && data[x][y].border.leftShow && this.props.data.showSolution) {
                return true
            }

            return !data[x][y].solution && !solution2 && (result || (data[x][y].border && data[x][y].border.leftShow))
        } else if (borderType === BorderType.right) {
            if (y < data[x].length - 1 && data[x][y + 1]) {
                solution2 = data[x][y + 1].solution
                result = data[x][y + 1].border ? data[x][y + 1].border.leftShow : false
            }

            // If solutions should not be rendered, we don't want to show the border if the cell or its neighbour is part of the solution
            if (!renderSolution && (data[x][y].solution || solution2)) {
                return false
            } else if (data[x][y].border && data[x][y].border.rightShow && this.props.data.showSolution) {
                return true
            }

            return !data[x][y].solution && !solution2 && (result || (data[x][y].border && data[x][y].border.rightShow))

        } else if (borderType === BorderType.top) {
            if (x > 0 && data[x - 1] && data[x - 1][y] !== null && data[x - 1][y] !== undefined) {
                solution2 = data[x - 1][y].solution
                result = data[x - 1][y].border ? data[x - 1][y].border.bottomShow : false
            }

            // If solutions should not be rendered, we don't want to show the border if the cell or its neighbour is part of the solution
            if (!renderSolution && (data[x][y].solution || solution2)) {
                return false
            } else if (data[x][y].border && data[x][y].border.topShow && this.props.data.showSolution) {
                return true
            }

            return !data[x][y].solution && !solution2 && (result || (data[x][y].border && data[x][y].border.topShow))
        } else if (borderType === BorderType.bottom) {
            if (x < data.length - 1 && data[x + 1] && data[x + 1][y] !== null && data[x + 1][y] !== undefined) {
                solution2 = data[x + 1][y].solution
                result = data[x + 1][y].border !== undefined ? data[x + 1][y].border.topShow : false
            }

            // If solutions should not be rendered, we don't want to show the border if the cell or its neighbour is part of the solution
            if (!renderSolution && (data[x][y].solution || solution2)) {
                return false
            } else if (data[x][y].border && data[x][y].border.bottomShow && this.props.data.showSolution) {
                return true
            }

            return !data[x][y].solution && !solution2 && (result || (data[x][y].border && data[x][y].border.bottomShow))
        }

        return result
    }
    updateCellBorder = (data: WDMathLineatureDataCell[][], borderType: BorderType, color: string, x: number, y: number, current?: boolean) => {
        // Draw right border of prev cell if it is not the first column
        if (borderType === BorderType.left && y > 0) {
            borderType = BorderType.right
            y--
        }
        // Draw bottom border of prev cell if it is not the first row
        else if (borderType === BorderType.top && x > 0) {
            borderType = BorderType.bottom
            x--
        }

        if (data[x] === undefined || data[x] === null) {
            data[x] = []
        }
        if (data[x][y] === undefined || data[x][y] === null) {
            data[x][y] = new WDMathLineatureDataCell("", "", "")
        }

        let border = data[x][y].border
        if (border === undefined || border === null) {
            border = new WDDataCellBorder()
        }

        if (borderType === BorderType.left) {
            if (current === undefined) {
                current = border.leftShow
            }
            border.leftShow = (border.leftColor === color) ? !current : true
            border.leftColor = color
        }
        if (borderType === BorderType.top) {
            if (current === undefined) {
                current = border.topShow
            }
            border.topShow = (border.topColor === color) ? !current : true
            border.topColor = color
        }
        if (borderType === BorderType.right) {
            if (current === undefined) {
                current = border.rightShow
            }
            border.rightShow = (border.rightColor === color) ? !current : true
            border.rightColor = color
        }
        if (borderType === BorderType.bottom || borderType === BorderType.bottom_double) {
            if (current === undefined) {
                current = border.bottomShow
            }
            border.bottomShow = (border.bottomColor === color) ? !current : true
            border.bottomColor = color
            border.bottomDouble = (borderType === BorderType.bottom_double)
        }
        if (borderType === BorderType.middle) {
            if (current === undefined) {
                current = border.middleShow
            }
            border.middleShow = (border.middleColor === color) ? !current : true
            border.middleColor = color

            // Reset icon
            data[x][y].icon = ""
        }
        if (borderType === BorderType.no) {
            border.leftShow = false
            border.rightShow = false
            border.topShow = false
            border.bottomShow = false
        }

        data[x][y].border = border

        return current
    }

    clearCellValue = (data: WDMathLineatureData, x: number, y: number) => {
        this.setCellValue(data, x, y, "", "", "", "", "")
        data.data[x][y].solution = false
    }
    setCellValue = (data: WDMathLineatureData, x: number, y: number, left?: string, middle?: string, right?: string,
                    icon?: string, iconColor?: string,) => {

        let cellData = data.data

        if (cellData[x] === undefined || cellData[x] === null) {
            cellData[x] = []
        }
        if (cellData[x][y] === undefined || cellData[x][y] === null) {
            cellData[x][y] = new WDMathLineatureDataCell(left || "", middle || "", right || "", icon, iconColor)
        } else {
            if (left !== undefined) {
                cellData[x][y].left = left
                cellData[x][y].icon = ""
            }
            if (middle !== undefined) {
                cellData[x][y].middle = middle
                cellData[x][y].icon = ""
            }
            if (right !== undefined) {
                cellData[x][y].right = right
                cellData[x][y].icon = ""
            }
            if (icon !== undefined) {
                cellData[x][y].icon = icon
                cellData[x][y].left = ""
                cellData[x][y].right = ""
                cellData[x][y].middle = ""
                if (cellData[x][y].border !== undefined) {
                    cellData[x][y].border.middleShow = false
                }
            }
            if (iconColor !== undefined) {
                cellData[x][y].iconColor = iconColor
            }
        }
        cellData[x][y].bold = this.state.bold
        cellData[x][y].italic = this.state.italic
        cellData[x][y].color = this.state.color
    }
    setSolution = (data: WDMathLineatureDataCell[][], x: number, y: number) => {
        if (data[x] === undefined || data[x] === null) {
            data[x] = []
        }
        if (data[x][y] === undefined || data[x][y] === null) {
            data[x][y] = new WDMathLineatureDataCell("", "", "")
        }

        data[x][y].solution = !data[x][y].solution
    }
    setCellBackground = (data: WDMathLineatureDataCell[][], x: number, y: number, background: string) => {
        if (data[x] === undefined || data[x] === null) {
            data[x] = []
        }
        if (data[x][y] === undefined || data[x][y] === null) {
            data[x][y] = new WDMathLineatureDataCell("", "", "")
        }

        data[x][y].background = background
    }
    setCellDecoration = (style: string, value?: string) => {
        let newData = {...this.props.data}

        let stateObj = {}

        let current: boolean | undefined = undefined
        for (const item of this.state.selectedCells) {
            if (newData.data[item.x] && newData.data[item.x][item.y]) {

                if (value) {
                    newData.data[item.x][item.y][style] = value
                    stateObj[style] = value
                } else {
                    if (current === undefined) {
                        current = newData.data[item.x][item.y][style]
                    }
                    newData.data[item.x][item.y][style] = !current
                    stateObj[style] = !current
                }
            } else {
                let stateObj = {}

                if (value) {
                    stateObj[style] = value
                } else {
                    if (current === undefined) {
                        current = true
                    }

                    stateObj[style] = !this.state[style]
                }
            }
        }

        this.setState(stateObj)

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }
    setCellBorder = (borderType: BorderType, color: string) => {
        let newData = {...this.props.data}
        let current: boolean | undefined = undefined

        // When a cell is selected, set border to selected cell
        if (this.state.isEdited && this.state.selectedCells && this.state.selectedCells.length > 0) {

            // Get min/max coordinates of selection
            let minY = this.state.selectedCells.reduce((prev, current) => {
                return (prev.y < current.y) ? prev : current
            }).y
            let minX = this.state.selectedCells.reduce((prev, current) => {
                return (prev.x < current.x) ? prev : current
            }).x
            let maxY = this.state.selectedCells.reduce((prev, current) => {
                return (prev.y > current.y) ? prev : current
            }).y
            let maxX = this.state.selectedCells.reduce((prev, current) => {
                return (prev.x > current.x) ? prev : current
            }).x

            for (const item of this.state.selectedCells) {
                if ((borderType === BorderType.left || borderType === BorderType.outer || borderType === BorderType.all) && item.y === minY) {
                    current = this.updateCellBorder(newData.data, BorderType.left, color, item.x, item.y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
                if ((borderType === BorderType.right || borderType === BorderType.outer || borderType === BorderType.all) && item.y === maxY) {
                    current = this.updateCellBorder(newData.data, BorderType.right, color, item.x, item.y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
                if ((borderType === BorderType.top || borderType === BorderType.outer || borderType === BorderType.all) && item.x === minX) {
                    current = this.updateCellBorder(newData.data, BorderType.top, color, item.x, item.y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
                if ((borderType === BorderType.bottom || borderType === BorderType.bottom_double || borderType === BorderType.outer || borderType === BorderType.all) && item.x === maxX) {
                    current = this.updateCellBorder(newData.data,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? BorderType.bottom : borderType,
                        color, item.x, item.y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
                if (borderType === BorderType.middle) {
                    current = this.updateCellBorder(newData.data, BorderType.middle, color, item.x, item.y, current)
                }
                if (borderType === BorderType.inner || borderType === BorderType.all) {
                    if (item.x < maxX) {
                        current = this.updateCellBorder(newData.data, BorderType.bottom, color, item.x, item.y, false)
                    }
                    if (item.y < maxY) {
                        current = this.updateCellBorder(newData.data, BorderType.right, color, item.x, item.y, false)
                    }
                }
                if (borderType === BorderType.no) {
                    current = this.updateCellBorder(newData.data, BorderType.no, color, item.x, item.y, current)
                }
            }
        } else if (!this.state.isEdited) {
            if (borderType === BorderType.left || borderType === BorderType.outer || borderType === BorderType.all) {
                for (let x = 0; x < newData.rows; x++) {
                    current = this.updateCellBorder(newData.data, BorderType.left, color, x, 0,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
            }
            if (borderType === BorderType.top || borderType === BorderType.outer || borderType === BorderType.all) {
                for (let y = 0; y < newData.cols; y++) {
                    current = this.updateCellBorder(newData.data, BorderType.top, color, 0, y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
            }
            if (borderType === BorderType.right || borderType === BorderType.outer || borderType === BorderType.all) {
                for (let x = 0; x < newData.rows; x++) {
                    current = this.updateCellBorder(newData.data, BorderType.right, color, x, newData.cols - 1,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
            }
            if (borderType === BorderType.bottom || borderType === BorderType.outer || borderType === BorderType.all) {
                for (let y = 0; y < newData.cols; y++) {
                    current = this.updateCellBorder(newData.data, BorderType.bottom, color, newData.rows - 1, y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
            }
            if (borderType === BorderType.inner || borderType === BorderType.all) {
                for (let x = 0; x < newData.rows; x++) {
                    for (let y = 0; y < newData.cols; y++) {
                        if (x < newData.rows - 1) {
                            current = this.updateCellBorder(newData.data, BorderType.bottom, color, x, y,
                                (borderType === BorderType.all) ? false : current)
                        }
                        if (y < newData.cols - 1) {
                            current = this.updateCellBorder(newData.data, BorderType.right, color, x, y,
                                (borderType === BorderType.all) ? false : current)
                        }
                    }
                }
            }
            if (borderType === BorderType.no) {
                for (let x = 0; x < newData.rows; x++) {
                    for (let y = 0; y < newData.cols; y++) {
                        current = this.updateCellBorder(newData.data, BorderType.no, color, x, y, current)
                    }
                }
            }
        }

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }
    setCellReckonBracket = (data: WDMathLineatureDataCell[][], x: number, y: number, position: BracketPosition, color: string) => {
        if (data[x] === undefined || data[x] === null) {
            data[x] = []
        }
        if (data[x][y] === undefined || data[x][y] === null) {
            data[x][y] = new WDMathLineatureDataCell("", "", "")
        }

        data[x][y].bracket = (data[x][y].bracket !== position || data[x][y].bracketColor !== color) ? position : undefined
        data[x][y].bracketColor = color
    }
    setResizeState = (state: boolean, nodeType?: RESIZE_NODE) => {
        this.state.elementRef.current?.setResizeState(state, nodeType)

        let update = new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(this.props.data),
            width: this.props.element.width,
            height: this.props.element.height,
            resized: state
        })

        if (nodeType === undefined) {
            let newData = {...this.props.data}
            newData.cols = Math.max(1, newData.cols)
            newData.rows = Math.max(1, newData.rows)

            update.value.content = this.serializeElementData(newData)
            update.value.width = this.getTotalWidth(newData)
            update.value.height = this.getTotalHeight(newData)
        }
        return update
    }

    moveRight = () => {
        let selectedCells: Coords[] = []

        let x = this.state.selectedCells[0].x
        let y = this.state.selectedCells[0].y

        const wrap = (y === this.props.data.cols - 1)

        if (!wrap || x < this.props.data.rows - 1) {
            x = wrap ? x + 1 : x
            y = wrap ? 0 : y + 1
            selectedCells.push(new Coords(x, y))

            this.setState({selectedCells: selectedCells})
        }
    }
    moveUp = () => {
        let selectedCells: Coords[] = []

        let x = this.state.selectedCells[0].x
        let y = this.state.selectedCells[0].y

        x = x > 0 ? x - 1 : x
        selectedCells.push(new Coords(x, y))

        this.setState({selectedCells: selectedCells})
    }
    moveLeft = () => {
        let selectedCells: Coords[] = []

        let x = this.state.selectedCells[0].x
        let y = this.state.selectedCells[0].y

        const wrap = (y === 0)

        y = wrap ? (x > 0 ? this.props.data.cols - 1 : y) : y - 1
        x = wrap ? (x > 0 ? x - 1 : x) : x
        selectedCells.push(new Coords(x, y))

        this.setState({selectedCells: selectedCells})
    }
    moveDown = () => {
        let selectedCells: Coords[] = []

        let x = this.state.selectedCells[0].x
        let y = this.state.selectedCells[0].y

        x = (x === this.props.data.rows - 1) ? x : x + 1
        selectedCells.push(new Coords(x, y))

        this.setState({selectedCells: selectedCells})
    }

    // add or delete rows
    addRow = (data: WDMathLineatureData, action: WDToolbarAction) => {
        data.rows = this.props.data.rows + 1

        let x = 0
        if (this.state.selectedCells.length > 0) {
            let minX = this.state.selectedCells.map(i => i.x).reduce((prev, current) => Math.min(prev, current))

            x = minX
            if (action === WDToolbarAction.ADD_ROW_BELOW) {
                x = Math.min(data.rows, minX + 1)
            }
            data.data.splice(x, 0, new Array(data.cols).fill(new WDMathLineatureDataCell("", "", "")))
        } else if (action === WDToolbarAction.ADD_ROW_ABOVE) {
            data.data.splice(x, 0, new Array(data.cols).fill(new WDMathLineatureDataCell("", "", "")))
        }

        if (action === WDToolbarAction.ADD_ROW_BELOW) {
            this.state.selectedCells.forEach(i => i.x++)
        }

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(data),
            width: this.getTotalWidth(data),
            height: this.getTotalHeight(data)
        })
    }
    deleteRow = (data: WDMathLineatureData) => {
        let rows: number[] = []
        if (this.state.isEdited) {
            for (let i = 0; i < this.state.selectedCells.length; i++) {
                if (rows.find(r => r === this.state.selectedCells[i].x) === undefined) {
                    rows.push(this.state.selectedCells[i].x)
                }
            }
        }

        // No row selected, delete last column
        if (rows.length === 0) {
            rows.push(data.rows - 1)
        }

        // Sort descending and delete columns
        rows.sort((a, b) => b - a)
            .forEach(row => {
                data.data.splice(row, 1)
            })

        data.rows = Math.max(1, data.rows - rows.length)

        if (this.state.isEdited) {
            this.setState({selectedCells: []})
        }

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(data),
            width: this.getTotalWidth(data),
            height: this.getTotalHeight(data)
        })
    }
    addColumn = (data: WDMathLineatureData, action: WDToolbarAction) => {
        data.cols = this.props.data.cols + 1

        let y = 0
        if (this.state.selectedCells.length > 0) {
            let minY = this.state.selectedCells.map(i => i.y).reduce((prev, current) => Math.min(prev, current))

            for (let x = 0; x < data.rows; x++) {
                if (data.data[x] === undefined || data.data[x] === null) {
                    continue
                }

                y = minY
                if (action === WDToolbarAction.ADD_COLUMN_RIGHT) {
                    y = Math.min(data.cols, minY + 1)
                }

                data.data[x].splice(y, 0, new WDMathLineatureDataCell("", "", ""))
            }

            if (action === WDToolbarAction.ADD_COLUMN_RIGHT) {
                this.state.selectedCells.forEach(i => i.y++)
            }
        } else if (action === WDToolbarAction.ADD_COLUMN_LEFT) {
            for (let x = 0; x < data.rows; x++) {
                if (data.data[x] === undefined || data.data[x] === null) {
                    continue
                }

                data.data[x].splice(y, 0, new WDMathLineatureDataCell("", "", ""))
            }
        }

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(data),
            width: this.getTotalWidth(data),
            height: this.getTotalHeight(data)
        })
    }
    deleteColumn = (data: WDMathLineatureData) => {
        let cols: number[] = []
        if (this.state.isEdited) {
            for (let i = 0; i < this.state.selectedCells.length; i++) {
                if (cols.find(c => c === this.state.selectedCells[i].y) === undefined) {
                    cols.push(this.state.selectedCells[i].y)
                }
            }
        }

        // No column selected, delete last column
        if (cols.length === 0) {
            cols.push(data.cols - 1)
        }

        // Sort descending and delete columns
        cols.sort((a, b) => b - a)
            .forEach(col => {
                for (let k = 0; k < data.rows; k++) {
                    if (data.data[k] === undefined || data.data[k] === null) {
                        continue
                    }

                    data.data[k].splice(col, 1)
                }
            })

        data.cols = Math.max(1, data.cols - cols.length)

        if (this.state.isEdited) {
            this.setState({selectedCells: []})
        }

        let width = this.getTotalWidth(data)
        let height =  this.getTotalHeight(data)

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(data),
            width: width,
            height: height
        })
    }

    clearData = (data: WDMathLineatureData) => {
        for (let i = 0; i < data.rows; i++) {
            for (let j = 0; j < data.cols; j++) {
                this.clearCellValue(data, i, j)
            }
        }
    }
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDMathLineatureData.serialize(data as WDMathLineatureData)
    }

    getPadding = () => {
        let padding = Const.ELEMENT_PADDING
        if (this.props.data.lineType === WDMathLineatureLineType.line_frame) {
            padding = padding - (this.getCellWidth() / 3)
        }
        return Math.max(padding, 0)
    }
    getTableWidth = (data?: WDMathLineatureData) => {
        let cols = data ? data.cols : this.props.data.cols
        let tableWidth = (this.getTotalCellWidth(data) * cols)

        // Reduce table width when printing and table is outside printable area
        if (this.props.sheetWidth && this.props.renderingMedia === RenderingMedia.print) {
            if ((this.props.element.posX + tableWidth + Const.ELEMENT_PADDING) >= this.props.sheetWidth) {
                tableWidth = this.props.sheetWidth - this.props.element.posX - Const.ELEMENT_PADDING
            }
        }
        return Math.floor(tableWidth)
    }
    getTableHeight = (data?: WDMathLineatureData) => {
        let rows = data ? data.rows : this.props.data.rows
        return (this.getTotalCellHeight(data) * rows)
    }
    getTotalWidth = (data?: WDMathLineatureData) => {
        return this.getTableWidth(data) + (2 * Const.ELEMENT_PADDING)
    }
    getTotalHeight = (data?: WDMathLineatureData) => {
        return this.getTableHeight(data) + (2 * Const.ELEMENT_PADDING)
    }

    isCellSelected = (row: number, col: number) => {
        return this.state.selectedCells.find(c => c.x === row && c.y === col) !== undefined
    }

    getCellWidth(data?: WDMathLineatureData) {
        let cellWidth = data ? data.cellWidth : this.props.data.cellWidth
        return Math.round(Converter.mmToPx(cellWidth))
    }
    getCellHeight(data?: WDMathLineatureData) {
        let cellHeight = data ? data.cellHeight : this.props.data.cellHeight
        return Math.round(Converter.mmToPx(cellHeight))
    }
    getTotalCellWidth(data?: WDMathLineatureData) {
        let borderWidth = data ? data.borderWidth : this.props.data.borderWidth
        return this.getCellWidth(data) + borderWidth
    }
    getTotalCellHeight(data?: WDMathLineatureData) {
        let borderWidth = data ? data.borderWidth : this.props.data.borderWidth
        return this.getCellHeight(data) + borderWidth
    }

    getFontSize() {
        return (this.props.data.cellHeight * 3.5);
    }

    selectDimension = (selection: Coords[], index: number) => {
        if (this.selectorElementMode === WDTableDimensionMode.ROW) {
            this.onSelectRow(selection, index)
        } else if (this.selectorElementMode === WDTableDimensionMode.COLUMN) {
            this.onSelectColumn(selection, index)
        }
    }

    onSelectRow = (selection: Coords[], row: number) => {
        for (let j = 0; j < this.props.data.cols; j++) {
            if (selection.find(c => c.x === row && c.y === j) === undefined) {
                selection.push(new Coords(row, j))
            }
        }
    }
    onSelectColumn = (selection: Coords[], col: number) => {
        for (let i = 0; i < this.props.data.rows; i++) {
            if (selection.find(c => c.x === i && c.y === col) === undefined) {
                selection.push(new Coords(i, col))
            }
        }
    }

    onMouseDownSelector = async (mode: WDTableDimensionMode, index: number) => {
        this.selectorElementMode = mode
        this.selectorStartIndex = index

        let selection: Coords[] = []
        this.selectDimension(selection, index)
        this.setState({selectedCells: selection})

        document.addEventListener("mouseup", this.onMouseUpSelector)
    }
    onMouseMoveSelector = async (e: React.MouseEvent<HTMLDivElement>, index: number) => {
        if (this.selectorElementMode !== undefined && e.buttons === 1) {
            let start = Math.min(this.selectorStartIndex || 0, index)
            let end = Math.max(this.selectorStartIndex || 0, index)

            let selection: Coords[] = []
            for (let i = start; i <= end; i++) {
                this.selectDimension(selection, i)
            }

            this.setState({selectedCells: selection})
        }
    }
    onMouseUpSelector = () => {
        this.selectorElementMode = undefined

        document.removeEventListener("mouseup", this.onMouseUpSelector)
    }

    getMarginByCellHeight = (cellHeight: number) => {
        let isFirefox = navigator.userAgent.match(/Firefox/) !== null
        if (cellHeight === 6 || cellHeight === 5 || cellHeight === 10 ||
            (cellHeight === 11 && !isFirefox) || (cellHeight === 12 && isFirefox)) {
            return -1
        }
        return -2
    }

    renderSolution = (): SolutionForceMode => {
        if (this.props.inPresentationMode) {
            return this.props.element.renderSolutionInPresentationMode ? SolutionForceMode.ForceShow : SolutionForceMode.ForceHide
        } else {
            return this.props.solutionForceMode
        }
    }
    renderRows() {
        let lineType = this.props.data.lineType

        const drawDot = lineType === WDMathLineatureLineType.line_dots || lineType === WDMathLineatureLineType.dots
        const drawQuad = lineType === WDMathLineatureLineType.line_quad || lineType === WDMathLineatureLineType.quad
        const drawFrame = lineType === WDMathLineatureLineType.line_frame
        const drawInnerBorder = lineType === WDMathLineatureLineType.line_dots ||
            lineType === WDMathLineatureLineType.line_frame ||
            lineType === WDMathLineatureLineType.line_quad ||
            lineType === WDMathLineatureLineType.line
        const cellWidth = this.getCellWidth()
        const cellWidthWithBorder = this.getTotalCellWidth()
        const cellHeight = this.getCellHeight()
        const decoRadius = Math.round(Math.min(cellWidth, cellHeight) / 5)
        const decoOffset = Math.ceil(decoRadius / 2) * -1
        const lineColor = this.props.data.lineColor
        const iconSize = Math.min(cellWidth, cellHeight) - 2

        let rows: JSX.Element[] = []
        let cells: JSX.Element[] = []

        let renderSolution = this.renderSolution()

        let borderStrokeWidth = 1
        let pageWidth = 0
        if (this.props.sheetWidth && this.props.renderingMedia === RenderingMedia.print) {
            pageWidth = this.props.sheetWidth
        }

        // When line frame style - draw additional line
        if (drawFrame) {
            cells.push(
                <td key={"pre-tl"}
                    className={"ws-designer-math-lineature-table-cell-container"}
                    style={{
                        width: Math.min(cellWidth / 3, Const.ELEMENT_PADDING),
                        height: Math.min(cellHeight / 3, Const.ELEMENT_PADDING),
                        borderWidth: 0
                    }}
                />
            )

            for (let j = 0; j < this.props.data.cols; j++) {
                cells.push(
                    <td key={"pre-" + j.toString()}
                        className={"ws-designer-math-lineature-table-cell-container ws-designer-math-lineature-table-cell-outdent-tb"}
                        style=
                            {{
                                width: cellWidth,
                                height: Math.min(cellHeight / 3, Const.ELEMENT_PADDING),
                                borderColor: this.props.data.lineColor
                            }}>
                    </td>
                )
            }

            cells.push(
                <td key={"post-tr"}
                    className={"ws-designer-math-lineature-table-cell-container"}
                    style={{
                        width: Math.min(cellWidth / 3, Const.ELEMENT_PADDING),
                        height: Math.min(cellHeight / 3, Const.ELEMENT_PADDING),
                        borderWidth: 0
                    }}
                />
            )

            rows.push(<tr key={"pre-row"}>{cells.map(c => c)}</tr>)
        }

        for (let i = 0; i < this.props.data.rows; i++) {
            let rowWidth = Const.ELEMENT_PADDING
            cells = []

            for (let j = 0; j < this.props.data.cols; j++) {
                let left = "", middle = "", right = "", icon, iconColor, bold = false, italic = false,
                    color, background, border = new WDDataCellBorder(), solution = false, bracketColor
                let bracket: BracketPosition | undefined = undefined

                let tdWidth = cellWidthWithBorder

                if (pageWidth > 0 && this.props.renderingMedia === RenderingMedia.print) {
                    if ((this.props.element.posX + rowWidth) >= pageWidth) {
                        tdWidth = 0
                    } else if ((this.props.element.posX + rowWidth + tdWidth) > pageWidth) {
                        tdWidth = pageWidth - (this.props.element.posX + rowWidth) + borderStrokeWidth
                    }
                    rowWidth += tdWidth
                }

                let data = this.props.data.data
                if (data[i] && data[i][j]) {
                    solution = data[i][j].solution

                    if (!solution || renderSolution !== SolutionForceMode.ForceHide) {
                        left = data[i][j].left
                        middle = data[i][j].middle
                        right = data[i][j].right

                        icon = data[i][j].icon
                        iconColor = data[i][j].iconColor
                        bracket = data[i][j].bracket
                        bracketColor = data[i][j].bracketColor
                    }

                    if (iconColor) {
                        iconColor = iconColor.toUpperCase().replace("#", "")
                    }
                    bold = data[i][j].bold
                    italic = data[i][j].italic
                    color = data[i][j].color
                    background = data[i][j].background
                    if (data[i][j].border !== undefined) {
                        border = data[i][j].border
                    }
                }

                const selected = (this.state.selectedCells.find(e => e.x === i && e.y === j) !== undefined)
                if (selected) {
                    if (background === undefined || background === "transparent") {
                        background = "#DADFF255"
                    } else {
                        background = Util.shadeColor(background, -15)
                    }
                }

                // Additional cells on the left for outlaying border
                if (drawFrame && j === 0) {
                    cells.push(
                        <td key={"pre-l-" + i.toString() + "-" + j.toString()}
                            className={"ws-designer-math-lineature-table-cell-container ws-designer-math-lineature-table-cell-outdent-lr"}
                            style={{width: cellWidth / 3, height: cellHeight, borderColor: lineColor}}>
                        </td>
                    )
                }

                let cellStyle: CSSProperties = {}
                cellStyle.height = cellHeight + "px"
                cellStyle.marginTop = this.getMarginByCellHeight(this.props.data.cellHeight)
                if (border.middleShow) {
                    cellStyle.fontSize = (this.getFontSize() / 2) + "pt"
                    cellStyle.marginTop = -1
                }

                // Double bottom border settings
                let borderWidth = 2
                let bottom = -2
                let bottom2 = -5
                if (this.props.data.cellWidth < 7) {
                    borderWidth = 1
                    bottom = -1
                    bottom2 = -3
                }

                // Content cells
                if (tdWidth > 0) {
                    cells.push(
                        <td key={i.toString() + "-" + j.toString()}
                            className={"ws-designer-math-lineature-table-cell-container"}
                            style={{
                                width: tdWidth - borderStrokeWidth,
                                height: cellHeight,
                                borderColor: (drawInnerBorder ? lineColor : "transparent"),
                            }}>

                            <div id={"cell-" + i.toString() + "-" + j.toString() + "-" + this.props.id}
                                 className={"ws-designer-math-lineature-table-cell"}
                                 style={{
                                     width: cellWidth,
                                     height: cellHeight,
                                     fontSize: this.getFontSize() + "pt",
                                     fontWeight: bold ? "bold" : "normal",
                                     fontStyle: italic ? "italic" : "normal",
                                     color: color,
                                     backgroundColor: background
                                 }}
                                 onMouseDown={() => this.onMouseDownCell(i, j)}
                                 onMouseMove={(e) => e.buttons === 1 && this.onMouseMoveCell(i, j)}
                            >
                                <div id={"cell-" + i.toString() + "-" + j.toString() + "-" + this.props.id + "-input"}
                                     className={"ws-designer-math-lineature-table-cell-input"}>
                                    <div
                                        className={"ws-designer-math-lineature-table-cell-left" + (solution ? " ws-designer-element-solution" : "")}
                                        style={cellStyle}>{left}</div>
                                    <div
                                        className={"ws-designer-math-lineature-table-cell-middle" + (solution ? " ws-designer-element-solution" : "")}
                                        style={cellStyle}>{middle}</div>
                                    <div
                                        className={"ws-designer-math-lineature-table-cell-right" + (solution ? " ws-designer-element-solution" : "")}
                                        style={cellStyle}>{right}</div>

                                    {/* Icon */}
                                    {icon &&
                                        <div className={"ws-designer-math-lineature-table-cell-icon"}>
                                            <img
                                                src={process.env.PUBLIC_URL + ImagePath.getIconUrl() + CategoryImageValue.getImageByKey([ImageCategory.MATH_LINEATURE_ICON], icon)}
                                                className={(solution ? "ws-designer-math-lineature-solution-icon" : "svg-color-" + iconColor)}
                                                alt={""} width={iconSize} height={iconSize} draggable={"false"}
                                                onContextMenu={(e) => e.preventDefault()}
                                            />
                                        </div>
                                    }

                                    {/* Sum line */}
                                    {(!solution || renderSolution !== SolutionForceMode.ForceHide) && border.middleShow &&
                                        <div
                                            className={"ws-designer-math-lineature-table-cell-border-middle" + (solution ? " ws-designer-math-lineature-solution-border" : "")}
                                            style={{
                                                width: cellWidth + 1,
                                                backgroundColor: (solution ? "" : border.middleColor)
                                            }}/>
                                    }

                                    {(bracket === BracketPosition.top) &&
                                        <>
                                            <div className={"ws-designer-math-lineature-table-cell-bracket-top-left"}
                                                 style={{
                                                     width: cellWidth / 2, backgroundColor: bracketColor
                                                 }}/>
                                            <div className={"ws-designer-math-lineature-table-cell-bracket-top-down"}
                                                 style={{
                                                     height: cellWidth / 2, backgroundColor: bracketColor
                                                 }}/>
                                        </>
                                    }
                                    {(bracket === BracketPosition.bottom) &&
                                        <>
                                            <div className={"ws-designer-math-lineature-table-cell-bracket-bottom-left"}
                                                 style={{
                                                     width: cellWidth / 2, backgroundColor: bracketColor
                                                 }}/>
                                            <div className={"ws-designer-math-lineature-table-cell-bracket-bottom-up"}
                                                 style={{
                                                     height: cellWidth / 2, backgroundColor: bracketColor
                                                 }}/>
                                        </>
                                    }
                                </div>
                            </div>

                            {/* Cell borders */}
                            {this.renderBorder(this.props.data.data, BorderType.left, i, j) &&
                                <div className={"ws-designer-math-lineature-table-cell-border-left"}
                                     style={{height: cellHeight + 2, backgroundColor: border.leftColor}}/>
                            }
                            {this.renderBorder(data, BorderType.top, i, j) &&
                                <div className={"ws-designer-math-lineature-table-cell-border-top"}
                                     style={{width: cellWidth + 2, backgroundColor: border.topColor}}/>
                            }
                            {this.renderBorder(data, BorderType.right, i, j) &&
                                <div className={"ws-designer-math-lineature-table-cell-border-right"}
                                     style={{height: cellHeight + 2, backgroundColor: border.rightColor}}/>
                            }
                            {this.renderBorder(data, BorderType.bottom, i, j) && !border.bottomDouble &&
                                <div className={
                                    "ws-designer-math-lineature-table-cell-border-bottom" +
                                    (solution ? " ws-designer-math-lineature-solution-border" : "")
                                }
                                     style={{
                                         width: cellWidth + 2,
                                         backgroundColor: (solution ? "" : border.bottomColor)
                                     }}
                                />
                            }
                            {this.renderBorder(data, BorderType.bottom, i, j) && border.bottomDouble &&
                                <>
                                    <div className={
                                        "ws-designer-math-lineature-table-cell-border-bottom" +
                                        (solution ? " ws-designer-math-lineature-solution-border" : "")
                                    }
                                         style={{
                                             width: cellWidth + 2,
                                             height: borderWidth + "px",
                                             bottom: bottom + "px",
                                             backgroundColor: (solution ? "" : border.bottomColor)
                                         }}
                                    />

                                    <div className={
                                        "ws-designer-math-lineature-table-cell-border-bottom-double" +
                                        (solution ? " ws-designer-math-lineature-solution-border" : "")
                                    }
                                         style={{
                                             width: cellWidth + 2,
                                             height: borderWidth + "px",
                                             bottom: bottom2 + "px",
                                             backgroundColor: (solution ? "" : border.bottomColor)
                                         }}/>
                                </>
                            }

                            {/* Draw dots in the crossing of table cells*/}
                            {drawDot && <div className={"ws-designer-math-lineature-dot"} style={{
                                width: decoRadius, height: decoRadius,
                                right: decoOffset, bottom: decoOffset,
                                backgroundColor: lineColor
                            }}/>}
                            {drawDot && i === 0 &&
                                <div className={"ws-designer-math-lineature-dot"} style={{
                                    width: decoRadius, height: decoRadius,
                                    right: decoOffset, top: decoOffset,
                                    backgroundColor: lineColor
                                }}
                                />}
                            {drawDot && j === 0 &&
                                <div className={"ws-designer-math-lineature-dot"} style={{
                                    width: decoRadius, height: decoRadius,
                                    left: decoOffset, top: decoOffset,
                                    backgroundColor: lineColor
                                }}/>}
                            {drawDot && j === 0 && i === this.props.data.rows - 1 &&
                                <div className={"ws-designer-math-lineature-dot"} style={{
                                    width: decoRadius, height: decoRadius,
                                    left: decoOffset, bottom: decoOffset,
                                    backgroundColor: lineColor
                                }}/>
                            }

                            {/* Draw quads in the crossing of table cells*/}
                            {drawQuad &&
                                <div className={"ws-designer-math-lineature-quad"} style={{
                                    width: decoRadius, height: decoRadius,
                                    right: decoOffset, bottom: decoOffset,
                                    backgroundColor: lineColor
                                }}/>}
                            {drawQuad && i === 0 &&
                                <div className={"ws-designer-math-lineature-quad"} style={{
                                    width: decoRadius, height: decoRadius,
                                    right: decoOffset, top: decoOffset,
                                    backgroundColor: lineColor
                                }}/>}
                            {drawQuad && j === 0 &&
                                <div className={"ws-designer-math-lineature-quad"} style={{
                                    width: decoRadius, height: decoRadius,
                                    left: decoOffset, top: decoOffset,
                                    backgroundColor: lineColor
                                }}/>}
                            {drawQuad && j === 0 && i === this.props.data.rows - 1 &&
                                <div className={"ws-designer-math-lineature-quad"} style={{
                                    width: decoRadius, height: decoRadius,
                                    left: decoOffset, bottom: decoOffset,
                                    backgroundColor: lineColor
                                }}/>
                            }
                        </td>
                    )
                }

                // Additional cells on the right for outlaying border
                if (tdWidth > 0 && drawFrame && j === this.props.data.cols - 1) {
                    cells.push(
                        <td key={"post-r-" + i.toString() + "-" + j.toString()}
                            className={"ws-designer-math-lineature-table-cell-container ws-designer-math-lineature-table-cell-outdent-lr"}
                            style={{
                                width: Math.min(cellWidth / 3, Const.ELEMENT_PADDING),
                                height: cellHeight,
                                borderColor: lineColor
                            }}>
                        </td>
                    )
                }
            }
            rows.push(<tr key={i}>{cells.map(c => c)}</tr>)
        }

        // When line frame style - draw additional line
        if (drawFrame) {
            cells = []

            cells.push(
                <td key={"post-tl"}
                    className={"ws-designer-math-lineature-table-cell-container"}
                    style={{
                        width: Math.min(cellWidth / 3, Const.ELEMENT_PADDING),
                        height: Math.min(cellHeight / 3, Const.ELEMENT_PADDING),
                        borderWidth: 0
                    }}
                />
            )

            for (let j = 0; j < this.props.data.cols; j++) {
                cells.push(
                    <td key={"post-" + j.toString()}
                        className={"ws-designer-math-lineature-table-cell-container ws-designer-math-lineature-table-cell-outdent-tb"}
                        style={{
                            width: cellWidth,
                            height: Math.min(cellHeight / 3, Const.ELEMENT_PADDING),
                            borderColor: lineColor
                        }}>
                    </td>
                )
            }

            cells.push(
                <td key={"post-bl"}
                    className={"ws-designer-math-lineature-table-cell-container"}
                    style={{
                        width: Math.min(cellWidth / 3, Const.ELEMENT_PADDING),
                        height: Math.min(cellHeight / 3, Const.ELEMENT_PADDING),
                        borderWidth: 0
                    }}
                />
            )

            rows.push(<tr key={"post"}>{cells.map(c => c)}</tr>)
        }

        return rows
    }

    renderSelector = () => {
        if (!this.isBorderModeOff()) {
            return
        }

        let selectors: JSX.Element[] = []
        let data = this.props.data

        const cellHeight = this.getCellHeight()
        const cellWidth = this.getCellWidth()

        // Row selector
        let posY = Const.ELEMENT_PADDING
        for (let i = 0; i < data.rows; i++) {
            let className = "ws-designer-math-lineature-selector-y"

            let selectedRow = false
            for (let j = 0; j < data.cols; j++) {
                selectedRow = selectedRow || this.isCellSelected(i, j)
            }

            if (selectedRow && this.props.element.selected) {
                className += " ws-designer-math-lineature-row-highlight"
            }

            selectors.push(<div className={className}
                                key={this.props.id + "-selector-y-" + i}
                                style={{height: cellHeight + 1, top: posY, left: 0}}
                                onMouseDown={() => this.onMouseDownSelector(WDTableDimensionMode.ROW, i)}
                                onMouseMove={(e) => this.onMouseMoveSelector(e, i)}
            />)

            posY += cellHeight + 1
        }

        // Column selector
        let posX = Const.ELEMENT_PADDING
        for (let i = 0; i < data.cols; i++) {
            let className = "ws-designer-math-lineature-selector-x"

            let selectedColumn = false
            for (let j = 0; j < data.rows; j++) {
                selectedColumn = selectedColumn || this.isCellSelected(j, i)
            }

            if (selectedColumn && this.props.element.selected) {
                className += " ws-designer-math-lineature-col-highlight"
            }

            selectors.push(<div className={className}
                                key={this.props.id + "-selector-x-" + i}
                                style={{width: cellWidth + 1, top: 0, left: posX}}
                                onMouseDown={() => this.onMouseDownSelector(WDTableDimensionMode.COLUMN, i)}
                                onMouseMove={(e) => this.onMouseMoveSelector(e, i)}
            />)
            posX += cellWidth + 1
        }

        return selectors
    }
    render() {
        this.context.log.debug("rendering " + this.props.id + " " + this.props.data.rows + "x" + this.props.data.cols + ": " + this.props.element.selected)
        this.context.log.flush(LogLevel.INFO)

        const resizeNodesLineature: ResizeInfo =
            new ResizeInfo(true, true, true, true, true, true, true, true,
                this.getMinWidth(), Const.MaxElementSize,
                this.getMinHeight(), Const.MaxElementSize)

        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }

        const lineature = <div className={"ws-designer-math-lineature print"}
                               style={{padding: this.getPadding() + "px"}}>
            <table className={"ws-designer-math-lineature-table " + BorderUtils.getCursorClass(this.state.borderMode)}
                   style={{width: this.getTableWidth(), height: this.getTableHeight()}}
            >
                <tbody>
                {this.renderRows()}
                </tbody>
            </table>

            {/* Selector for columns and rows */}
            {this.renderSelector()}
        </div>

        if (this.props.isIndependentElement) {
            return <WDElementContainer id={this.props.id}
                                       element={this.props.element}
                                       hasResizeOnCreate={false}
                                       onEdit={this.onEditElement}
                                       resizeInfo={resizeNodesLineature}
                                       renderWrapper={true}
                                       onUnlockElement={this.unlockElement}
                                       onResizeStateChanged={this.props.onResizeStateChanged}
                                       onResizeElement={this.props.onElementResize}
                                       isEditModeAllowed={this.isEditModeAllowed}
                                       isReadOnly={this.props.isReadOnly}
                                       onContextMenu={this.props.onContextMenu}
                                       ref={this.state.elementRef}
            >
                {lineature}
            </WDElementContainer>

        }

        return lineature
    }
}
