import React from 'react';
import '../assets/css/layout.min.css';
import '../index.css'
import {AppRouter} from "../Framework/Router/AppRouter";
import {MainContext} from "./MainContext";
import {UserSettings} from "../_model/UserSettings";
import {Notification, NotificationHandler} from "../Components/Notification/NotificationHandler";
import {MatchProps} from "../Designer/WDesigner";
import {GetUserProductLicenses, GetUserSettings} from "../_endpoint/UserEndpoint";
import Auth from "../Framework/Auth";
import {Resource, Util} from "../Framework/Util";
import {Log} from "../Framework/Log";
import {ListFilter} from "../Components/List/ListFilter";
import {AdminDataListSortSettings} from "../Admin/Maintenance/AdminDataListSortSettings";
import {ListRecord} from "../Components/List/ListRecord";
import {AdminObjectType} from "../Framework/Enums";
import {AuthError} from "../Framework/Error/AuthError";
import translations from "../Framework/translations.json";
import Announcement, {AnnouncementType} from "../_model/Announcement";
import {VicidoProduct} from "../_model/VicidoProduct";
import {WorksheetFilter, WorksheetMarketplaceFilter} from "../_model/Worksheet";
import {WorksheetItemTypeEnum} from "../_model/WorksheetItemType";
import {ICacheObject} from "../Framework/Cache/ICacheObject";
import {Coords} from "../Framework/Coords";
import {
    WDActionLogCategory,
    WDActionLogEntry,
    WDActionLogEntryDetails,
    WDActionLogType
} from "../Designer/ActionLog/WDActionLogEntry";
import {TutorialData, TutorialStepData} from "../Designer/Tutorial/TutorialData";
import {CompleteTutorial, GetTutorials} from "../_endpoint/TutorialEndpoint";
import ProblemReport from "../_model/base/ProblemReport";

interface IProps { }

interface IState {
    log: Log

    hasError: boolean
    zoom: number,
    hasInternetConnection: boolean
    userProducts: VicidoProduct[]

    userSettings?: UserSettings
    notification?: Notification[]
    listFilter?: ListFilter[]
    worksheetFilter: WorksheetFilter
    marketplaceFilter: WorksheetMarketplaceFilter
    listSortValues?: AdminDataListSortSettings[]
    listRecords?: ListRecord[]
    notifications: Notification[]
    cacheObjects?: ICacheObject[]
    manualToolbarCoords?: Coords

    currentActionLogEntry?: WDActionLogEntry
    actionLog?: WDActionLogEntry[]
    onSendErrorReport?: (message: string) => void

    tutorials: TutorialData[]
    currentTutorial?: TutorialData
}

class AppStart extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: MatchProps, state: IState) {
        super(props, state);

        this.state = {
            log: new Log(),
            zoom: 1,
            hasError: false,
            hasInternetConnection: true,
            userProducts: [],
            notifications: [],
            tutorials: [],
            worksheetFilter: WorksheetFilter.initFilter(),
            marketplaceFilter: WorksheetMarketplaceFilter.initFilter()
        }
    }

    componentDidMount() {
        this.getUserProducts()
        this.getUserSettings()
        if (Auth.isLoggedIn()) {
            this.getTutorials()
        }

        window.addEventListener('online', this.handleOnline);
        window.addEventListener('offline', this.handleOffline);
    }
    componentWillUnmount() {
        window.removeEventListener('online', this.handleOnline);
        window.removeEventListener('offline', this.handleOffline);
    }

    handleOnline = () => {
        if (!this.state.hasInternetConnection) {
            this.setState({hasInternetConnection: true})
        }
    }
    handleOffline = () => {
        this.setState({hasInternetConnection: false})
    }
    getGlobalAnnouncements = () => {
        if (!this.state.hasInternetConnection) {
            return [new Announcement(0,
                this.context.translate(translations.notification.no_internet_connection_name),
                true, AnnouncementType.info,
                this.context.translate(translations.notification.no_internet_connection))]
        }
        return []
    }



    handleError = (error: unknown, message?: string, notificationId?: string, title?: string, category?: WDActionLogCategory) => {
        const err = Util.getError(error)

        this.addWDAction(WDActionLogType.Error, category || WDActionLogCategory.error, [], message + " | " +
            JSON.stringify(err, ["timestamp", "status", "error", "message", "path"]))

        if (error instanceof AuthError) {
            // Remove pending notifications
            if (notificationId) {
                this.removeNotification(notificationId)
            }

            this.setState({hasError: true})
        } else {
            let notification = Notification.handleError(error as Error, message ? message : (error as Error).message,
                this.context.translate, title)
            this.pushNotification(notification, notificationId)
        }
    }

    getUserProducts = (): Promise<VicidoProduct[]> => {
        return new Promise<VicidoProduct[]>(resolve => {
            if (this.state.userProducts && this.state.userProducts.length > 0) {
                resolve(this.state.userProducts)
            } else if (Auth.isLoggedIn()) {
                // get current user settings and update context
                GetUserProductLicenses().then((userProducts) => {
                    this.updateUserProducts(userProducts)
                    resolve(userProducts)
                })
            }
        })
    }
    updateUserProducts = (userProducts: VicidoProduct[]) => {
        this.setState({userProducts: userProducts})
    }
    getUserSettings = (): Promise<UserSettings> => {

        return new Promise<UserSettings>(resolve => {
            if (this.state.userSettings) {
                resolve(this.state.userSettings)
            } else if (Auth.isLoggedIn()) {
                // get current user settings and update context
                GetUserSettings().then((userSettings) => {
                    this.updateUserSettings(userSettings)
                    resolve(userSettings)
                })
            }
        })
    }
    updateUserSettings = (userSettings: UserSettings) => {
        this.setState({userSettings: userSettings})
    }
    getTutorials = () => {
        if (this.state.tutorials.length === 0) {
            this.loadTutorials()
        }
    }

    setListFilter = (filter: ListFilter, callback?: () => void) => {
        let filters = this.state.listFilter

        if (filters) {
            let current = filters.find(i => i.objectType === filter.objectType)
            if (current) {
                current.listStyle = filter.listStyle
                current.searchString = filter.searchString
                current.searchOption = filter.searchOption
                current.filterValues = filter.filterValues
            } else {
                filters.push(filter)
            }
        } else {
            filters = [filter]
        }

        this.setState({listFilter: filters}, callback)
    }
    getListFilter = (objectType: AdminObjectType) => {
        return this.state.listFilter?.find(i => i.objectType === objectType)
    }

    /** Worksheet filter */
    getWorksheetFilter = (): WorksheetFilter => {
        return this.state.worksheetFilter
    }
    setWorksheetFilter = (filter: WorksheetFilter, callback?: () => void) => {
        this.setState({worksheetFilter: filter}, callback)
    }

    /** Marketplace filter */
    getMarketplaceFilter = (): WorksheetMarketplaceFilter => {
        return this.state.marketplaceFilter
    }
    setMarketplaceFilter = (filter: WorksheetMarketplaceFilter, callback?: () => void) => {
        this.setState({marketplaceFilter: filter}, callback)
    }

    setListSortValue = (sortValue: AdminDataListSortSettings, callback?: () => void) => {
        let sortValues = this.state.listSortValues

        if (sortValues) {
            let current = sortValues.find(i => i.objectType === sortValue.objectType)
            if (current) {
                current.sortString = sortValue.sortString
                current.descending = sortValue.descending
            } else {
                sortValues.push(sortValue)
            }
        } else {
            sortValues = [sortValue]
        }

        this.setState({listSortValues: sortValues}, callback)
    }
    getListSortValue = (objectType: AdminObjectType) => {
        return this.state.listSortValues?.find(i => i.objectType === objectType)
    }

    getListRecords = () => {
        return this.state.listRecords
    }
    setListRecords = (data: ListRecord[]) => {
        this.setState({listRecords: data})
    }

    getZoom = () => {
        return this.state.zoom
    }
    setZoom = (zoom: number) => {
        this.setState({zoom: zoom})
    }

    translate = (r: Resource) => {
        if (this.state.userSettings) {
            return r[this.state.userSettings.languageId === 3 ? "en" : "de"]
        } else {
            return r["de"]
        }
    }
    log = () => {
        if (this.state.log) {
            return this.state.log
        }

        let l = new Log()
        this.setState({log: l})
        return l
    }

    pushNotification = (notification: Notification, notificationId?: string) => {
        let notifications = this.state.notifications

        if (notificationId) {
            notifications = notifications.filter(n => n.id !== notificationId)
            notification.id = notificationId
        }
        notifications.push(notification)
        this.setState({notifications: notifications})
    }
    removeNotification = (notificationId: string) => {
        let notifications = this.state.notifications
        notifications = notifications.filter(n => n.id !== notificationId)
        this.setState({notifications: notifications})
    }

    removeContext = () => {
        this.setState({listFilter: undefined})
    }

    getCacheObject = (id: number, objectType: WorksheetItemTypeEnum): ICacheObject | undefined => {
        return this.state.cacheObjects?.find(c => c.id === id && c.objectType === objectType)
    }
    setCacheObject = (obj: ICacheObject) => {
        let cacheObjects = this.state.cacheObjects
        if (cacheObjects) {
            let current = cacheObjects.find(c => c.id === obj.id && c.objectType === obj.objectType)
            if (current === undefined) {
                cacheObjects.push(obj)
            }
        } else {
            cacheObjects = [obj]
        }

        this.setState({cacheObjects: cacheObjects})
    }

    /** Manual Toolbar Coords */
    getManualToolbarCoords = (): Coords | undefined => {
        return this.state.manualToolbarCoords
    }
    setManualToolbarCoords = (coords: Coords | undefined) => {
        this.setState({manualToolbarCoords: coords})
    }

    /** Designer Action Log */
    initWDActionLog = () => {
        this.setState({actionLog: []})
    }
    addWDAction = (type: WDActionLogType, category: WDActionLogCategory, details: WDActionLogEntryDetails[], description?: string, report?: ProblemReport[]) => {
        let currentActionLogEntry = new WDActionLogEntry(type, category, details, description, report)

        let actionLog = this.state.actionLog
        if (actionLog) {
            actionLog.push(currentActionLogEntry)
        }
        else {
            actionLog = [currentActionLogEntry]
        }

        // this.setState({actionLog: actionLog.slice(Math.max(actionLog.length - 50, 0))})
        this.setState({actionLog: actionLog})
    }
    getWDActionLog = (): WDActionLogEntry[] => { return this.state.actionLog || [] }
    setOnSendErrorReportDelegate = (onSendErrorReport: (message: string) => void) => {
        this.setState({onSendErrorReport: onSendErrorReport})
    }

    /** Tutorials */
    loadTutorials = () => {
        GetTutorials().then(tutorials => {
            this.setState({tutorials: tutorials})
        })
    }
    initTutorial = (tutorialKey: string, steps: TutorialStepData[]) => {
        let tutorial = this.state.tutorials.find(t => t.tutorialKey === tutorialKey)
        if (tutorial) {
            if (this.state.currentTutorial && this.state.currentTutorial.tutorialKey === tutorialKey) {
                tutorial = this.state.currentTutorial
                tutorial.currentStep = 0
                this.setState({currentTutorial: tutorial})
            }
            else {
                this.setState({currentTutorial: new TutorialData(tutorial.id, tutorialKey, steps, 0)})
            }
        }
    }
    getTutorial = (tutorialKey: string) => {
        if (this.state.currentTutorial && this.state.currentTutorial.tutorialKey === tutorialKey) {
            return this.state.currentTutorial
        }
    }
    setTutorialStep = (tutorialKey: string, step: number) => {
        if (this.state.currentTutorial && this.state.currentTutorial.tutorialKey === tutorialKey) {
            let tutorial = this.state.currentTutorial
            tutorial.currentStep = step
            this.setState({currentTutorial: tutorial})
        }
    }
    closeTutorial = (tutorialKey: string) => {
        let tutorial = this.state.tutorials.find(t => t.tutorialKey === tutorialKey)
        if (tutorial) {
            CompleteTutorial(tutorial.id).then(() => {
                this.setState({
                    tutorials: this.state.tutorials.filter(t => t.tutorialKey !== tutorialKey),
                    currentTutorial: undefined
                })
            })
        }
    }

    render() {
        return <MainContext.Provider value={{
            log: this.state.log,
            translate: this.translate,
            handleError: this.handleError,
            getUserProductLicense: this.getUserProducts,
            getUserSettings: this.getUserSettings,
            updateUserSettings: this.updateUserSettings,
            getListFilter: this.getListFilter,
            setListFilter: this.setListFilter,
            getListSortValue: this.getListSortValue,
            setListSortValue: this.setListSortValue,
            getListRecords: this.getListRecords,
            setListRecords: this.setListRecords,
            getWorksheetFilter: this.getWorksheetFilter,
            setWorksheetFilter: this.setWorksheetFilter,
            getMarketplaceFilter: this.getMarketplaceFilter,
            setMarketplaceFilter: this.setMarketplaceFilter,
            getGlobalAnnouncements: this.getGlobalAnnouncements,
            getZoom: this.getZoom,
            setZoom: this.setZoom,
            setNotification: this.pushNotification,
            removeNotification: this.removeNotification,
            removeContext: this.removeContext,
            getCacheObject: this.getCacheObject,
            setCacheObject: this.setCacheObject,
            getManualToolbarCoords: this.getManualToolbarCoords,
            setManualToolbarCoords: this.setManualToolbarCoords,
            loadTutorials: this.loadTutorials,
            initTutorial: this.initTutorial,
            getTutorial: this.getTutorial,
            setTutorialStep: this.setTutorialStep,
            closeTutorial: this.closeTutorial,
            initWDActionLog: this.initWDActionLog,
            addWDAction: this.addWDAction,
            getWDActionLog: this.getWDActionLog,
            setOnSendErrorReportDelegate: this.setOnSendErrorReportDelegate
        }}>
            <main className="app-main">
                {this.state.userProducts &&
                    <AppRouter userProductLicenses={this.state.userProducts}/>
                }

                {Auth.hasToken() &&
                    <div id={"notification-container"} className={"notification-container"}>
                        {this.state.notifications.map(n => {
                            return <NotificationHandler id={n.id} key={n.id}
                                                        notification={n}
                                                        removeNotification={this.removeNotification}
                                                        sendErrorReport={this.state.onSendErrorReport}
                            />
                        })}
                    </div>
                }
            </main>
        </MainContext.Provider>
    }
}

export default AppStart;
